import React, { Fragment, useState }  from 'react';
import EngineDetailsFilterProps, { Switcher, Filter, NumbersRange } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import InnputGroup from '../../private/InputGroup';
import inputGroup from '../../private/InputGroup/props';
import NumberRangeInput from '../../private/NumberRangeInput';
import NumberRangeInputProps from '../../private/NumberRangeInput/props';
import Title from '../../private/Title';
import title from '../../private/Title/props';
import Collapsible from '../../private/Collapsible';
import collapsible from '../../private/Collapsible/props';
import ToolSetOptionsFilter from '../ToolSetOptionsFilter';
import config from './config.json';

function EngineDetailsFilter(props: EngineDetailsFilterProps) {
  const [isChecked, setChecked] = useState(0);

  return (
    <div className={classNames('engineDetailsWrapper', style)}>
      <Collapsible {...collapsibleProps(props.header, props.isOpen, props.onOpen)}>
        <div className={classNames('engineDetailsFilter', style)}>
          { props.switcher && props.switcher?.items?.length > 0 && (
            <>
              <Title {...titleProps(props.switcher?.header)} />
              <InnputGroup { ...switcherProps(props.switcher, isChecked, setChecked) } />
            </>
          )}
          
          {props.numbersRange?.min && props.numbersRange?.max && (
            <>
              <Title {...titleProps(props.numbersRange?.header)} />
              <NumberRangeInput { ...numbersRangeInputProps(props.numbersRange) } />
            </>
          )}

          { props.filters && props.filters?.length > 0 && props.filters.map((filter: Filter, index: number) => (
            <Fragment key={index}>
              <Title {...titleProps(filter.header)} />
              <ToolSetOptionsFilter { ...filter } />
            </Fragment>
          ))}
        </div>
      </Collapsible>
    </div>
  );
}

const numbersRangeInputProps = (numbersRange: NumbersRange): NumberRangeInputProps => ({
  ...numbersRange,
  min: {
    ...numbersRange.min,
    ...config.NumberRangeInput.min
  },
  max: {
    ...numbersRange.max,
    ...config.NumberRangeInput.max
  }
});

const collapsibleProps = (header: string, isOpen = false, onOpen?: (state: boolean) => void): collapsible => ({
  ...config.Collapsible,
  header: header,
  isOpen,
  onOpen,
});

const titleProps = (title: string): title => ({
  ...config.Title,
  text: title,
});

const switcherProps = (switcher: Switcher, isChecked: number, setChecked: any): inputGroup => ({
  ...config.InputGroup,
  items: switcher.items.map((item, index) => ({
    Input: {
      classNames: config.Input.classNames,
      Label: {
        hidden: config.Input.Label.hidden,
        text: item.text,
      },
      Link: {
        text: item.text,
        url: item.url,
        onClick: (e) => {
          setChecked(index);
          item.onClick && item.onClick(e, item.value);
        },
        hyperlinkAttributes: {
          ...(item.nofollow && { rel: 'nofollow' }),
        }
      },
      id: item.id,
      name: item.name,
      defaultChecked: index === isChecked || item.defaultChecked,
    }
  }))
});

export default React.memo(EngineDetailsFilter);