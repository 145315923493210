import React, { createRef } from 'react';
import inputButtonGlue from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Input from '../Input';
import input from '../Input/props';
import Button from '../Button';
import button from '../Button/props';
import config from './config.json';
import uniqueId from 'lodash/uniqueId';

function InputButtonGlue(props: inputButtonGlue) {
  const inputRef: React.RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
  const id = uniqueId();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      props.onClick(e, inputRef.current?.value);
    }
  };

  return (
    <div className={classNames('inputButtonGlue', style)}>
      <Input {...inputProps(props, inputRef, id)} />
      <Button {...buttonProps(props, handleClick, id)} />
    </div>
  );
}

const inputProps = (props: inputButtonGlue, inputRef: React.RefObject<HTMLInputElement>, id: string): input => ({
  classNames: config.Input.classNames,
  Label: {
    text: config.Input.Label.text,
    hidden: config.Input.Label.hidden,
  },
  id: id,
  disabled: props.inputDisabled, 
  value: props.value,
  ref: inputRef,
  onChange: props.onChange,
});

const buttonProps = (props: inputButtonGlue, handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void, id: string): button => ({
  classNames: config.Button.classNames,
  Label: {
    text: props.buttonText,
    htmlFor: id
  },
  onClick: handleClick
});

export default React.memo(InputButtonGlue);
