import React, { useEffect, useRef, useState } from 'react';
import StackedGalleryProps from "./props";
import style from "./style.module.scss";
import config from './config.json';
import classNames from '../../../utils/classNames';
import blackShare from '../../../icons/blackShare.svg';
import vectorLeft from '../../../icons/vectorLeft.svg';
import button from '../../private/Button/props';
import IconProps from '../../private/Icon/props';
import Button from '../../private/Button';

function StackedGallery(props: StackedGalleryProps) {
  const [scrolledDown, setScrolledDown] = useState(false);
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollableRefCurrent = scrollableRef.current;

    const handleScroll = () => {
      const scrollPosition = scrollableRefCurrent?.scrollTop;
      const isScrolled =  scrollPosition !== 0;
      setScrolledDown(isScrolled);

      if (props.onScrollPositionChange) {
        props.onScrollPositionChange(scrollPosition);
      }
    };

    scrollableRefCurrent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableRefCurrent?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableRef]);

  return (
    <div ref={props.forwardedRef} className={classNames('stackedGallery', style, props.classNames)} data-e2e='stacked-gallery-component' >
      <div className={classNames('scrollGallery', style, {positionDown: scrolledDown})} ref={scrollableRef}>
        {!props.hideTopButtons &&
          <div className={classNames('sticky-buttons', style, {scrolledDown: scrolledDown})}  data-e2e='sticky-buttons'>
            <Button {...backButtonProps(props)} />
            <Button {...shareButtonProps(props)} />
          </div>
        }
        {props.children && props.children.map((child, index) => (
          <div className={classNames('stacked-gallery-item', style)} data-e2e='stacked-gallery-item' key={index} data-id={index}>
              {child}
          </div>
        ))}
      </div>
    </div>
  );
}

const shareButtonProps = (props: StackedGalleryProps): button => {
  return {
    onClick: props.onClickShare,
    e2e: 'share-button-stacked-gallery',
    Icon: {
      svg: blackShare,
      alt: 'share',
      classNames: config.Icon.classNames
    } as IconProps
  }
}

const backButtonProps = (props: StackedGalleryProps): button => {
  return {
    onClick: props.onClickBack,
    e2e: 'back-button-stacked-gallery',
    Icon: {
      svg: vectorLeft,
      alt: 'go back',
      classNames: config.Icon.classNames
    } as IconProps
  }
}

export default React.memo(StackedGallery);