import React from 'react';
import successMessage from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Icon from '../../private/Icon';
import IconProps from '../../private/Icon/props';
import successIcon from '../../../icons/check.svg';
import checkIcon from '../../../icons/check-circle.svg';

function SuccessMessage(props: successMessage) {
  const {title, message, show } = props;
  if (!show) return null;
  return (
    <div className={classNames('successMessage', style, props.classNames)}>
      <Icon {...iconProps(props)}/>
      <div>
        { !props.classNames?.successCard && <p className="message-title" data-e2e={`${props['data-e2e']}-title`}>{title}</p>}
        <p data-e2e={`${props['data-e2e']}-content`}>{message}</p>
      </div>
    </div>
  );
}

export default React.memo(SuccessMessage);

const iconProps = (props: successMessage): IconProps => {
  return {
    svg: props.classNames?.successCard ? checkIcon : successIcon,
    alt: props.successIconAlt || 'Success icon'
  };
}