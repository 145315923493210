import React from 'react';
import pageFooter from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';

function PageFooter(props: pageFooter) {
  return (
    <div className={classNames('pageFooter', style)}>
      {props.text}
    </div>
  );
}

export default React.memo(PageFooter);