import React, { useMemo } from 'react';
import app from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import ModalProps from '../../private/Modal/props';
import Modal from '../../private/Modal';
import ImageProps from '../../private/Image/props';
import Image from '../../private/Image';
import button from '../../private/Button/props';
import Button from '../../private/Button';

import IconProps from '../../private/Icon/props';
import blocker from '../../private/Blocker/props';
import title from '../../private/Title/props';
import SubtitleProps from '../../private/Subtitle/props';
import LabelProps from '../../private/Label/props';

import appbody from './components/AppBody/props';
import AppBody from './components/AppBody';

function App(props: app) {
  return (
    <span className={classNames('app', style)}>
      <Modal {...useModalProps(props)}>
        <Image {...useImageProps(props)}></Image>
        <AppBody {...useAppBodyProps(props)}></AppBody>
        <div
          style={{
              width: config.Button.width
          }}
        >
          <Button {...useButtonProps(props)}></Button>
        </div>
        <Button {...useLinkProps(props)}></Button>
      </Modal>
    </span>
  );
}

const useModalProps = (props: app): ModalProps => {
  return useMemo(() => {
    return {
      Button: {
        onClick: props.onClose,
        Icon: {
          classNames: config.Modal.Button.Icon.classNames
        } as IconProps
      } as button,
      classNames: {
        mobile: config.Modal.classNames.mobile
      },
      height: config.Modal.height,
      Blocker: {
        classNames: {
          scroll: true
        }
      } as blocker
    };
  }, [props]);
}

const useImageProps = (props: app): ImageProps => {
  return useMemo(() => {
    return {
      src: props.icon,
      alt: 'logo',
      width: config.Image.width,
      height: config.Image.height
    };
  }, [props]);
}

const useAppBodyProps = (props: app): appbody => {
  return useMemo(() => {
    return {
      Title: {
        text: props.title
      } as title,
      Subtitle: {
        text: props.subtitle
      } as SubtitleProps
    };
  }, [props]);
}

const useButtonProps = (props: app): button => {
  return useMemo(() => {
    return {
      classNames: config.Button.classNames,
      onClick: props.onClick,
      Label: {
        text: props.button
      } as LabelProps
    };
  }, [props]);
}

const useLinkProps = (props: app): button => {
  return useMemo(() => {
    return {
      classNames: config.Link.classNames,
      onClick: props.onClick,
      Label: {
        text: props.close
      } as LabelProps
    };
  }, [props]);
}

export default React.memo(App);
