import React, { useMemo } from 'react';
import listingSnapshot from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../../../utils/classNames';
import config from "./config.json";

import button from '../../../../../Button/props';
import Button from '../../../../../Button/Button';
import title from '../../../../../Title/props';
import Title from '../../../../../Title/Title';
import ContentProps from '../../../../../Content/props';
import Content from '../../../../../Content/Content';

function ListingSnapshot(props: listingSnapshot) {
  return (
    <div className={classNames('listingSnapshot', style)}>
      <Button {...useButtonProps(props)}></Button>
      <Title {...useTitleProps(props)}></Title>
      <Content {...useContentProps(props)}></Content>
    </div>
  );
}

const useButtonProps = (props: listingSnapshot): button => {
  return useMemo(() => {
    return {
      ...props.Button as button,
      classNames: config.Button.classNames
    };
  }, [props]);
}

const useTitleProps = (props: listingSnapshot): title => {
  return useMemo(() => {
    return {
      ...props.Title as title,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const useContentProps = (props: listingSnapshot): ContentProps => {
  return useMemo(() => {
    return {
      ...props.Content as ContentProps,
      classNames: config.Content.classNames
    };
  }, [props]);
}

export default React.memo(ListingSnapshot);