import React, {useState, useRef, useMemo, createRef, useEffect} from 'react';
import MediaCarouselProps, {VideoControlsRef} from './props';
import { VideoRef, ThumbnailsType, MediaItem } from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import EmblaCarousel from '../EmblaCarousel';
import EmblaCarouselProps from '../EmblaCarousel/props';
import Thumbnails from '../../private/Thumbnails/Thumbnails';
import ThumbnailsProps, {ThumbnailItem} from '../../private/Thumbnails/props';
import MoreOptions from '../ImageCarousel/components/MoreOptions';
import Button from '../../private/Button';
import ButtonProps from '../../private/Button/props';
import ImageCounterPanel from '../../private/ImageCounterPanel';
import Image from '../../private/Image/Image';
import ImageProps from '../../private/Image/props';
import Video from '../../private/Video/Video';
import VideoProps from '../../private/Video/props';
import ImagePannellumContainer from "../ImagePannellumContainer";
import ImagePannellumContainerProps from "../ImagePannellumContainer/props";


import defaultImage from '../../../icons/placeholder-image.svg';
import share from '../../../icons/share.svg';


function MediaCarousel(props: MediaCarouselProps) {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const videoItemRefs = useRef<VideoRef>([]);

  const handleThumbnailClick = (index: number) => {
    const thumbnailIndex = index - 1;
    setSelectedIndex(thumbnailIndex);
    videoItemRefs.current = props.items.map((_, i: number) => {
      return videoItemRefs.current[i];
    });
    props.onThumbnailClick && props.onThumbnailClick(index);
  }

  useEffect(() => {
    videoItemRefs.current.forEach((ref, index) => {
      if (index !== selectedIndex) {
        ref?.current?.stopPlayingVideo();
      }
    });
  }, [selectedIndex]);


  const hasImages = props.items?.length > 0;
  const showCounter = props.showCounter && hasImages;

  const emblaProps: EmblaCarouselProps = { ...useEmblaProps(props, videoItemRefs), selectedIndex, setSelectedIndex};

  return (
    <div className={classNames('mediaCarousel', style)} data-e2e="media-carousel">
      <div className={classNames('buttonsTop', style)}>
        <Button {...buttonShareProps(props)} role='share'/>
        { props.options && <MoreOptions options={props.options}/>}
      </div>

      <ImageCounterPanel show={showCounter} count={selectedIndex + 1} total={props.items.length} />
      <EmblaCarousel {...emblaProps} handleCarouselItemClick={ props.handleCarouselItemClick }/>
      { props.thumbnails && <Thumbnails {...thumbnailsProps(props)} activeIndex={selectedIndex + 1} onThumbnailClick={handleThumbnailClick}/>}

    </div>
  );
}
const buttonShareProps = (props: MediaCarouselProps): ButtonProps => {
  return {
    onClick: props.onClickShare,
    Icon: {
      svg: share,
      alt: props.shareIconAlt || 'share',
      classNames: config.Icon.classNames
    }
  }
}

const stopAllVideos = (childRefs: React.MutableRefObject<VideoRef>) => {
  childRefs.current?.forEach(ref => {
    if (ref.current) {
      ref.current.stopPlayingVideo();
    }
  })
}

const lazyDefault = (isLazyLoading?: boolean) => isLazyLoading !== false;

const createImageProps = (item: MediaItem, lazyLoading: boolean, fetchPriority: boolean) => ({
  alt: item.alt,
  src: item.src,
  srcset: item.srcset,
  sizes: item.sizes,
  defaultImage: defaultImage,
  lazyLoading,
  fetchPriority
}) as ImageProps;

const createVideoProps = (item: MediaItem, index: number, childRefs: React.MutableRefObject<VideoRef>, swipeVideosOverlay: boolean) => ({
  title: item.title,
  src: item.src,
  childRef: childRefs.current[index] as VideoControlsRef,
  overlay: false,
  swipeVideosOverlay
}) as VideoProps;

const createPannellumProps = (item: MediaItem) => ({
  imageSource: item.src,
  id: item.id,
  config: {
    autoRotate: 0,
    autoLoad: true,
    showFullscreenCtrl: true,
  }
}) as ImagePannellumContainerProps;

const useEmblaProps = (props: MediaCarouselProps, childRefs: React.MutableRefObject<VideoRef>) => {
  return useMemo(() => {
    childRefs.current = props.items.map((_, i: number) => childRefs.current[i] ?? createRef());

    return {
      prevIconAlt: props.prevIconAlt,
      nextIconAlt: props.nextIconAlt,
      goToSlide: true,
      prevOnClick: (ev: React.MouseEvent<HTMLButtonElement>) => {
        stopAllVideos(childRefs);
        props.prevOnClick && props.prevOnClick(ev);
      },
      nextOnClick: (ev: React.MouseEvent<HTMLButtonElement>) => {
        stopAllVideos(childRefs);
        props.nextOnClick && props.nextOnClick(ev);
      },
      children: props.items.map((item: MediaItem, index: number) => {
        const lazyLoading = lazyDefault(props.isLazyLoading) && index > 0;
        const fetchPriority = lazyDefault(props.isLazyLoading) && index === 0;
        const swipeVideosOverlay = props.swipeVideosOverlay ?? false;

        if (item?.mediaType === '360Image' || item?.mediaType === 'ImagePannellumContainer') {
          return <ImagePannellumContainer {...createPannellumProps(item)} key={index}/>
        }
        if (item?.mediaType === 'video' || item?.mediaType === 'external') {
          return <Video {...createVideoProps(item, index, childRefs, swipeVideosOverlay)} key={index}/>
        }
        return <Image {...createImageProps(item, lazyLoading, fetchPriority)} key={index}/>
      }),
    };
  }, [props, childRefs]);
};


const isButtonThumbnail = (item: ThumbnailsType) => !!item.buttonText;

const isVideoThumbnail = (item: ThumbnailsType) => item?.mediaType === 'video' || item?.mediaType === 'external';

const createButtonThumbnail = (item: ThumbnailsType) => ({
  Button: {
    Label: {
      text: item.buttonText,
    },
    Icon: {
      svg: item.src,
      alt: item.alt,
      srcset: item.srcset,
      sizes: item.sizes,
      defaultImage: defaultImage,
    },
    onClick: item.onClick,
    classNames: config.Thumbnails.Button.classNames,
  }
});

const createVideoThumbnail = (item: ThumbnailsType) => ({
  Video: {
    title: item.title,
    src: item.src,
    overlay: config.Thumbnails.Video.overlay,
    childRef: { current: null },
    poster: item.poster,
    thumbnailVideo: item.thumbnailVideo
  },
});

const createImageThumbnail = (item: ThumbnailsType, lazyLoading: boolean)=> ({
  Image: {
    src: item.src,
    alt: item.alt,
    srcset: item.srcset,
    sizes: item.sizes,
    defaultImage: defaultImage,
    lazyLoading,
  },
});

const thumbnailsProps = (props: MediaCarouselProps): ThumbnailsProps => {
  const thumbnailList = props.thumbnails;
  const lazyLoading = lazyDefault(props.isLazyLoading);
  const filteredThumbnailList = thumbnailList.filter(item => item.show !== false);
  return {
    items:  filteredThumbnailList.map((item) => {
      if (isButtonThumbnail(item)) {
        return createButtonThumbnail(item);
      } else if (isVideoThumbnail(item)) {
        return createVideoThumbnail(item);
      } else {
        return createImageThumbnail(item, lazyLoading);
      }
    }) as ThumbnailItem[],
  };
};

export default React.memo(MediaCarousel);
