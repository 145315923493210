import React, { useMemo } from 'react';
import listingNavigation from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../../../../../utils/classNames';
import config from './config.json';

import title from '../../../../../../../Title/props';
import Title from '../../../../../../../Title/Title';
import IconProps from '../../../../../../../Icon/props';
import Icon from '../../../../../../../Icon/Icon';

function ListingNavigation(props: listingNavigation) {
  return (
    <div className={classNames('listingNavigation', style)}>
      <Title {...useTitleProps(props)}></Title>
      <Icon {...useIconProps(props)}></Icon>
    </div>
  );
}

const useTitleProps = (props: listingNavigation): title => {
  return useMemo(() => {
    return {
      ...props.Title as title,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const useIconProps = (props: listingNavigation): IconProps => {
  return useMemo(() => {
    return {
      ...props.Icon as IconProps,
      classNames: config.Icon.classNames
    };
  }, [props]);
}

export default React.memo(ListingNavigation);