import React from 'react';
import title from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function Title(props: title) {
  return (
      <h3 className={classNames('title', style, props.classNames)}
        {...(props['data-e2e'] && {'data-e2e': props['data-e2e']})}>
        {props.text}
        {props.children}
      </h3>
  );
}

export default React.memo(Title);
