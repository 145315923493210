import React from 'react';
import LoadMoreProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Button from '../../private/Button';
import ButtonProps from '../../private/Button/props';
import config from './config.json';

function LoadMore(props: LoadMoreProps) {
  return (
    <div className={classNames('loadMore', style)}>
      <Button {...buttonProps(props)} />
    </div>
  );
}

const buttonProps = (props: LoadMoreProps): ButtonProps => {
  return {
    Label: {
      text: props.text || config.text,
    },
    onClick: props.onClick
  };
}

export default React.memo(LoadMore);