import React from 'react';
import HeadingTagProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function HeadingTag({customClassName = '', ...props}: HeadingTagProps) {
  const Tag = `h${props.headingLevel}` as keyof JSX.IntrinsicElements;

  const headingTagClass = classNames(`h${props.headingLevel}`, style)
  const headingClass = `${classNames('headingTag', style)} ${headingTagClass} ${customClassName}`
  
  return (
    <Tag
      className={headingClass}
      data-e2e={`heading${props.headingLevel}`}
    >
      {props.heading}
    </Tag>
  );
}

export default React.memo(HeadingTag);
