import React from 'react';
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Action from '../Action';
import SummarySection from '../SummarySection/SummarySection';
import SummaryCTAProps from "./props";
import config from './config';

const status = config.status;

function SummaryCTA ({
  customClassNames,
  src,
  summary,
  hideSave,
  liked,
  className = "",
  onClickLike,
  onClickPreQualified,
  onClickRequestInfo,
  disableRequestInfo,
  hidePreQualifiedButton,
  contactBtnText,
  preQualifiedBtnText,
  buttonAttributes,
}: SummaryCTAProps) {
  return (
    <section className={`${classNames('summary-cta', style, customClassNames)} ${className}`} >
      <article className={classNames('thumbnail', style)}>
        {src && <img loading="lazy" src={src} alt="thumbnail" />}
      </article>
      <article className={classNames('summary', style)}>
        <SummarySection replaceTitleWithDiv={true} {...summary} />
      </article>
      {!hideSave && <article className={classNames('saved', style)}>
        <Action
          attributes={buttonAttributes}
          data-testid="liked-boat-btn"
          data-e2e="heart-icon-btn"
          onClick={onClickLike}
          label={liked ? status.liked.label : status.unliked.label}
          size="small"
          variant="text"
          aria-label={liked ? status.liked.aria : status.unliked.aria}
          icon={liked ? 
            <img src={status.liked.src} alt={status.liked.alt} /> :
            <img src={status.unliked.src} alt={status.unliked.alt} />
          }
        />
      </article>}
      <article className={classNames('cta', style)}>
      {!hidePreQualifiedButton && <Action variant="secondary" label={ preQualifiedBtnText || "Get Pre-Qualified"} onClick={onClickPreQualified} /> }
        <Action attributes={buttonAttributes} label={contactBtnText || "Request Info"} onClick={onClickRequestInfo} disabled={disableRequestInfo} />
      </article>

    </section>
  )
}

export default React.memo(SummaryCTA);
