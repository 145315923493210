import React, { useState, useRef, useEffect } from 'react';
import MoreOptionsProps, { OptionType } from './props';
import style from './style.module.scss';
import classNames from '../../../../../utils/classNames';
import more from '../../../../../icons/dots.svg';
import config from './config.json';

import Button from '../../../../private/Button';
import ButtonProps from '../../../../private/Button/props';
import IconProps from '../../../../private/Icon/props';
import Link from '../../../../private/Link';
import LinkProps from '../../../../private/Link/props';

function MoreOptions(props: MoreOptionsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const divEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {

      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const buttonDropdownProps = (props: MoreOptionsProps): ButtonProps => {
    return {
      onClick: () => handleOpen(),
      Icon: {
        svg: more,
        alt: props.moreIconAlt || 'more options',
        classNames: config.Icon.classNames,
      } as IconProps,
    };
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    option: OptionType
  ) => {
    option.onClick && option.onClick(event);
    setIsOpen(false);
  };

  const linkProps = (props: MoreOptionsProps, index: number): LinkProps => {
    return {
      url: props.options[index].url,
      text: props.options[index].label,
      onClick: (event) => handleClick(event, props.options[index]),
      rest: props.options[index].rest,
    };
  };

  return (
    <div ref={divEl} className={classNames('moreOptions', style)}>
      <Button {...buttonDropdownProps(props)} />
      {isOpen && props.options && (
        <div>
          {props.options.map(
            (option, index: number) =>
              option.show && <Link key={index} {...linkProps(props, index)} />
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(MoreOptions);
