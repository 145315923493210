import React, { useState, useEffect } from 'react';
import NumberRangeFormProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import Button from '../../private/Button';
import ButtonProps from '../../private/Button/props';

import NumberRangeInput from '../../private/NumberRangeInput';
import NumberRangeInputProps, { DataChangeMinMax } from '../../private/NumberRangeInput/props';
import { convertStringToNumber } from '../../../utils/input';

interface FormState {
  isSubmitButtonDisabled: boolean;
  invalidFields: string[];
  errorMessages: string[];
}

function NumberRangeForm(props: NumberRangeFormProps) {
  const [formState, setFormState] = useState<FormState>({
    isSubmitButtonDisabled: true,
    invalidFields: [],
    errorMessages: [],
  });

  useEffect(() => {
    if (!props.min?.value && !props.max?.value) {
      setFormState((prev) => ({
        ...prev,
        isSubmitButtonDisabled: true,
        invalidFields: [],
        errorMessages: [],
      }));
    }
  }, [props.min?.value, props.max?.value]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const elements = (e.target as HTMLFormElement).elements as HTMLFormControlsCollection & { [key: string]: HTMLInputElement };
    const minValue = elements?.[`${props.min.id}`].value;
    const maxValue = elements?.[`${props.max.id}`].value;

    let min = minValue;
    let max = maxValue;
    if (props.price) {
      min = minValue !== '' ? `${ convertStringToNumber(minValue) }` : '';
      max = maxValue !== '' ? `${ convertStringToNumber(maxValue) }` : '';
    }

    const data = { min, max };

    const errors = (props.validate && props.validate(data)) || {};
    const invalidFields = Object.keys(errors);
    const errorMessages = Object.values(errors);

    setFormState((prev) => ({
      ...prev,
      isSubmitButtonDisabled: true,
      invalidFields,
      errorMessages: errorMessages as string[],
    }));

    if (invalidFields.length > 0) {
      return;
    }

    props.onSubmit && props.onSubmit(data);

  };

  const isSubmitDisabled = (data: DataChangeMinMax, invalidFields: string[]) => {
    const { min, max } = data;
    const minProp = props.min?.value;
    const maxProp = props.max?.value;

    // the next line checks if there are any validation errors or user type the same values as before
    return (invalidFields.length > 0) || (minProp && maxProp && +minProp === +min && +maxProp === +max)
  }

  const handleDataChange = (data: DataChangeMinMax): void => {
    setFormState((prev) => ({
      ...prev,
      isSubmitButtonDisabled: !!isSubmitDisabled(data, []),
      invalidFields: [],
      errorMessages: [],
    }));

  };

  const classes = { light: formState.isSubmitButtonDisabled };

  return (
    <div className={ classNames('numberRangeForm', style, classes) }>
      <form onSubmit={ handleSubmit }>
        <NumberRangeInput { ...numberRangeProps(props, formState) } onDataChange={ handleDataChange }  />
        <Button { ...submitButtonProps(props, formState) } />
      </form>
      { formState.errorMessages.length > 0 && (
        <div data-error="true">
          { formState?.errorMessages.map((error: string, index: number) => (
            <div key={index}>{ error }</div>
          )) }
        </div>
      )}
    </div>
  );
}

const submitButtonProps = (props: NumberRangeFormProps, formState: FormState): ButtonProps => ({
  ...config.Button,
  Label: {
    text: props.buttonText
  },
  disabled: formState.isSubmitButtonDisabled,
  buttonAttributes: props.buttonAttributes
} as ButtonProps);

const numberRangeProps = (props: NumberRangeFormProps, formState: FormState): NumberRangeInputProps => ({
  splitter: props.splitter,
  currency: props.currency,
  currencyPrefix: props.currencyPrefix,
  classNames: {
    light: formState.isSubmitButtonDisabled
  },
  min: {
    ...props.min,
    price: props.price,
    invalid: formState.invalidFields.includes('min') || props?.min?.invalid,
  },
  max: {
    ...props.max,
    price: props.price,
    invalid: formState.invalidFields.includes('max') || props?.max?.invalid,
  }
});

export default React.memo(NumberRangeForm);
