import React, { useCallback, useMemo } from 'react';
import infoBoxButton from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Icon from '../../private/Icon/Icon';
import IconProps from '../../private/Icon/props';
import Title from '../../private/Title/Title';
import title from '../../private/Title/props';
import Content from '../../private/Content/Content';
import ContentProps from '../../private/Content/props';

import info from '../../../icons/info.svg';
import description from '../../../icons/description.svg';
import propulsion from '../../../icons/propulsion.svg';
import measurements from '../../../icons/measurements.svg';
import location from '../../../icons/location.svg';

const icons = {
  description,
  propulsion,
  measurements,
  location
}

function InfoBoxButton(props: infoBoxButton) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    props.onClick(event);
  }, [props]);

  return (
    <div
      onClick={onClick}
      className={classNames('info-box-button', style, props.classNames)}
    >
      {props.classNames?.icon && <Icon {...iconProps(props)}></Icon>}
      <div>
        <Title {...useTitleProps(props)}></Title>
        <Content {...useContentProps(props)}></Content>
      </div>
    </div>
  );
}

const iconProps = (props: infoBoxButton): IconProps => {
  return {
    svg: (props.icon && icons[props.icon]) || info,
    alt: props.icon || 'info',
    classNames: config.Icon.classNames
  };
};

const useTitleProps = (props: infoBoxButton): title => useMemo(() => {
  return {
    text: props.title,
    classNames: config.Title.classNames
  };
}, [props]);

const useContentProps = (props: infoBoxButton): ContentProps => useMemo(() => {
  return {
    text: props.content,
    classNames: config.Content.classNames
  };
}, [props])

export default React.memo(InfoBoxButton);
