import React, { useState, useEffect } from 'react';
import ToolSetOptionsFilterProps, {ToolSetOption} from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Input from '../../private/Input';
import input from '../../private/Input/props';
import InputGroup from '../../private/InputGroup';
import inputGroup from '../../private/InputGroup/props';
import config from './config.json';

function ToolSetOptionsFilter(props: ToolSetOptionsFilterProps) {
  const [typeAheadText, setTypeAheadText] = useState('');
  const [selectedValues, setSelectedValues] = useState(props.selectedValues || []);

  useEffect(() => {
    if (!props.selectedValues?.length) {
      setSelectedValues([]);
    } else {
      setSelectedValues(props.selectedValues);
    }
  }, [props.selectedValues]);

  const handleToggleValue = (value: string) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter(selectedValue => selectedValue !== value)
      : [...selectedValues, value];

    setSelectedValues(updatedValues);
    props.onDataChange && props.onDataChange(updatedValues);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, item: ToolSetOption) => {
    handleToggleValue(item.value);
    item.onClick && item.onClick(e, item.value);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, item: ToolSetOption) => {
    handleToggleValue(item.value);
    item.onChange && item.onChange(e, item.value);
  };

  const normalizeString = (string: string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const typeAheadMatchesValue = (typeAheadText: string, item: ToolSetOption) => {
    const filterValue = item.filterValue || item.value || item.text;
    return normalizeString(filterValue).toLowerCase().includes(normalizeString(typeAheadText).toLowerCase());
  };

  const handleTypeAhead = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTypeAheadText( e.target.value);
    props.onChange && props.onChange(e);
  };

  const propsItems = props.items || [];
  const filteredItems = typeAheadText ? propsItems.filter(item => typeAheadMatchesValue(typeAheadText, item)) : propsItems;

  return (
    <div className={classNames('toolSetOptionsFilter', style)}>
      <Input {...inputProps(props)} onChange={ handleTypeAhead } />
      {filteredItems?.length > 0 && (
        <div>
          <InputGroup {...inputGroupProps(filteredItems, handleClick, handleChange, selectedValues)} />
        </div>
      )}
    </div>
  );
}

const inputProps = (props: ToolSetOptionsFilterProps): input => ({
  ...config.TextInput,
  Label: {
    ...config.TextInput.Label,
    text: props.label,
  },
  value: props.value,
  name: props.name,
  id: props.id,
  placeholder: props.placeholder,
});


const inputGroupProps = (
  items: ToolSetOption[],
  handleClick: (e: React.MouseEvent<HTMLElement>, item: ToolSetOption) => void,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, item: ToolSetOption) => void,
  selectedValues: string[]
): inputGroup => {

  return {
    classNames: config.inputGroup.classNames,
    items: items.map((item: ToolSetOption) => ({
      Input: {
        ...config.Input,
        Label: {
          ...config.Input.Label,
          text: item.text,
        },
        Link: {
          text: item.text,
          url: item.url,
          onClick: (e: React.MouseEvent<HTMLElement>) => handleClick(e, item),
          hyperlinkAttributes: {
            ...(item.nofollow && { rel: 'nofollow' }),
          }
        },
        id: item.value,
        name: item.name,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, item),
        value: item.value,
        defaultChecked: selectedValues.includes(item.value),
      },
    })),
  };
}

export default React.memo(ToolSetOptionsFilter);