import React, { useMemo } from "react";
import oemFindADealer from "./props";
import style from "./style.module.scss";
import config from "./config.json";
import Image from "../../private/Image";
import ImageProps from "../../private/Image/props";
import Label from "../../private/Label";
import LabelProps from "../../private/Label/props";
import Title from "../../private/Title/Title";
import title from "../../private/Title/props";
import Action from "../Action/Action";
import { ActionProps } from "../Action/props";
import Button from "../../private/Button/Button";
import button, { ButtonType } from "../../private/Button/props";
import classNames from "../../../utils/classNames";

function OEMFindADealer(props: oemFindADealer) {
  const imageProps = useImageProps(props);
  const labelProps = useLabelProps(props);
  const titleProps = useTitleProps(props);
  const actionProps = useActionProps(props);
  const buttonProps = useButtonProps(props);

  // Determine which component to render
  const ActionOrButton = props.portal === 'bt' ? ActionComponent : ButtonComponent;

  return (
    <div className={classNames("oem-find-dealer", style, props.classNames)}>
      <Image {...imageProps} />
      <Label {...labelProps} />
      <Title {...titleProps} />
      <ActionOrButton {...(props.portal === 'bt' ? actionProps : buttonProps)} />
    </div>
  );
}

const ActionComponent = (props: ActionProps) => {
  return <Action {...props} />;
};

const ButtonComponent = (props: button) => {
  return <Button {...props} />;
};

const useImageProps = (props: oemFindADealer): ImageProps =>
  useMemo(() => {
    return {
      src: props.logoUrl,
      alt: props.title,
      classNames: config.Image.classNames,
      onError: (e) => {
        e.currentTarget.src = props.defaultLogoUrl as string;
        e.currentTarget.width = config.Image.defaultImageWidth as number;
      },
      onLoad: props.onLoad
    };
  }, [props]);

const useLabelProps = (props: oemFindADealer): LabelProps =>
  useMemo(() => {
    return {
      text: props.headline,
      classNames: config.Label.classNames
    };
  }, [props]);

const useTitleProps = (props: oemFindADealer): title =>
  useMemo(() => {
    return {
      text: props.title,
      classNames: config.Title.classNames
    };
  }, [props]);

const useActionProps = (props: oemFindADealer): ActionProps => {
  return {
    onClick: props.button.onClick,
    label: props.button.label
  };
}

const useButtonProps = (props: oemFindADealer): button =>
  useMemo(() => {
    return {
      onClick: props.button.onClick,
      type: config.Button.type as ButtonType,
      classNames: config.Button.classNames,
      Label: {
        text: props.button.label
      }
    };
  }, [props]);


export default React.memo(OEMFindADealer);
