import React, { useState } from 'react';
import financeSummary from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import IconProps from '../../private/Icon/props';
import Icon from '../../private/Icon';
import calculator from '../../../icons/calculator.svg';
import config from './config.json';
import link from '../../private/Link/props';
import Link from '../../private/Link';
import tooltipLite from '../../private/TooltipLite/props';
import TooltipLite from '../../private/TooltipLite';
import info from '../../../icons/info.svg';

function FinanceSummary(props: financeSummary) {
  const [showTooltip, setShowTooltip] = useState(false);

  const onTooltipClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setShowTooltip(!showTooltip);
  }

  return (
    <div className={classNames('financeSummary', style, {...props.classNames})} style={{maxWidth: props.maxWidth ? props.maxWidth : config.indicator.size}}>
      <Icon {...iconProps()}></Icon>
      <div>
        {props.paymentText}
        <TooltipLite {...tooltipLiteProps(props, showTooltip)}>
            <div onClick={onTooltipClick} style={{cursor: config.indicator.cursor}}>
                <Icon {...iconInfoProps()}></Icon>
            </div>
        </TooltipLite> 
      </div>
      <Link {...linkProps(props)}></Link>
    </div>
  );
}

export default React.memo(FinanceSummary);

const linkProps = (props: financeSummary): link => {
  return {
    text: props.linkText,
    onClick: props.onClickCustomize,
    classNames: config.Link.classNames,
    hyperlinkAttributes: props.hyperlinkAttributes
  }
}

const iconProps = (): IconProps => {
  return {
    ...config.CalculatorIcon,
    svg: calculator,
    classNames: {
      s42: true
    },
  };
}

const iconInfoProps = (): IconProps => {
  return {
    ...config.InfoIcon,
    svg: info,
    classNames: {
      button: true
    }
  };
}

const tooltipLiteProps = (props: financeSummary, showTooltip: boolean): tooltipLite => {
  return {
    content: props.content,
    useHover: props.useHover,
    isOpen: showTooltip,
    eventToggle: props.eventToggle,
    direction: props.direction,
    distance: props.distance,
    background: props.background,
    className: props.className,
  }
}
