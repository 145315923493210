import React from 'react';
import SimpleDropdownListProps, { SimpleDropdownOption } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Collapsible from '../../private/Collapsible/Collapsible';
import collapsible from '../../private/Collapsible/props';
import config from './config.json';

function SimpleDropdownList(props: any) {
  return (
    <div className={classNames('simpleDropdownList', style)}>
      <Collapsible {...collapsibleProps(props)} />
    </div>
  );
}

const collapsibleProps = (props: SimpleDropdownListProps): collapsible => {
  return {
    ...config.Collapsible,
    InputGroup: undefined,
    header: props.selected || '',
    onChange: props.onChange,
    List: {
      items: props.items.map((option: SimpleDropdownOption) => ({
        prefix: option.prefix,
        Content: { text: option.text }
      }))
    }
  }
}

export default React.memo(SimpleDropdownList);