import React, { useMemo } from 'react';
import darkCard from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Image from '../../private/Image/Image';
import ImageProps from '../../private/Image/props';
import Title from '../../private/Title';
import title from '../../private/Title/props';
import Subtitle from '../../private/Subtitle';
import SubtitleProps from '../../private/Subtitle/props';
import Link from "../../private/Link";
import link from '../../private/Link/props';

function DarkCard(props: darkCard) {
  return (
    <div className={classNames('darkCard', style)}>
      <Image {...imageProps(props)}></Image>
      <Title {...useTitleProps(props)}></Title>
      <Subtitle {...useSubtitleProps(props)}></Subtitle>
      <Link {...useLinkProps(props)}></Link>
    </div>
  );
}

const imageProps = (props: darkCard): ImageProps => {
  return {
    src: props.image || '',
    alt: props.title
  };
}

const useTitleProps = (props: darkCard): title => {
  return useMemo(() => {
    return {
      text: props.title,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const useSubtitleProps = (props: darkCard): SubtitleProps => {
  return useMemo(() => {
    return {
      text: props.subtitle,
      classNames: config.Subtitle.classNames
    };
  }, [props]);
}

const useLinkProps = (props: darkCard): link => {
  return useMemo(() => {
    return {
      url: props.url,
      text: props.button,
      classNames: config.Link.classNames
    }
  }, [props]);
}

export default React.memo(DarkCard);
