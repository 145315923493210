import React from 'react';
import TypeClassCardProps, { TypeImageProps, TypeLinkProps } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Title from '../../private/Title';
import TitleProps from '../../private/Title/props';
import Content from '../../private/Content';
import ContentProps from '../../private/Content/props';
import Link from '../../private/Link';
import LinkProps from '../../private/Link/props';
import Picture from '../../private/Picture';
import { PictureProps } from '../../private/Picture/props';
import config from './config.json';

function TypeClassCard(props: TypeClassCardProps) {
  return (
    <div className={ classNames('typeClassCard', style, {}) }>
      <Picture { ...useImageProps(props.image) } />
      <Title { ...useTitleProps(props) } />
      <Content { ...useContentProps(props) } />
      <Link { ...useLinkProps(props.link) } />
    </div>
  );
}

const useImageProps = (props: TypeImageProps): PictureProps => ({
  ...props,
  mainImgClassNames: config.Picture.classNames,
});

const useTitleProps = (props: TypeClassCardProps): TitleProps => ({
  text: props.title,
  classNames: config.Title.classNames,
});

const useContentProps = (props: TypeClassCardProps): ContentProps => ({
  text: props.description,
  classNames: config.Content.classNames,
});

const useLinkProps = (props: TypeLinkProps): LinkProps => ({
  ...props,
  classNames: config.Link.classNames,
});

export default React.memo(TypeClassCard);