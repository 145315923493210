import React from 'react';
import ContactCardProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import Button from '../../private/Button';
import ButtonProps from '../../private/Button/props';
import Image from '../../private/Image';
import ImageProps from '../../private/Image/props';
import SellerName from '../SellerName';
import SellerNameProps from '../SellerName/props';
import SuccessMessage from '../SuccessMessage';
import SuccessMessageProps from '../SuccessMessage/props';



function ContactCard(props: ContactCardProps) {
  return (
    <div className={classNames('contactCard', style)} data-e2e='contactCard'>
      <div className={classNames('contactCardInfo', style)}>
        {!!props.src && <Image {...imageProps(props)}></Image>}
        {!props.hideSellerName && <SellerName {...sellerNameProps(props)} />}
      </div>
      <Button {...buttonProps(props)}/>

      {props.showConfirmationMessage && <SuccessMessage {...successMessageProps(props)} />}
    </div>
  );
}

const imageProps = (props: ContactCardProps): ImageProps => {
  return {
    src: props.src,
    alt: props.alt || 'Contact Broker Logo',
  };
}

const sellerNameProps = (props: ContactCardProps): SellerNameProps => {
  return {
    listedByText: props.listedByText || 'Listed by',
    name: props.sellerName,
    address: '',
    phoneNumber: props.phoneNumber,
    phoneText: props.phoneText || 'View phone number',
    displayPhoneMessage: !!(props.phoneText && props.phoneText.length),
    onClickPhoneNumber: props.onClickPhoneNumber,
    onClickPhoneMessage: props.onClickPhoneMessage,
  }
}
const buttonProps = (props: ContactCardProps): ButtonProps => {
  return {
    onClick: props.handleClick,
    Label: {
      text: props.buttonLabel || 'Contact Broker'
    },
    classNames: config.Button.classNames,
    buttonAttributes: props.buttonAttributes
  };
}
const successMessageProps = (props: ContactCardProps): SuccessMessageProps => {
  return {
    classNames: {
      successCard: true,
    },
    message: props.successMessage || 'Your request has been sent successfully. The seller will be in touch with you soon.',
    show: props.showConfirmationMessage,
    'data-e2e': `confirmation-message`
  };
};

export default React.memo(ContactCard);
