import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import ToggleProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import IconProps from '../Icon/props';
import button from '../Button/props';
import Button from '../Button';

const getCurrentStateIndex = (states: { state: string, Icon?: IconProps}[], currentState: string) =>
  states.findIndex((state: {[key: string]: string | IconProps}) => state.state === currentState);

function Toggle(props: ToggleProps) {

  const [index, setIndex] = useState(getCurrentStateIndex(props.states, props.state));

  useEffect(() => {
    const currentIndex = getCurrentStateIndex(props.states, props.state);
    setIndex(currentIndex);
  },[props.state, props.states]);


  const ref: React.RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement>(null);

  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (props.preventDefault){
      event?.preventDefault();
    }

    const i: number = props.states[index + 1] ? index + 1 : 0;

    setIndex(i);

    if (props.classNames?.confetti && i) {
      createConfettiEffect(ref);
    }

    props.onClick(props.states[i].state, event);
  }, [props, index]);

  return (

    <div className={classNames('toggle', style, props.classNames)}
      aria-current={index ? true : false}
      onClick={props.classNames?.switch ? onClick : undefined}
      {...props.dataSelector}
      >
      {props.classNames?.confetti && <canvas
        ref={ref}
        width={config.canvas.size}
        height={config.canvas.size}
      ></canvas>}
      <Button {...useButtonProps(props, index, onClick)}></Button>
    </div>
  );
}

const useButtonProps = (props: ToggleProps, index: number, onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): button => {
  return useMemo(() => {
    return {
      onClick: onClick,
      classNames: {
        icon: true
      },
      Icon: props.states[index].Icon as IconProps
    };
  }, [props, index, onClick]);
}

const createConfettiEffect = (ref: React.RefObject<HTMLCanvasElement>) => {
  const canvas: HTMLCanvasElement = ref.current as HTMLCanvasElement;
  const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;
  const confettiParticles: { x: number; y: number; size: number; speed: number; angle: number; color: string; }[] = [];
  const button: HTMLButtonElement = canvas.nextElementSibling as HTMLButtonElement;

  button.style.transform = 'rotate(20deg)';
  button.style.transition = 'none';

  setTimeout(() => {
    button.style.transform = 'rotate(0deg)';
    button.style.transition = '';

    animate();
  }, 30);

  for (let i = 0; i < 100; i++) {
    confettiParticles.push({
      x: canvas.width / 2,
      y: canvas.height,
      size: Math.random() * 5 + 1,
      speed: Math.random() * 1 + 0.5,
      angle: Math.random() * Math.PI * 2,
      color: `hsl(${Math.random() * 360}, 100%, 50%)`
    });
  }

  const updateParticles = () => {
    for (const particle of confettiParticles) {
      particle.y -= Math.random() * 1 + particle.speed;
      particle.x += Math.cos(particle.angle) * particle.speed;

      if (particle.size > 0.25) {
        particle.size -= 0.25;
      }
    }
  };

  const drawParticles = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (const particle of confettiParticles) {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.closePath();
      ctx.fillStyle = particle.color;
      ctx.fill();
    }
  };

  const animate = () => {
    updateParticles();
    drawParticles();

    if (confettiParticles.find(particle => particle.y > 0)) {
      requestAnimationFrame(animate);
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };
};

export default React.memo(Toggle);
