import React from 'react';
import ContentProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function Content(props: ContentProps) {
  return (
    <p className={classNames('content', style, props.classNames)}
      { ...(props['data-e2e'] && { 'data-e2e': props['data-e2e'] }) }
       {...props?.dataSelector}
    >
      {props.text}
    </p>
  );
}

export default React.memo(Content);