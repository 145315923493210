import React, {createRef, useEffect, useRef, useState} from 'react';
import VerticalImageGalleryProps, { ThumbnailsType, VideoRef, MediaItem, VideoControlsRef} from "./props";
import style from "./style.module.scss";
import config from './config.json';
import classNames from '../../../utils/classNames';
import StackedGallery from '../StackedGallery';
import StackedGalleryProps from '../StackedGallery/props';
import StickyGalleryBanner from './components/StickyGalleryBanner';
import StickyGalleryBannerProps from './components/StickyGalleryBanner/props';
import Thumbnails from '../../private/Thumbnails';
import ThumbnailsProps, { ThumbnailItem } from '../../private/Thumbnails/props';
import defaultImage from '../../../icons/placeholder-image.svg';
import Button from '../../private/Button';
import chevron_up from '../../../icons/chevron-up.svg';
import chevron_down from '../../../icons/chevron-down.svg';
import button from '../../private/Button/props';
import ImageCounterPanel from '../../private/ImageCounterPanel';
import Image from '../../private/Image/Image';
import ImageProps from '../../private/Image/props';
import Video from '../../private/Video/Video';
import VideoProps from '../../private/Video/props';
import ImagePannellumContainer from "../ImagePannellumContainer";
import ImagePannellumContainerProps from "../ImagePannellumContainer/props";

function VerticalImageGallery(props: VerticalImageGalleryProps) {
  const galleryRef = useRef<HTMLDivElement>(null);
  const thumbnailScrollOffsetRef = useRef<HTMLButtonElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageCounter, setImageCounter] = useState(1);
  const [thumbnailScrollOffset, setThumbnailScrollOffset] = useState<number | undefined>(0);
  const videoItemRefs = useRef<VideoRef>([]);

  const scrollToImage = (index: number) => {
    // Get the vertical position of the corresponding image element
    const imageElement = galleryRef?.current?.querySelector(`[data-id="${index}"]`) as HTMLElement | null;
    imageElement?.scrollIntoView({ behavior: 'smooth', block: 'center'});
  };

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(index);
    // Triggering a smooth scroll interrupts all other smooth scrolling actions that are ongoing on the page in Chrome, this issue is a
    // known bug in Chrome and has been reported to the Chromium team. The workaround is to delay the scroll action by 300ms.
    // https://issues.chromium.org/issues/40715316
    // https://issues.chromium.org/issues/40669363
    // https://issues.chromium.org/issues/40572042
    setTimeout(() => {
      scrollToImage(index);
    }, 300);
    setImageCounter(index + 1);
    props.onThumbnailClick && props.onThumbnailClick(index);
  };

  const handleButtonClick = (direction: string) => {
    let newIndex = activeIndex;
    if (direction === 'up') {
      newIndex = Math.max(activeIndex - 1, 0);
    } else { //down arrow
      newIndex = Math.min(activeIndex + 1, props.mediaItemList.length - 1);
    }
    setActiveIndex(newIndex);
    // Triggering a smooth scroll interrupts all other smooth scrolling actions that are ongoing on the page in Chrome, this issue is a
    // known bug in Chrome and has been reported to the Chromium team. The workaround is to delay the scroll action by 300ms.
    // https://issues.chromium.org/issues/40715316
    // https://issues.chromium.org/issues/40669363
    // https://issues.chromium.org/issues/40572042
    // Increased timeout as the arrows scroll all the thumbnails and it takes longer to scroll.
    setTimeout(() => {
      scrollToImage(newIndex);
    }, 600);
    setImageCounter(newIndex + 1);
    props.onArrowClick && props.onArrowClick(direction);
  };

  useEffect(() => {
    setThumbnailScrollOffset(thumbnailScrollOffsetRef?.current?.offsetHeight);
  }, [thumbnailScrollOffsetRef.current]);

  useEffect(() => {
    videoItemRefs.current.forEach((ref, index) => {
      if (index !== activeIndex) {
        ref?.current?.stopPlayingVideo();
      }
    });
  }, [activeIndex]);

  return (
    <div className={classNames('verticalImageGallery', style)}>
      <StickyGalleryBanner {...stickyGalleryBannerProps(props)}/>
      <div className={classNames('galleryWrapper', style)} ref={galleryRef}>
        <StackedGallery {...stackedGalleryProps(props, videoItemRefs)} forwardedRef={galleryRef}/>
        <div className={classNames('rightWrapper', style)}>
          <Button {...buttonUpProps()} onClick={() => handleButtonClick('up')} ref={thumbnailScrollOffsetRef}></Button>
          <Thumbnails {...thumbnailsProps(props)}
            onThumbnailClick={handleThumbnailClick}
            activeIndex={activeIndex}
            thumbnailScrollOffset={thumbnailScrollOffset}
            onThumbnailsScroll={props.onThumbnailsScroll}/>
          <Button {...buttonDownProps()} onClick={() => handleButtonClick('down')}></Button>
          <ImageCounterPanel show={true} count={imageCounter} total={props.mediaItemList.length} />
        </div>
      </div>
    </div>
  );
}

const stackedGalleryProps = (props: VerticalImageGalleryProps, childRefs: React.MutableRefObject<VideoRef>) : StackedGalleryProps=> {
  childRefs.current = props.mediaItemList.map((_, i: number) => childRefs.current[i] ?? createRef());
  return {
    children: props.mediaItemList.map((item: MediaItem, index: number) => {
      const lazyLoading = index > 0;
      const fetchPriority = index === 0;

      if (item?.mediaType === '360Image' || item?.mediaType === 'ImagePannellumContainer') {
        return <ImagePannellumContainer {...createPannellumProps(item)} key={index}/>
      }
      if (item?.mediaType === 'video' || item?.mediaType === 'external') {
        return <Video {...createVideoProps(item, index, childRefs)} key={index}/>
      }
      return <Image {...createImageProps(item, lazyLoading, fetchPriority)} key={index}/>
    }) as JSX.Element[],
    hideTopButtons: true,
    classNames: {
      desktopModalView: true
    }
  }
}

const stickyGalleryBannerProps = (props: VerticalImageGalleryProps): StickyGalleryBannerProps => {
  return {
    title: props.title,
    price: props.price,
    location: props.location,
    phoneNumber: props.phoneNumber,
    phoneText: props.phoneText,
    contactButtonText: props.contactButtonText,
    onClickPhoneMessage: props.onClickPhoneMessage,
    onClickClose: props.onClickClose,
    onClickContact: props.onClickContact
  }
}

const createVideoThumbnail = (item: ThumbnailsType) => ({
  Video: {
    title: item.title,
    src: item.src,
    overlay: config.Thumbnails.Video.overlay,
    childRef: { current: null },
    poster: item.poster,
    thumbnailVideo: item.thumbnailVideo
  },
});

const createImageThumbnail = (item: ThumbnailsType)=> ({
  Image: {
    src: item.src,
    alt: item.alt,
    srcset: item.srcset,
    sizes: item.sizes,
    defaultImage: defaultImage
  },
});

const isVideoThumbnail = (item: ThumbnailsType) => item?.mediaType === 'video' || item?.mediaType === 'external';

const createImageProps = (item: MediaItem, lazyLoading: boolean, fetchPriority: boolean) => ({
  alt: item.alt,
  src: item.src,
  srcset: item.srcset,
  sizes: item.sizes,
  dimensions: item.dimensions,
  defaultImage: defaultImage,
  lazyLoading,
  fetchPriority
}) as ImageProps;

const createVideoProps = (item: MediaItem, index: number, childRefs: React.MutableRefObject<VideoRef>) => ({
  title: item.title,
  src: item.src,
  childRef: childRefs.current[index] as VideoControlsRef,
  overlay: false,
}) as VideoProps;

const createPannellumProps = (item: MediaItem) => ({
  imageSource: item.src,
  id: item.id,
  config: {
    autoRotate: 0,
    autoLoad: true,
    showFullscreenCtrl: true,
    style: { height: '550px' }
  },
}) as ImagePannellumContainerProps;

const thumbnailsProps = (props: VerticalImageGalleryProps): ThumbnailsProps => {
  const thumbnailList = props.thumbnailList;
  const filteredThumbnailList = thumbnailList?.filter(item => item?.show !== false);


  return {
    items:  filteredThumbnailList?.map((item) => {
      if (isVideoThumbnail(item)) {
        return createVideoThumbnail(item);
      } else {
        return createImageThumbnail(item);
      }
    }) as ThumbnailItem[],
    classNames: {
      vertical: true,
    },
    isVertical: true,
    optsAttributes: {
      'data-reporting-click-internal-link-type': 'click',
      'data-reporting-click-internal-link-id': 'view_image_thumbnail'
    }
  };
};

const buttonUpProps = () : button => {
  return {
    Icon: {
      svg: chevron_up,
      alt: 'Scroll up',
      classNames: {
        s50: true
      }
    },
    buttonAttributes: {
      'data-reporting-click-internal-link-type': 'click',
      'data-reporting-click-internal-link-id': 'scroll_images_up'
    }
  };
};

const buttonDownProps = (): button => {
  return {
    Icon: {
      svg: chevron_down,
      alt: 'Scroll down',
      classNames: {
        s50: true
      }
    },
    buttonAttributes: {
      'data-reporting-click-internal-link-type': 'click',
      'data-reporting-click-internal-link-id': 'scroll_images_down'
    }
  };
};

export default React.memo(VerticalImageGallery);