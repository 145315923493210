import React, { useState } from 'react';
import LocationFilterProps from './props';
import './styles.scss';
import chevron_up from '../../../icons/chevron-up2.svg';
import chevron_down from '../../../icons/chevron-down2.svg';
import RadiusDropdown from '../RadiusDropdown/RadiusDropdown';
import CurrentLocation from '../CurrentLocation/CurrentLocation';
import TypeaheadSearch from '../TypeaheadSearch/TypeaheadSearch';

function LocationFilter(props: LocationFilterProps) {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`location-filter ${props.isMobile ? 'mobile-filter' : ''}`}>
      <div className='location-filter__heading' onClick={toggleAccordion}>
        <h4>{props.heading}</h4>
        <img src={isOpen ? chevron_up : chevron_down} alt='location filter toggle icon' />
      </div>
      {isOpen && (
        <div className='location-filter__content'>
          <div className='location-filter__search-wrapper'>
          <TypeaheadSearch
            placeholder={props.searchPlaceholder}
            options={props.searchOptions}
            notFoundText={props.searchNotFoundText}
            search={props.defaultSearch}
            onSearchChange={props.onLocationChange}
          />
          <CurrentLocation currentLocationText={props.currentLocationText} locationDeniedAlertText={props.locationDeniedAlertText} onLocationChange={props.onLocationChange} errorText={props.locationErrorText}/>
          </div>
          <RadiusDropdown
            options={props.radiusOptions}
            label={props.radiusLabel}
            defaultText={props.radiusDefaultText}
            onChangeRadius={props.onChangeRadius}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(LocationFilter);
