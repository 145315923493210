import React, {useState, useRef, useCallback, useEffect} from 'react';
import debounce from 'lodash/debounce';
import CarouselProps, {CarouselTouchEvent, CarouselValidItem, ListingItem, ListingSocialItem} from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import button from '../Button/props';
import Button from '../Button/Button';

import ListingProps from '../../public/Listing/props';
import listingSocial from '../../public/ListingSocial/props';
import arrow_left from '../../../icons/arrow_left.svg';
import arrow_right from '../../../icons/arrow_right.svg';
import CarouselItem from "./CarouselItem/CarouselItem";

import ImageProps from "../Image/props";
import defaultImage from '../../../icons/placeholder-image.svg';

const showCta = (items: CarouselValidItem[], index: number) => {
  return (
    (items.length >= 4 && index === 3) ||
    (items.length < 4 && index === items.length - 1)
  );
};

function Carousel(props: CarouselProps) {
  const [commonState, setCommonState] = useState({ mostInViewIndex: props.mainIndex || 0, listingState: props.items.map(() => 1) });
  const [keyState, setKeyState] = useState(0);
  const [hidden, setHidden] = useState(true);

  const ref: React.RefObject<HTMLUListElement> = useRef<HTMLUListElement>(null);
  const indexRef = useRef(0);
  const scrollTimeout = useRef(setTimeout(() => { return false; }, 0));
  const touching = useRef(false);
  const scrolling = useRef(false);
  const show = props.items.length < config.indicator.show ? props.items.length : config.indicator.show;
  const enabledUp = props.items.map(item => !!item?.enableTouchUp);

  let showLeftButton = !!props.LeftButton;
  let showRightButton = !!props.RightButton;

  const changeListingState = (index: number) => {
    setCommonState((prev) => ({
      ...prev,
      listingState: prev.listingState.map((state: number, i: number) => {
        return i === index ? Infinity: state;
      })
    }))
  };
  const scrollCarousel = props.touchScroll ? doScrollCarousel : doMoveCarousel;

  const setMostInViewIndex = useCallback((index: number) => {
    setCommonState((prev) => ({
      ...prev,
      mostInViewIndex: index,
      listingState: prev.listingState.map((state: number, i: number) => {
        return i === index && config.indicator.show > state ? config.indicator.show : state;
      })
    }));

    if (props.callBack) {
      props.callBack(index);
    }
    indexRef.current = index;
  }, [props]);

  useEffect(() => {
    if(typeof props.mainIndex === 'number') {
      changeListingState(props.mainIndex);
      const newIndex = scrollCarousel(ref, 0, indexRef.current, props.mainIndex);
      if (newIndex !== indexRef.current) {
        if (props.callBack) {
          props.callBack(newIndex);
        }
        indexRef.current = newIndex;
      }
    }
  }, [props.mainIndex]);

  const onScroll: () => void = useCallback(() => {
    scrolling.current = true;

    clearTimeout(scrollTimeout.current);

    scrollTimeout.current = setTimeout(() => {
      scrolling.current = false;

      if (!touching.current) {
        const index: number = scrollCarousel(ref, 0, indexRef.current);

        setMostInViewIndex(index);
      }
    }, 100);
  }, [setMostInViewIndex]);

  const stopEventOnDisabled = (ev: CarouselTouchEvent, index: number): void => {
    const upEvent = ['mouseup', 'touchend'].includes(ev.nativeEvent?.type);
    if(!(enabledUp[index] && upEvent)) {
      ev?.stopPropagation();
    }
  }

  const onTouch = useCallback((event: CarouselTouchEvent, touch: boolean) => {
    const theIndex: number = indexRef.current;
    stopEventOnDisabled(event, theIndex);

    touching.current = touch;

    if (!scrolling.current && !touching.current) {
      const index: number = scrollCarousel(ref, 0, indexRef.current);
      setMostInViewIndex(index);
    }
  }, [setMostInViewIndex]);


  const handleWheel: (event: React.WheelEvent<HTMLUListElement>) => void = useCallback((event: React.WheelEvent<HTMLUListElement>) => {
    event.stopPropagation();

    if (!event.deltaX) {
      const gain = event.deltaY > 0 ? 1 : -1;
      const index: number = scrollCarousel(ref, gain, indexRef.current);
      setMostInViewIndex(index);
    }
  }, [setMostInViewIndex]);

  const onClick: (event: React.MouseEvent<HTMLButtonElement>, gain: number) => void = useCallback((event: React.MouseEvent<HTMLButtonElement>, gain: number) => {
    if (gain === 1) {
      props.RightButton?.onClick ? props.RightButton.onClick(event) : null;
    } else {
      props.LeftButton?.onClick ? props.LeftButton.onClick(event) : null;
    }

    const index: number = scrollCarousel(ref, gain, indexRef.current);

    // This is super ugly, but we need to allow parent to change index without entering an infinite loop of re-renders.
    // Solution: refactor everything or use a carousel library.
    if(props.changeIndex) {
      props.changeIndex(index);
    } else {
      setMostInViewIndex(index);
    }

  }, [props.LeftButton, props.RightButton, setMostInViewIndex]);

  const handleItemClick: (e: React.MouseEvent<HTMLElement>, index: number) => void = (e: React.MouseEvent<HTMLElement>, index: number) => {
    props?.handleCarouselItemClick ? props?.handleCarouselItemClick(e, index) : null;
  }

  const handleResize = debounce(() => {
    setKeyState((prev) => prev + 1);
    props.callBack?.(commonState.mostInViewIndex);
    setMostInViewIndex(0);
  }, 800);

  useEffect(() => {
    setMostInViewIndex(0);
    // we would like to call useEffect only once, but not every time the props change
    // this will allow us to avoid one extra rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if(!props.disableResizeHandler) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      /* istanbul ignore else */
      if (!props.disableResizeHandler) {
        window.removeEventListener('resize', handleResize);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Extra comma is because the IDE complains for some super weird issue related with linting or TS versions :-)
  const carouselCustomProps = <T,>(key: string, index: number, value: T): T | ListingProps | listingSocial | ImageProps => {
    const bypassImageFilterState = props.innerImageFilter;
    switch (key) {
      case 'ListingSocial':
        return listingSocialProps(props, index, commonState.listingState[index], changeListingState, bypassImageFilterState);
      case 'Listing':
        return listingProps(props, index, commonState.listingState[index], changeListingState, bypassImageFilterState);
      case 'Image': {
        const imageProps = value as unknown as ImageProps;
        imageProps.defaultImage = defaultImage;
        return imageProps;
      }
      default:
        return value;
    }
  };

  if(props.items?.length <= 1) {
    showRightButton = false;
    showLeftButton = false;
  }

  let carouselItems: JSX.Element[];
  if(!props.items?.length) {
    const imageProps: ImageProps = { defaultImage: defaultImage, src: defaultImage, alt: ''};
    carouselItems = [<li key={0}><CarouselItem itemKey="Image" elementProps={imageProps}/></li>];
    showRightButton = false;
    showLeftButton = false;
  } else {
    carouselItems = props.items.map((item, index) => {
      const [key, value] = Object.entries(item)[0];
      const isCurrent = commonState.mostInViewIndex === index;
      return (<li key={index} onClick={(e) => handleItemClick(e, index)}>
          <CarouselItem itemKey={key} elementProps={carouselCustomProps(key, index, value)} isCurrent={isCurrent} />
        </li>)
      }
    );
  }

  return (
    <div
      className={classNames('carousel', style, props.classNames)}
      key={keyState}
      data-key={keyState}
      data-role={props.role}
      onMouseEnter={() => setHidden(false)}
      onMouseLeave={() => setHidden(true)}>
      {props.cta?.text && showCta(props.items, indexRef.current) &&
      <div data-role="carousel-cta" className={classNames("cta-overlay", style)}>
        <p>{props.cta.text}</p>
      </div>}
      {showLeftButton && <Button {...leftButtonProps(props, onClick, indexRef, hidden)} role="prev"></Button>}
      {showRightButton && <Button {...rightButtonProps(props, onClick, indexRef, hidden)} role="next"></Button>}
      {props.classNames?.indicator && <div
        style={{
          width: config.indicator.size * show * (config.indicator.gap + 1),
          height: config.indicator.size,
          marginLeft: -(config.indicator.size * show * (config.indicator.gap + 1) / 2)
        }}
      >
        {props.items.map((_item: CarouselValidItem , index: number) =>
          <span
            key={index}
            style={{
              width: config.indicator.size,
              height: config.indicator.size,
              marginLeft: index ? config.indicator.size * config.indicator.gap : 0
            }}
            aria-current={commonState.mostInViewIndex === index}
          ></span>
        )}
      </div>}
      <ul
        ref={ref}
        onScroll={onScroll}
        onWheel={props.classNames?.overflow ? handleWheel : undefined}
        onMouseDown={(event: React.MouseEvent<HTMLUListElement>) => onTouch(event, true)}
        onMouseUp={(event: React.MouseEvent<HTMLUListElement>) => onTouch(event, false)}
        onTouchStart={(event: React.TouchEvent<HTMLUListElement>) => onTouch(event, true)}
        onTouchEnd={(event: React.TouchEvent<HTMLUListElement>) => onTouch(event, false)}
      >
        {carouselItems}
      </ul>
    </div>
  );
}

const hideIt = (props: CarouselProps, indexRef: {current: number} , shouldHideArrowButton: boolean) => {
  if (props.hideLeftArrowButtonOnFirstSlide && indexRef.current === 0) {
    return { 'invisible': true };
  }
  if (props.showArrowButtonsOnHover && !(indexRef.current !== 0 && !shouldHideArrowButton)) {
    return { 'invisible': true };
  }
  return { 'invisible': false };
}

const leftButtonProps = (props: CarouselProps, onClick: (event: React.MouseEvent<HTMLButtonElement>, gain: number) => void, indexRef: {current: number}, shouldHideArrowButton: boolean): button => {
  const shouldDisableButton = (props.hideLeftArrowButtonOnFirstSlide && indexRef.current === 0);
  return {
    ...props.LeftButton as button,
    classNames: {
      ...config.Button.classNames,
      ...(hideIt(props, indexRef, shouldHideArrowButton))
    },
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (!shouldDisableButton) {
        onClick(event, -1);
      }
    },
    Icon: {
      svg: arrow_left,
      alt: props.prevIconAlt || 'Previous Slide'
    }
  };
}

const rightButtonProps = (props: CarouselProps, onClick: (event: React.MouseEvent<HTMLButtonElement>, gain: number) => void, indexRef: {current: number}, shouldHideArrowButton: boolean): button => {
  const shouldDisableButton = (props.cta?.text && showCta(props.items, indexRef.current));
  const hideArrowButton = (props.showArrowButtonsOnHover && shouldHideArrowButton || shouldDisableButton);
  return {
    ...props.RightButton as button,
    classNames: {
      ...config.Button.classNames,
      ...(hideArrowButton ? { 'invisible': true } : {})
    },
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (!shouldDisableButton) {
        onClick(event, 1);
      }
    },
    Icon: {
      svg: arrow_right,
      alt: props.nextIconAlt || 'Next Slide'
    }
  };
}

// This is a situation where we can use the any type since we do not know what data we get
const nextPrevFunction = (fn: any, changeListingState: (index: number) => void, index: number) => {
  let finalFn = undefined;
  if (typeof fn === 'function') {
    finalFn = (event: React.MouseEvent<HTMLButtonElement>) => {
      changeListingState(index);
      fn(event);
    }
  }
  return finalFn;
};

/**
 * @todo: refactor listings types definitions.....
 */
const prepareListingProps = <T extends listingSocial>(theListing: T, listingState: number, changeListingState: (index: number) => void, index: number, bypassImageFilterState?: boolean): T => {
  const theListingCarousel = theListing?.carousel;
  const images = theListingCarousel?.images || [];
  const carouselPrevClick = nextPrevFunction(theListingCarousel?.prevClick, changeListingState, index);
  const carouselNextClick = nextPrevFunction(theListingCarousel?.nextClick, changeListingState, index);
  const filteredImages = bypassImageFilterState ?
    images.filter((image: {image: string, imageAlt: string}) => image.image !== '') :
    images.filter((_image: { [key: string]: string }, i: number) => i < listingState);

  const theProps = {
    ...theListing,
    carousel: {
      ...theListingCarousel,
      images: filteredImages,
      prevClick: carouselPrevClick,
      nextClick: carouselNextClick
    }
  };
  return theProps as T;
}

const listingSocialProps = (props: CarouselProps, index: number, listingState: number, changeListingState: (index: number) => void, bypassImageFilterState?: boolean): listingSocial => {
  const item = props.items[index] as ListingSocialItem;
  const theListing = item.ListingSocial;
  return prepareListingProps<listingSocial>(theListing, listingState, changeListingState, index, bypassImageFilterState);
}

/* -- deprecated -- */
const listingProps = (props: CarouselProps, index: number, listingState: number, changeListingState: (index: number) => void, bypassImageFilterState?: boolean): ListingProps => {
  const item = props.items[index] as ListingItem;
  const theListing = item.Listing as unknown as listingSocial;
  return prepareListingProps<listingSocial>(theListing, listingState, changeListingState, index, bypassImageFilterState);
}

// Ugly hack to deal with conveyer and image carousel requiring a different behaviour to avoid a very big refactor.
// Proper solution to this mess is to, either implement a library, or use an infinite scroll
const doMoveCarousel = (
  ref: React.RefObject<HTMLUListElement>,
  gain: number,
  currentElement: number,
  exactIndex?: number
) => {
  const carousel: HTMLUListElement = ref.current as HTMLUListElement;
  const carouselItems: HTMLCollectionOf<HTMLLIElement> =
    carousel.children as HTMLCollectionOf<HTMLLIElement>;
  let mostInViewIndex = currentElement;
  if (typeof exactIndex === 'number') {
    if (exactIndex <= carouselItems.length - 1) {
      mostInViewIndex = exactIndex;
    }
  } else {
    // we move left
    if (gain < 0) {
      mostInViewIndex--;
      if (mostInViewIndex < 0) {
        mostInViewIndex = carouselItems.length - 1;
      }
    } else if (gain > 0) {
      // we move right
      mostInViewIndex++;
      if (mostInViewIndex > carouselItems.length - 1) {
        mostInViewIndex = 0;
      }
    }
  }
  carousel.scrollLeft = carouselItems[mostInViewIndex].offsetLeft;

  const carouselIndicators: HTMLElement =
    carousel.previousElementSibling as HTMLElement;
  if (carouselIndicators?.tagName === 'DIV') {
    carouselIndicators.scrollLeft =
      (carouselIndicators.children[mostInViewIndex] as HTMLElement).offsetLeft -
      carouselIndicators.clientWidth / 2 +
      carouselIndicators.children[mostInViewIndex].clientWidth / 2;
  }

  return mostInViewIndex;
};

const doScrollCarousel = (ref: React.RefObject<HTMLUListElement>, gain: number) => {
  const carousel: HTMLUListElement = ref.current as HTMLUListElement;
  const carouselItems: HTMLCollectionOf<HTMLLIElement> = carousel.children as HTMLCollectionOf<HTMLLIElement>;
  const scrollPosition = carousel.scrollLeft;
  let mostInViewIndex = (Math.round(scrollPosition / carouselItems[0].offsetWidth) || 0) + gain;
  mostInViewIndex = mostInViewIndex > carouselItems.length - 1 ? 0 : mostInViewIndex < 0 ? carouselItems.length - 1 : mostInViewIndex;

  carousel.scrollLeft = carouselItems[mostInViewIndex].offsetLeft;

  const carouselIndicators: HTMLElement =
    carousel.previousElementSibling as HTMLElement;
  if (carouselIndicators?.tagName === 'DIV') {
    carouselIndicators.scrollLeft =
      (carouselIndicators.children[mostInViewIndex] as HTMLElement).offsetLeft -
      carouselIndicators.clientWidth / 2 +
      carouselIndicators.children[mostInViewIndex].clientWidth / 2;
  }

  return mostInViewIndex;
}

export default React.memo(Carousel);

