/**
 * Current parsable listings:
 * - ListingStandard
 * - ListingSponsored
 * - ListingEnhanced
 * - ListingOem
 * - ListingCarouselCard
 */

// Used letters to minimize size, probably not the best approach...
// Preferably, use alphabetical order and letter position in the word.
export const FOOTER_SELECTOR = 'f';
export const IMG_SELECTOR = 'i';
export const LISTING_SELECTOR = 'l';
export const PRICE_DROP_SELECTOR = 'pd';
export const TOGGLE_SELECTOR = 'tg';
export const TOOLBAR_SELECTOR = 'to';
