import React from 'react';
import LinkContainerProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';

function LinkContainer(props: LinkContainerProps) {
  return (
      <> { props.hyperlinkAttributes
          ? <a {...props.hyperlinkAttributes}
              {...props.dataSelector}
              className={classNames('linkContainer', style)}>
              {props.children}
           </a>
          : <>{props.children}</>
      } </>
  );
}

export default React.memo(LinkContainer);
