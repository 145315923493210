import React from 'react';
import ColumnContainerProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';

function ColumnContainer(props: ColumnContainerProps) {
  return (
    <div className={classNames('columnContainer', style)}>
      {props.children}
    </div>
  );
}

export default React.memo(ColumnContainer);