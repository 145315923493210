import React, { useMemo } from 'react';
import listingBody from './props';
import style from './style.module.scss';
import classNames from '../../../../../../../utils/classNames';
import config from './config.json';

import ContentProps from '../../../../../Content/props';
import Content from '../../../../../Content';

function ListingBody(props: listingBody) {
  return (
    <span className={classNames('listingBody', style)}>
      <Content {...useSellerContentProps(props)}></Content>
    </span>
  );
}

const useSellerContentProps = (props: listingBody): ContentProps => {
  return useMemo(() => {
    return {
      ...props.SellerContent as ContentProps,
      classNames: config.SellerContent.classNames
    };
  }, [props]);
}

export default React.memo(ListingBody);