import React from 'react';
import LabelProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function Label(props: LabelProps) {
  return (
    <label
      htmlFor={props.htmlFor}
      className={classNames('label', style, props.classNames)}
      { ...(props['data-e2e'] && { 'data-e2e': props['data-e2e'] }) }
    >
      {props.text}
    </label>
  );
}

export default React.memo(Label);