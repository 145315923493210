import React from "react";
import Tooltip from "react-tooltip-lite";
import BoatDetailProps from "./props";
import style from "./style.module.scss";
import config from "./config.json";

import Icon from "../../../../private/Icon/Icon";
import IconProps from "../../../../private/Icon/props";
import Title from "../../../../private/Title/Title";
import title from "../../../../private/Title/props";
import Content from "../../../../private/Content/Content";
import ContentProps from "../../../../private/Content/props";
import tooltipLite from "../../../../private/TooltipLite/props";

import boatClass from "../../../../../icons/boat-class.svg";
import boatModel from "../../../../../icons/boat-model.svg";
import capacity from "../../../../../icons/capacity.svg";
import engine from "../../../../../icons/engine.svg";
import engineHours from "../../../../../icons/hours.svg";
import length from "../../../../../icons/length.svg";
import power from "../../../../../icons/power.svg";
import year from "../../../../../icons/year.svg";
import info from "../../../../../icons/info.svg";

import classNames from "../../../../../utils/classNames";

const icons: { [key: string]: string } = {
  boatClass,
  boatModel,
  capacity,
  engine,
  engineHours,
  length,
  power,
  year,
};

function BoatDetail(props: BoatDetailProps) {
  return (
    <div className={classNames("boatDetail", style)}>
      <Icon {...iconProps(props)} />
      <div>
        <Title {...titleProps(props)} />
        <Content {...contentProps(props)} />
      </div>
    </div>
  );
}

const iconProps = (props: BoatDetailProps): IconProps => {
  return {
    svg: props.icon && icons[props.icon],
    alt: props.iconAlt || props.icon,
    classNames: config.Icon.classNames,
  };
};

const titleProps = (props: BoatDetailProps): title => {
  return {
    text: props.title,
    classNames: config.Title.classNames,
    children: props.tooltip && (
      <Tooltip {...tooltipProps(props)}>
        <Icon {...{ svg: info, classNames: { button: true }, alt: props.tooltip }}></Icon>
      </Tooltip>
    ),
  };
};

const contentProps = (props: BoatDetailProps): ContentProps => {
  return {
    text: props.content,
    classNames: config.Content.classNames,
  };
};

const tooltipProps = (props: BoatDetailProps): tooltipLite => {
  return {
    content: props.tooltip,
    className: classNames("tooltip", style),
    forceDirection: true,
    background: "black",
    tagName: "span",
    direction: "up",
    color: "white",
  };
};

export default React.memo(BoatDetail);
