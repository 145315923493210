import React, { useCallback, useMemo } from 'react';
import listingPreviewSnapshot from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import LabelProps from '../../private/Label/props';
import ImageProps from '../../private/Image/props';
import title from '../../private/Title/props';
import ContentProps from '../../private/Content/props';
import button from '../../private/Button/props';

import ListingPreviewProps from '../../private/ListingPreview/props';
import ListingPreview from '../../private/ListingPreview';

import ListingCaptionProps from '../../private/ListingPreview/components/ListingCaption/props';
import listingSnapshot from '../../private/ListingPreview/components/ListingCaption/components/ListingSnapshot/props';

function ListingPreviewSnapshot(props: listingPreviewSnapshot) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    
    props.onClick(event);
  }, [props]);

  return (
    <span
      className={classNames('listingPreviewSnapshot', style)}
      onClick={onClick}
      style={{ height: config.style.height }}
    >
      <ListingPreview {...useListingPreviewProps(props)}></ListingPreview>
    </span>
  );
}

const useListingPreviewProps = (props: listingPreviewSnapshot): ListingPreviewProps => {
  return useMemo(() => {
    return {
      Image: {
        src: props.image,
        alt: props.imageAlt,
        height: config.ListingPreview.Image.height
      } as ImageProps,
      ListingCaption: {
        ListingSnapshot: {
          Button: {
            onClick: props.snapshot?.onClick,
            Label: {
              text: props.snapshot?.link
            } as LabelProps
          } as button,
          Title: {
            text: props.snapshot?.title
          } as title,
          Content: {
            text: props.snapshot?.content
          } as ContentProps
        } as listingSnapshot
      } as ListingCaptionProps
    };
  }, [props]);
}

export default React.memo(ListingPreviewSnapshot);