import React from 'react';
import ListingCaptionProps from './props';
import style from './style.module.scss';
import classNames from '../../../../../utils/classNames';

import listingHeader from './components/ListingHeader/props';
import ListingHeader from './components/ListingHeader';
import ListingPriceProps from './components/ListingPrice/props';
import ListingPrice from './components/ListingPrice';
import listingBody from './components/ListingBody/props';
import ListingBody from './components/ListingBody/ListingBody';
import listingSnapshot from './components/ListingSnapshot/props';
import ListingSnapshot from './components/ListingSnapshot/ListingSnapshot';
import listingCard from './components/ListingCard/props';
import ListingCard from './components/ListingCard/ListingCard';

function ListingCaption(props: ListingCaptionProps) {
  return (
    <span className={classNames('listingCaption', style, props.classNames)}>
      {props.ListingHeader && <ListingHeader {...props.ListingHeader as listingHeader}></ListingHeader>}
      {props.ListingPrice && <ListingPrice {...props.ListingPrice as ListingPriceProps}></ListingPrice>}
      {props.ListingBody && <ListingBody {...props.ListingBody as listingBody}></ListingBody>}
      {props.ListingSnapshot && <ListingSnapshot {...props.ListingSnapshot as listingSnapshot}></ListingSnapshot>}
      {props.ListingCard && <ListingCard {...props.ListingCard as listingCard}></ListingCard>}
    </span>
  );
}

export default React.memo(ListingCaption);