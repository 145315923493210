import React from 'react';
import style from "./style.module.scss";
import classNames from "../../../../../utils/classNames";
import Title from "../../../../private/Title";
import Link from "../../../../private/Link";
import link from "../../../../private/Link/props";
import config from "../../config.json";
import title from "../../../../private/Title/props";
import InfoDealerPanelContentProps, {NilFn} from "./props";

function InfoDealerPanelContent(props: InfoDealerPanelContentProps) {
  const linkBtnProps = linkButtonProps(props);
  const websiteProps = linkWebsiteProps(props);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const nil: NilFn = () => {};
  const onClickLinkHandler = props.onClickLinkHandler || nil;

  return (
    <div className={classNames('infoDealerPanelContent', style)}>
      <Title {...titleProps(props)} />
      <div>
        {props.children}
        {websiteProps &&
          <span onClick={onClickLinkHandler}>
            <Link {...websiteProps}></Link>
          </span>
        }
        {linkBtnProps && <Link {...linkBtnProps} />}
      </div>
    </div>
  );
}

function linkButtonProps(props: InfoDealerPanelContentProps): link | null {
  if (!props.buttonText) {
    return null;
  }
  const linkBtn: link = {
    ...config.LinkButton,
    text: props.buttonText,
    url: props.buttonLink
  };

  return linkBtn;
}

const titleProps = (props: InfoDealerPanelContentProps): title => {
  return {
    ...config.Title,
    text: props.title,
  }
};

const linkWebsiteProps = (props: InfoDealerPanelContentProps): link | null => {
  if (!props.linkUrl || !props.linkText) {
    return null;
  }
  const classNames = config.LinkText.classNames;
  const rel = props?.rel || config.LinkText.rel;
  const target = props?.linkUrlTarget;
  const websiteLink: link = {
    classNames,
    text: props.linkText,
    url: props.linkUrl,
    rel,
    target
  }
  return websiteLink;
}

export default React.memo(InfoDealerPanelContent);
