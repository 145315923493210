import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import classNames from '../../../utils/classNames';
import { useKeyPress } from '../../../hooks/useKeyPress';
import Collapsible from "../../private/Collapsible";
import Input from "../../private/Input";
import { FilterSearchProps } from './props';
import style from "./style.module.scss";
import Action from '../Action';
import Button from "../../private/Button";

function FilterSearch({
    buttonText = 'Apply',
    headerText = 'Keyword',
    id = 'filterSearchInput',
    placeholder = 'e.g. outboard',
    value = '',
    onApplyValue,
    portal
  }: FilterSearchProps) {
  const componentRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const [inputValueWhenPressingEnter, setInputValueWhenPressingEnter ] = useState(value);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { isEnterPressed } = useKeyPress(componentRef);

  const handleApplyValue = useCallback(() => onApplyValue && onApplyValue(inputValue), [inputValue, onApplyValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value])

  useEffect(() => {
    if (isEnterPressed) {
      if(inputValueWhenPressingEnter !== inputValue){
        setInputValueWhenPressingEnter(inputValue);
        handleApplyValue();
      }
    }
  }, [handleApplyValue, inputValue, isInputFocused, isEnterPressed, inputValueWhenPressingEnter, onApplyValue, value]);

  return (
    <div className={classNames("filter-search", style)}>
      <Collapsible header={headerText} isOpen closeOnOutsideClick={false}>
        <div className={classNames("filter-search-data", style)}>
          <Input
            Label={{ text: headerText, hidden: true }}
            classNames={{ text: true }}
            value={value}
            id={id}
            name={id}
            placeholder={placeholder}
            onChange={({target}) => setInputValue(target.value)}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            ref={componentRef}
          />
          { portal === 'bt' ?
            <Action size="small" variant="secondary" className={classNames("action", style)} label={buttonText} onClick={() => handleApplyValue()} /> :
            <Button classNames={{ tertiary: true }} Label={{ text: buttonText}} onClick={() => handleApplyValue()} />
          }

        </div>
      </Collapsible>
    </div>
  );
}

export default memo(FilterSearch);
