import React, {useEffect, useCallback} from 'react';
import EmblaSwiperProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import useEmblaCarousel from 'embla-carousel-react';
import {
  EmblaEventType,
  EmblaOptionsType,
} from 'embla-carousel';

function EmblaSwiper(props: EmblaSwiperProps) {

  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'start' } as EmblaOptionsType);

  const slideInView = useCallback((emblaApi) => {
    const newIndex = emblaApi.selectedScrollSnap();
    props.onChange?.(newIndex);
  }, [props]);

  useEffect(() => {
    if (!emblaApi) return

    slideInView(emblaApi);
    emblaApi.on('select' as EmblaEventType, slideInView);

  }, [emblaApi, slideInView])


  return (
    <div className={classNames('emblaSwiper', style)} data-e2e='emblaSwiper' ref={emblaRef}>
      <div className={classNames('emblaContainer', style)}>
        {props.slides.map((slide, index) => (
          <div className={classNames('emblaSlide', style)} data-e2e='emblaSwiperSlide' key={index}>
            {slide}
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(EmblaSwiper);

