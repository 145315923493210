import React from 'react';
import BooleanTypeQuestionProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import HeadingTag from '../HeadingTag';

function BooleanTypeQuestion(props: BooleanTypeQuestionProps) {
  const [selectedOption, setSelectedOption] = React.useState('');

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    props.onChange && props.onChange();
  };

  return (
    <div className={classNames('booleanTypeQuestion', style)} data-testing='boolean-type-question'>
      <HeadingTag headingLevel={3} heading={props.questionTitle} />
      <div className={classNames('options', style)}>
      {props.options && props.options.map((option) => (
          <div key={option} className={classNames('option', style)} onChange={handleOptionChange}
          data-e2e={selectedOption === option ? 'selected-option' : 'unselected-option'}>
            <label>
              <input
                type='radio'
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
                name={ props.name }
              />
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(BooleanTypeQuestion);