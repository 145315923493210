import React, { useRef } from "react";
import SearchAlertsModalProps from "./props";
import style from "./style.module.scss";
import classNames from "../../../utils/classNames";
import config from "./config.json";

import Modal from "../../private/Modal";
import ModalProps from "../../private/Modal/props";
import Title from "../../private/Title";
import TitleProps from "../../private/Title/props";
import Icon from "../../private/Icon";
import IconProps from "../../private/Icon/props";
import Content from "../../private/Content";
import ContentProps from "../../private/Content/props";
import Input from "../../private/Input";
import InputProps from "../../private/Input/props";
import Button from "../../private/Button";
import ButtonProps from "../../private/Button/props";
import Link from "../../private/Link";
import LinkProps from "../../private/Link/props";
import warning from "../../../icons/warning.svg";


function SearchAlertsModal(props: SearchAlertsModalProps) {
  const fromRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const obj: { [key: string]: FormDataEntryValue | null } = {};

    formData.forEach((value, key) => {
      obj[key] = value;
    });

    props.onSubmit(e, obj);
  };


  const titleProps = (props: SearchAlertsModalProps): TitleProps => ({
    text: props.title,
    classNames: config.Title.classNames,
  });

  return (
    <div className={classNames("searchAlertsModal", style)}>
      <Modal {...modalProps(props)}>
        <section>
          <div
            className={classNames("message", style, {
              ...props.classNames,
              warning: props.classNames?.warning || false,
            })}
          >
            <Icon {...iconProps()}></Icon>
            <Content {...textWarningProps(props)}></Content>
          </div>
          <Title {...titleProps(props)} />
          <ul>
            {props.list?.map((item, index) => (
              <li key={index}>{`${item.text} : ${item.value}`}</li>
            ))}
          </ul>
          <form aria-label="searchAlertModalForm" ref={fromRef} onSubmit={handleSubmit}>
            {props?.invalidFields && props?.invalidFields?.length > 0 && (
              <Content {...invalidMessageProps(props)} />
            )}
            <div className={classNames("form-group", style)}>
              <Input {...inputEmailProps(props)} />
              <Button {...buttonProps(props)} />
            </div>
          </form>
          <div className={classNames("footer", style)}>
            <Content {...textFooterProps(props)}></Content>
            <Link {...linkProps(props)}></Link>
          </div>
        </section>
      </Modal>
    </div>
  );
}

const iconProps = (): IconProps => {
  return {
    svg: warning,
  };
};

const invalidMessageProps = (props: SearchAlertsModalProps): ContentProps => {
  return {
    text: props.invalidMessage || "* Please correct highlighted errors.",
    classNames: config.Content.classNames,
  };
};

const modalProps = (props: SearchAlertsModalProps): ModalProps => {
  return {
    Button: {
      onClick: props.modal?.onClose,
    },
    Blocker: {
      onClick: props.modal?.onClose,
      classNames: config.Blocker.classNames,
    },
    width: config.Modal.width,
    classNames: config.Modal.classNames,
  };
};

const linkProps = (props: SearchAlertsModalProps): LinkProps => {
  return {
    onClick: props.link.onClick,
    url: props.link.url,
    text: props.link.text,
    classNames: config.Link.classNames,
    target: props.link.target,
    rel: props.link.rel,
  };
};

const textWarningProps = (props: SearchAlertsModalProps): ContentProps => {
  return {
    classNames: config.TextWarning.classNames,
    text: props.textWarning,
  };
};

const textFooterProps = (props: SearchAlertsModalProps): ContentProps => {
  return {
    classNames: config.Content.classNames,
    text: props.textFooter,
  };
};

const inputEmailProps = (props: SearchAlertsModalProps): InputProps => {
  return {
    Label: {
      text: props.email.label || "Your Email",
    },
    id: props.email.id,
    type: "email",
    name: "email",
    value: props.email.value,
    onChange: props.email.onChange,
    onBlur: props.email.onBlur,
    classNames: config.Input.classNames,
    invalid: props.invalidFields?.includes("email"),
  };
};

const buttonProps = (
  props: SearchAlertsModalProps
): ButtonProps => {
  return {
    Label: {
      text: props.buttonLabel || "Save Search",
    },
    type: "submit",
    disabled: props.buttonDisabled,
    classNames: config.Button.classNames,
  };
};

export default React.memo(SearchAlertsModal);
