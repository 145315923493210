import React , {useMemo} from 'react';
import footerSocial from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../utils/classNames';

import FooterSocialConnect from './components/FooterSocialConnect';
import footerSocialConnect from './components/FooterSocialConnect/props'; 

import FooterSocialStores from './components/FooterSocialStores';
import footerSocialStores from './components/FooterSocialStores/props';

function FooterSocial(props: footerSocial) {
  return (
    <div className={classNames('footerSocial', style)}>
      <div>
        <FooterSocialConnect {...useFooterSocialConnectProps(props)}/>
        <FooterSocialStores {...useFooterSocialStoresProps(props)}/>
      </div>    
    </div>
  );
}

const useFooterSocialConnectProps = (props: footerSocial): footerSocialConnect => {
  return useMemo(() => {
    return {
      title: props.title,
      items: props.items
    };
  }, [props]);
}

const useFooterSocialStoresProps = (props: footerSocial): footerSocialStores => {
  return useMemo(() => {
    return {
      titleStores: props.titleStores,
      stores: props.stores
    };
  }, [props]);
}

export default React.memo(FooterSocial);