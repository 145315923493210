import React, {useRef, useEffect, useState, useCallback} from 'react';
import YouTube, { YouTubePlayer } from 'react-youtube';
import VideoProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';

import Icon from '../../private/Icon/Icon';
import icon from '../../private/Icon/props';
import play_icon from '../../../icons/play_icon.svg';


const youtube_parser = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    let videoId;

    if (hostname === 'youtu.be') {
      videoId = parsedUrl.pathname.slice(1);
    } else if (hostname === 'www.youtube.com' || hostname === 'youtube.com') {
      videoId = parsedUrl.searchParams.get('v');
      if (!videoId) {
        const paths = parsedUrl.pathname.split('/');
        if (paths.includes('embed') || paths.includes('v')) {
          videoId = paths.pop();
        }
      }
    }

    return videoId && videoId.length === 11 ? videoId : undefined;
  } catch (e) {
    return undefined;
  }
};



function Video( props: VideoProps) {
  const videoYTElementRef = useRef<YouTubePlayer>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const youtubeVideoId = youtube_parser(props.src);

  const onReady = (event: YouTubePlayer) => {
    videoYTElementRef.current = event.target;
  };

  const startPlayingVideo = (doPlayVideo?: boolean) => {
    if (doPlayVideo) {
      if (youtubeVideoId && videoYTElementRef.current) {
        videoYTElementRef.current.playVideo();
      } else if (videoRef.current) {
        videoRef.current.play();
      }
    }
  }

  useEffect(() => {
    startPlayingVideo(props.playVideo);
  });

  const stopPlayingVideo = () => {
    const videoElement = videoRef.current;
    const videoYTElement =videoYTElementRef.current;

    if (videoElement) {
      videoElement.currentTime = 0;
      videoElement.pause();
    }

    videoYTElement?.stopVideo();
    if (props.handlePause) {
      props.handlePause();
    }
  };

  props.childRef.current = {
    stopPlayingVideo: stopPlayingVideo,
    startPlayingVideo: startPlayingVideo
  };

  if (youtubeVideoId) {
    return (
      <YouTube
        className="youtube-video"
        style={{ height: '100%', width: '100%' }}
        onReady={onReady}
        onPause={props.handlePause}
        iframeClassName="video-player"
        videoId={youtubeVideoId}
        opts={{
          height: '100%',
          width: '100%',
          ...props.optsAttributes
        }}
      />
    );
  }

  return (
    <video
      ref={videoRef}
      className={classNames('video', style)}
      title={props.title}
      data-e2e={props.e2e}
      {...(!props.hideControls ? { controls: true } : {})}
      {...props.optsAttributes}
    >
      <source src={props.src} />
    </video>
  );
}

const VideoOverlay = (props: VideoProps) => {
  const [playVideo, setPlayVideo] = useState(props.playVideo);
  const [hideOverlay, setHideOverlay] = useState(false);
  const [hasOverlay, setHasOverlay] = useState(true);
  const youtubeVideoId = youtube_parser(props.src)

  const handleClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setPlayVideo(true);
    setHideOverlay(true);
    props.overlayClick && props.overlayClick(event);
  }, [props]);

  const handleVideoClick = () => {
    setPlayVideo(!playVideo);
    setHasOverlay(!hasOverlay);
  };
  const handlePause = () => {
    setPlayVideo(false);
    setHasOverlay(true);
  };

  if (props.swipeVideosOverlay) {  
    const newProps = {...props, playVideo, handlePause};
    return (
      <div className={classNames('videoOverlay', style)} data-e2e='swipeVideosOverlay' onClick={handleVideoClick}>
          <div className={classNames('swipeVideosOverlay', style, { hide: !hasOverlay })} >
          </div>
        <Video {...newProps} />
      </div>
    )
  }

  if (props.customPlayIcon) {  
    const newProps = {...props, playVideo: playVideo};
    return (
      <div className={classNames('videoOverlay', style)} data-e2e='customPlayIcon'>
          <div className={classNames('videoOverlayColor', style, { hideOverlay: hideOverlay })} onClick={!props.hideControls ? handleClick : undefined}>
          {youtubeVideoId ? null : <Icon {...iconProps()}></Icon>}
          </div>
        <Video {...newProps} />
      </div>
    )
  }

  if (props.overlay) {
    return (
      <div className={classNames('videoOverlay', style)} data-e2e='videoOverlay' onClick={props.overlayClick}>
        <div></div>
        <Video {...props} />
      </div>
    )
  }

  return <Video {...props} />
}

const iconProps = (): icon => {
  return {
    svg: play_icon,
    alt: 'play icon'
  };
};

export default React.memo(VideoOverlay);
