import React from 'react';
import FlexLinksProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Link from '../../private/Link';
import LinkProps from '../../private/Link/props';
import externalLink from '../../../icons/external-link.svg';

const icons: { [key: string]: string } = {
  externalLink
};


function FlexLinks({customClassName='', ...props}: FlexLinksProps) {
  const linkProps = (link: LinkProps) => {
    return {
      classNames: {
        button: link.svg,
        linkWithIcon: link.svg,
      },
      Icon: {
        svg: link.svg && icons[link.svg],
        alt: link.alt,
      },
    };
  };
  return (
    <div className={`${classNames('flexLinks', style, props.classNames)} ${customClassName}`} data-e2e='flexLinks'>
      {props.links.map((link, index) => link.show && (
        <Link
        {...linkProps(link)}
        key={index}
        text={link.text}
        data-e2e={link.text}
        url={link.href}
        onClick={link.onClick}
        hyperlinkAttributes={link.hyperlinkAttributes}
        />
      ))}
    </div>
  );
}

export default React.memo(FlexLinks);


