import React from 'react';
import ListingSocialWithEmblaProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';
import EmblaCarousel from '../EmblaCarousel';
import Image from '../../private/Image/Image';
import ImageProps from '../../private/Image/props';
import ToggleProps from '../../private/Toggle/props';
import Toggle from '../../private/Toggle';
import ListingHeader from '../../private/ListingPreview/components/ListingCaption/components/ListingHeader/ListingHeader';
import List from '../../private/List';
import Button from '../../private/Button';

import defaultImage from '../../../icons/placeholder-image.svg';
import thumb_off from '../../../icons/thumb_off.svg';
import thumb_on from '../../../icons/thumb_on.svg';

function ListingSocialWithEmbla(props: ListingSocialWithEmblaProps) {
  return (
    <div
      className={classNames('listingSocialWithEmbla', style)}
      data-e2e='listingSocialWithEmbla'
    >
      <div className={classNames('listingHead', style)}>
        <EmblaCarousel classNames={{ withIndicators: true }} prevOnClick={props.handleCarouselPrevClick} nextOnClick={props.handleCarouselNextClick}>
          {props.items.map((item, index) => (
            <Image {...createImageProps(item, index)} key={index}/>
          ))}
        </EmblaCarousel>
        <div className={classNames('listingHeadInfo', style)}>
          <ListingHeader {...listingHeaderProps(props)} />
          <Toggle {...useToggleProps(props)}></Toggle>
        </div>
      </div>
      <div className={classNames('listingBoatDetails', style)}>
        <List {...useListProps(props)}></List>
      </div>
      <div className={classNames('listingButtons', style)}>
        <Button {...shareButtonProps(props)} />
        <Button {...contactButtonProps(props)} />
      </div>
    </div>
  );
}
const createImageProps = (item: ImageProps, index: number) => {
  return {
    alt: item.alt,
    src: item.src,
    srcset: item.srcset,
    sizes: item.sizes,
    defaultImage: defaultImage,
    lazyLoading: index > 0,
    fetchPriority: index === 0,
  };
};
const listingHeaderProps = (props: ListingSocialWithEmblaProps) => {
  return {
    Title: {
      text: props.boatName,
    },
    Subtitle: {
      text: props.price,
    },
    Content: {
      text: props.location,
    },
  };
};

const useToggleProps = (props: ListingSocialWithEmblaProps): ToggleProps => {
  return {
    onClick: props.handleLikeClick,
    state: props.like?.state || 'off',
    states: [
      {
        state: 'off',
        Icon: {
          iconSrc: thumb_off,
          alt: 'thumb off',
        },
      },
      {
        state: 'on',
        Icon: {
          iconSrc: thumb_on,
          alt: 'thumb on',
        },
      },
    ],
    classNames: config.Toggle.classNames,
  } as ToggleProps;
};

const useListProps = (props: ListingSocialWithEmblaProps) => {
    return {
      Subtitle: {
        text: props.detailsHeader
      },
      items: props.details.map((item: {[key: string]: string}) => {
        return {
          Label: {
            text: item.key
          },
          Content: {
            text: item.value
          }
        }
      })
    };
}
const shareButtonProps = (props: ListingSocialWithEmblaProps) => {
  return {
    onClick: props.handleShareClick,
    e2e: 'sbp-share-button',
    Label: {
      text: props.shareButtonText || 'Share'
    },
    classNames: {
      ...config.Button.classNames,
      tertiary: true
    }
  }
}
const contactButtonProps = (props: ListingSocialWithEmblaProps) => {
  return {
    onClick: props.handleContactClick,
    e2e: 'sbp-request-info-button',
    Label: {
      text: props.contactButtonText || 'Contact'
    },
    classNames: {
      ...config.Button.classNames,
    }
  }
}

export default React.memo(ListingSocialWithEmbla);
