export const getCurrencySymbol = (currency: string): string => {
  const mapper: { [key: string]: string } = {
    "USD": "$",
    "EUR": "€",
    "GBP": "£",
    "DKK": "Dkr ",
    "SEK": "SKr ",
    "NOK": "Nkr ",
    "AUD": "A$",
  }

  const key = currency.toUpperCase();
  return mapper[key] || currency;
};

export const convertStringToNumber = (string: string) => {
  return Number(string.replace(/[^0-9.]+/g, ''));
};

export const convertToPrice = (value: string, currencyPrefix?: boolean, currency = 'USD', locale = 'en-US', ) => {
  const number = convertStringToNumber(value);
  const prefix = currencyPrefix ? getCurrencySymbol(currency) : '';

  const formattedResult = new Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits: 0
  }).format(number)

  return `${prefix}${formattedResult}`;
};