import React, { useRef} from 'react';
import ImgProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import { useSafeImageLoading } from '../../../hooks/useSafeLoadingImage';

function Image(props: ImgProps) {
  const imageRef: null | React.RefObject<HTMLImageElement> = useRef(null);
  const {
    imageSrc,
    imageSrcset,
    imageClassNames,
    setErrorLoadingImage,
    setSuccessLoadingImage
  } = useSafeImageLoading(props.src, props.srcset, props.defaultImage, props.hiddenOnError, props.classNames, imageRef);
  const hasLogo = props.Logo?.src;

  let loadingProps = {};
  
  if (props.lazyLoading) {
    loadingProps = {
      loading: 'lazy',
    }
  } else if (props.loading) {
    loadingProps = {
      loading: props.loading,
    }
  }


  // The value 0.8 indicates the aspect ratio of the image, <1 is portrait, 1 is square
  // and > 1 landscape. In our case, we consider that it is portrait for values less or equal 0.8,
  // images with a higher aspect ratio are seen correctly.

  const isPortrait = (width: number, height: number) => width / height <= 0.8 ? 'portrait' : '';

  const portraitClass = props?.dimensions?.width && props?.dimensions?.height ? isPortrait(props.dimensions.width, props.dimensions.height) : ''; 

  return (
    <>
      <span className={portraitClass ? 
        classNames('image-portrait', style, imageClassNames) : 
        classNames('image', style, imageClassNames)
      } 
      data-e2e={portraitClass ? 'image-portrait' : 'imageWrapper'}
      {...props.dataSelector}
      >
        {hasLogo &&
          <>
            <div className={classNames('image-gradient', style, imageClassNames)} />
            <img
              className={classNames('image-logo', style, imageClassNames)}
              src={props.Logo?.src}
              alt={props.Logo?.alt}
            />
          </>
        }
        {/* 
          loadingProps should be the first attribute for an image because if this
          attribute goes after "src" attribute the lazy loading doesn't work all 
          the times, so please keep it as the first attribute to ensure the lazy loading works
        */}
        <img
          {...loadingProps}
          className={hasLogo ? 
            classNames('mask-image', style, imageClassNames) :
            portraitClass ? classNames(portraitClass, style) : '' }
          ref={imageRef}
          src={imageSrc}
          srcSet={imageSrcset}
          sizes={props.sizes}
          alt={props.alt || '404'}
          fetchpriority={props.fetchPriority ? 'high' : undefined}
          width={props.width}
          height={props.height}
          onLoad={props.onLoad || setSuccessLoadingImage}
          onError={props.onError || setErrorLoadingImage}
          {...props.optsAttributes}
        />
      </span>
    </>
  );
}

export default React.memo(Image);