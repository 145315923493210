import React from "react";
import PaginationRowProps from "./props";
import style from "./style.module.scss";
import classNames from "../../../utils/classNames";

function PaginationRow(props: PaginationRowProps) {
  const clamp = (value: number, min: number, max: number) => {
      return Math.min(Math.max(value, min), max);
  }

  const { pageStart, pageEnd, onPageClick, getUrl } = props;
  const currentPage = clamp(props.currentPage, pageStart, pageEnd);

  const onClick = (page: number, pageLabel: string) => (event: React.MouseEvent<HTMLElement>) => {
    if (page === currentPage) {
      return event.preventDefault();
    }
    if (onPageClick) {
      onPageClick(page, pageLabel);
    }
  };

  const getPage = (index: number, value: string) => {
    const active = currentPage == index;
    return (
      <li key={value}>
        <a
          href={getUrl(index)}
            className={classNames("paginationItem", style, {"active": active, ...props.classNames})}
          onClick={onClick(index, `page ${index}`)}
        >
         {value} 
        </a>
      </li>
    )
  }
  const renderPages = () => {
    const pages = [];
    
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      const firstPage = getPage(prevPage, '<');
      pages.push(firstPage);
    }

    for (let i = pageStart; i <= pageEnd; i++) {
      const page = getPage(i, i.toString());
      pages.push(page);
    }

    if (currentPage < pageEnd) {
      const nextPage = currentPage + 1;
      const firstPage = getPage(nextPage, '>');
      pages.push(firstPage);
    }
    return pages;
  };

  return (
    <ul className={classNames("paginationRow", style, props.classNames)}>{renderPages()}</ul>
  );
}

export default React.memo(PaginationRow);