import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import listingSocial from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import ToggleProps from '../../private/Toggle/props';
import Toggle from '../../private/Toggle';
import list from '../../private/List/props';
import List from '../../private/List';
import FooterProps from '../../private/Footer/props';
import Footer from '../../private/Footer';
import Divider from '../../private/Divider';

import CarouselProps from '../../private/Carousel/props';
import ToolbarProps from '../../private/Toolbar/props';
import LabelProps from '../../private/Label/props';
import IconProps from '../../private/Icon/props';
import ImageProps from '../../private/Image/props';
import title from '../../private/Title/props';
import SubtitleProps from '../../private/Subtitle/props';
import ContentProps from '../../private/Content/props';
import button from '../../private/Button/props';

import ListingPreviewProps from '../../private/ListingPreview/props';
import ListingPreview from '../../private/ListingPreview';

import listingHeader from '../../private/ListingPreview/components/ListingCaption/components/ListingHeader/props';

import thumb_off from '../../../icons/thumb_off.svg';
import thumb_on from '../../../icons/thumb_on.svg';

function ListingSocial(props: listingSocial) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    props.onClick(event);
  }, [props]);

  return (
    <span
      className={classNames('listingSocial', style)}
      onClick={onClick}
      style={{ height: config.style.height }}
      data-testid="listingSocial"
    >
      <Toggle {...useToggleProps(props)}></Toggle>
      <ListingPreview {...useListingPreviewProps(props)}></ListingPreview>
      <Divider></Divider>
      <List {...useListProps(props)}></List>
      <Divider></Divider>
      <Footer {...useFooterProps(props)}></Footer>
    </span>
  );
}

const useToggleProps = (props: listingSocial): ToggleProps => {
  return useMemo(() => {
    return {
      onClick: props.like?.onClick,
      state: props.like?.state,
      states: [{
        state: props.like?.off,
        Icon: {
          svg: thumb_off,
          alt: props.like?.off
        } as IconProps
      }, {
        state: props.like?.on,
        Icon: {
          svg: thumb_on,
          alt: props.like?.on
        } as IconProps
      }],
      classNames: config.Toggle.classNames
    } as ToggleProps;
  }, [props]);
}

const useListingPreviewProps = (props: listingSocial): ListingPreviewProps => {
  return useMemo(() => {
    return {
      Carousel: {
        LeftButton: {
          onClick: props.carousel?.prevClick
        } as button,
        RightButton: {
          onClick: props.carousel?.nextClick
        } as button,
        items: props.carousel?.images.map((item: {[key: string]: string}) => {
          return {
            Image: {
              src: item.image,
              alt: item.imageAlt,
              height: config.ListingPreview.Image.height
            } as ImageProps
          };
        }),
        classNames: config.ListingPreview.Carousel.classNames
      } as CarouselProps,
      ListingCaption: {
        ListingHeader: {
          Title: {
            text: props.name,
            'data-e2e': 'listingName'
          } as title,
          Subtitle: {
            text: props.price,
            'data-e2e': 'listingPrice'
          } as SubtitleProps,
          Content: {
            text: props.location,
            'data-e2e': 'listingSellerContent'
          } as ContentProps
        } as listingHeader
      }
    };
  }, [props]);
}

const useListProps = (props: listingSocial): list => {
  return useMemo(() => {
    return {
      Subtitle: {
        text: props.details?.header
      } as SubtitleProps,
      items: (props.details?.items || []).map((item: {[key: string]: string}) => {
        return {
          Label: {
            text: item.key
          } as LabelProps,
          Content: {
            text: item.value
          } as ContentProps
        }
      })
    };
  }, [props]);
}

const useFooterProps = (props: listingSocial): FooterProps => {
  return useMemo(() => {
    return {
      Toolbar: {
        items: [{
          Button: {
            onClick: props.share.onClick,
            Label: {
              text: props.share.text
            } as LabelProps,
            classNames: {
              ...config.Footer.Toolbar.Button.classNames,
              tertiary: true
            }
          } as button
        }, {
          Button: {
            onClick: props.contact.onClick,
            Label: {
              text: props.contact.text
            } as LabelProps,
            classNames: config.Footer.Toolbar.Button.classNames,
            e2e: 'listingContactButton'
          } as button
        }]
      } as ToolbarProps,
      classNames: config.Footer.classNames
    };
  }, [props]);
}

export default React.memo(ListingSocial, (prevProps: listingSocial, nextProps: listingSocial) => {
  return isEqual(prevProps.carousel?.images, nextProps.carousel?.images);
});
