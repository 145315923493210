import React from 'react';
import style from './style.module.scss';
import classNames from '../../../../../utils/classNames';

type EmblaCarouselDotsProps = {
  activeSlideIndex: number,
  childrenLength: number
};
const MAX_DOTS_DISPLAY = 5;
const MAX_DOTS_DISPLAY_INDEX = 4;

const EmblaCarouselDots: React.FC<EmblaCarouselDotsProps> = ({ activeSlideIndex, childrenLength }) => {
  const dots = new Array(Math.min(MAX_DOTS_DISPLAY, childrenLength)).fill(null);
  
  return (
    <div
      className={classNames('emblaCarouselDots', style)}
      data-e2e='embla-carousel-dots'
    >
      {dots.map((_dot, index) => {
        const dotIndex =
          activeSlideIndex < MAX_DOTS_DISPLAY_INDEX ? index :
          activeSlideIndex === childrenLength - 1 ? index + (activeSlideIndex - 4) : 
          index + (activeSlideIndex - 3);
        return (
          <span
            key={dotIndex}
            aria-current={activeSlideIndex === dotIndex}
          ></span>
        );
      })}
    </div>
  );
}

export default EmblaCarouselDots;