import React from 'react';
import ImageWrapperProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Image from '../../private/Image';

function ImageWrapper(props: ImageWrapperProps) {
  return (
    <div className={classNames('imageWrapper', style)}>
      <Image {...props} />
    </div>
  );
}

export default React.memo(ImageWrapper);