import React from 'react';
import SubtitleProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function Subtitle(props: SubtitleProps) {
  return (
    <h4 className={classNames('subtitle', style, props.classNames)}
      { ...(props['data-e2e'] && { 'data-e2e': props['data-e2e'] }) }
    >
      {props.text}
    </h4>
  );
}

export default React.memo(Subtitle);