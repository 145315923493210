import React from 'react';
import brandHeaderImage from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import hero from '../Hero/props';
import Hero from '../Hero/Hero';
import ImageProps from '../../private/Image/props';
import Image from '../../private/Image/Image';
import LinkProps from '../../private/Link/props';
import Link from '../../private/Link';
import arrow_forward from '../../../icons/arrow_forward.svg';
import config from './config.json';

function BrandHeaderImage(props: brandHeaderImage) {
  return (
    <div className={classNames('brandHeaderImage', style)}>
      <Hero {...heroProps(props)}></Hero>
      {
        props.showViewModels && <div className={classNames('viewModelsButton', style)}>
          <Link {...linkProps(props)}></Link>
        </div>
      }
      <div>
        <Image {...imageProps(props)}></Image>
      </div>
    </div>
  );
}

const heroProps = (props: brandHeaderImage): hero => {
  return {
    img: props.img,
    height: config.Hero.height,
    hiddenOnError: true,
  };
}

const imageProps = (props: brandHeaderImage): ImageProps => {
  return {
    src: props.logo,
    alt: props.alt,
    width: config.Image.width,
    height: config.Image.height,
    hiddenOnError: true,
  };
}

const linkProps = (props: brandHeaderImage): LinkProps => {
  return {
    text: props.viewModelsText || 'View Models',
    Icon: {
      svg: arrow_forward,
      alt: props.viewModelsText || 'View Models'
    },
    classNames: { button: true, linkWithIconAndBC: true },
    url: props.url,
  };
}

export default React.memo(BrandHeaderImage);