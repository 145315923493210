import React, { useMemo } from 'react';
import style from "./style.module.scss";
import config from './config.json';
import { ListingConfig, ListingContactProps } from "../../../shared/SSRHelpers/SSRParsableElements";
import {ListingStandard} from "../../index";

function ListingSponsored(props: ListingContactProps) {
  const theConfig = useModifiedConfig(props, config as unknown as ListingConfig);
  return (<ListingStandard {...props}
    moduleStyle={style}
    classKey='listingSponsored'
    listingConfig={theConfig}
  />);
}

const useModifiedConfig = (props: ListingContactProps, config: ListingConfig): ListingConfig => {
  return useMemo(() => {
    if (!props?.contact?.text) {
      return modifyConfig(config);
    }
    return config;
  }, [props, config]);
}

const modifyConfig = (config: ListingConfig): ListingConfig => {
  const newConfig = {
    ...config,
    Footer: {
      ...config.Footer
    }
  };

  delete newConfig.Footer.Toolbar;
  return newConfig;
}

export default React.memo(ListingSponsored);
