import React, {useMemo} from 'react';
import footerSocialConnect from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../../../utils/classNames';
import config from './config.json';

import Title from '../../../../../../private/Title';
import title from '../../../../../../private/Title/props';

import Link from '../../../../../../private/Link';
import link from '../../../../../../private/Link/props';


import youtube from '../../../../../../../icons/youtube.svg';
import instagram from '../../../../../../../icons/instagram.svg';
import facebook from '../../../../../../../icons/facebook.svg';
import twitter from '../../../../../../../icons/twitter.svg';

const icons = {
  youtube,
  instagram,
  facebook,
  twitter
}

function FooterSocialConnect(props: footerSocialConnect) {
  return (
    <div className={classNames('footerSocialConnect', style)}>
      <Title {...useTitleProps(props)}></Title>
      <div>
        {props.items.map((_item, index: number) => (
          <Link key={index} {...linkProps(props, index)} />
        ))}
      </div>

    </div>
  );
}

const useTitleProps = (props: footerSocialConnect): title => {
  return useMemo(() => {
    return {
      text: props.title,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const linkProps = (props: footerSocialConnect, index: number): link => {
  return {
    url: props.items[index].url,
    text: '',
    classNames: config.Link.classNames,
    Icon: {
      svg: (props.items[index].icon && icons[props.items[index].icon]),
      alt: props.items[index].icon,
      classNames: config.Icon.classNames
    },
  }
};

export default React.memo(FooterSocialConnect);