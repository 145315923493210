import React from "react";
import introSectionContentProps from "./props";
import style from "./style.module.scss";
import classNames from "../../../../../utils/classNames";
import Heading from "../../../../private/Heading";
import heading from "../../../../private/Heading/props";
import Link from "../../../../private/Link";
import link from "../../../../private/Link/props";
import config from "../../config.json";
import Title from "../../../../private/Title";
import title from "../../../../private/Title/props";
import Button from "../../../../private/Button";
import button from "../../../../private/Button/props";

function IntroSectionContent(props: introSectionContentProps) {
  return (
    <div className={classNames("introSectionContent", style)}>
      <Heading {...headingProps(props)} />
      <Title {...titleProps(props)} />
      <div>
        {props.children}
        <Link {...linkProps(props)}></Link>
        <Button {...buttonProps(props)}></Button>
      </div>
    </div>
  );
}

const headingProps = (props: introSectionContentProps): heading => {
  return {
    ...config.Heading,
    text: props.brand,
  };
};

const titleProps = (props: introSectionContentProps): title => {
  return {
    text: props.title,
    classNames: {
      ...(props.classNames?.untruncate && {
        untruncate: props.classNames?.untruncate,
      }),
    },
  };
};

const linkProps = (props: introSectionContentProps): link => {
  return {
    ...config.Link,
    url: props.linkUrl,
  };
};

const buttonProps = (props: introSectionContentProps): button => {
  const text = props.buttonText || config.Button.text;
  const onClick = props.buttonOnClick ? { onClick: props.buttonOnClick } : {};
  return { Label: { text }, ...onClick };
};

export default React.memo(IntroSectionContent);
