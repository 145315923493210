import React, {useState, useEffect, useCallback} from 'react';
import ModalWrapperProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import Modal from '../../private/Modal/Modal';

function ModalWrapper(props: ModalWrapperProps) {
  const {
    children,
    customClassName = '',
    onClose,
    show
  } = props;

  const [visible, setVisible] = useState(show);

  useEffect(() => {
    setVisible(show);
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  const handleClose = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      setVisible(false);
      onClose?.(ev);
    },
    [onClose]
  );

  const createModalProps = () => {
    const { Modal: modalConfig, Blocker: blockerConfig} = config;
    const blocker = { ...blockerConfig, onClick: handleClose };
    return {
      ...modalConfig,
      Blocker: blocker,
      hideCloseButton: true,
    };
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={`${classNames('modalWrapper', style, props.classNames)} ${customClassName}`} data-e2e='modalWrapper'>
      <Modal {...createModalProps()}>
        {children}
      </Modal>
    </div>
  );
}

export default ModalWrapper;
