import React from 'react';
import inputGroup from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Input from '../Input';
import input from '../Input/props';

function InputGroup(props: inputGroup) {
  return (
    <div className={classNames('inputGroup', style, props.classNames)}>
       {props.items.map((item: { [key: string]: input }, index: number) => (
          <Input key={String(item.Input.id || index)} {...inputProps(item.Input as input, props)} />
        ))}
    </div>
  );
}
const inputProps = (item: input, props: inputGroup): input => {
  return {
    ...item as input,
    classNames: {
      ...item.classNames,
      ...props.classNames?.horizontal && { horizontal: true }
    }
  };
}

export default React.memo(InputGroup);