import React, {useMemo} from 'react';
import linkListModal from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Modal from '../../private/Modal/Modal';
import ModalProps from '../../private/Modal/props';
import Title from '../../private/Title/Title';
import title from '../../private/Title/props';
import Link from '../../private/Link/Link';
import link from '../../private/Link/props';
import Content from '../../private/Content/Content';
import ContentProps from '../../private/Content/props';
import Subtitle from '../../private/Subtitle/Subtitle';
import SubtitleProps from '../../private/Subtitle/props';
import list from '../../private/List/props';
import List from '../../private/List/List';

function LinkListModal(props: linkListModal) {
  return (
    <div className={classNames('linkListModal', style)}>
      <Modal {...useModalProps(props)}>
        <Title {...useTitleProps(props)}></Title>
        <Link {...useLinkProps(props)}></Link>
        <Content {...useContentProps(props)}></Content>
        <Subtitle {...useSubtitleProps(props)}></Subtitle>
        <List {...useListProps(props)}></List>
      </Modal>
    </div>
  );
}

const useModalProps = (props: linkListModal): ModalProps => {
  return useMemo(() => {
    return {
      classNames: config.Modal.classNames,
      Button: {
        onClick: props.onClose,
        Icon: {
          classNames: config.Modal.Button.Icon.classNames
        }
      },
      Blocker: {
        classNames: {
          scroll: true
        }
      }
    };
  }, [props]);
};

const useTitleProps = (props: linkListModal): title => {
  return useMemo(() => {
    return {
      text: props.title,
      classNames: config.Title.classNames
    };
  }, [props]);
};

const useSubtitleProps = (props: linkListModal): SubtitleProps => {
  return useMemo(() => {
    return {
      text: props.subtitle,
      classNames: config.Subtitle.classNames
    };
  }, [props]);
};

const useContentProps = (props: linkListModal): ContentProps => {
  return useMemo(() => {
    return {
      text: props.content,
      classNames: config.Content.classNames
    };
  }, [props]);
};

const useLinkProps = (props: linkListModal): link => {
  return useMemo(() => {
    return {
      text: props.link.text,
      url: props.link.url,
      title: props.link.title,
      classNames: config.Link.classNames
    };
  }, [props]);
};

const useListProps = (props: linkListModal): list => {
  return useMemo(() => {
    return {
      items: props.list.map((item) => {
        return {
          Link: {
            text: item.text,
            url: item.url,
            classNames: config.List.Link.classNames
          } as link
        }
      }),
      classNames: config.List.classNames
    };
  }, [props]);
};

export default React.memo(LinkListModal);