import React, { useEffect } from 'react';
import SubjectDropdownProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import chevron_up from '../../../icons/chevron-up2.svg';
import chevron_down from '../../../icons/chevron-down2.svg';
import Button from '../../private/Button';

function SubjectDropdown(props: SubjectDropdownProps) {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = React.useState(props.defaultText);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOptionSelect = (index: number, option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    props.onChangeSubject?.(index, option);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current) {
        return;
      }
      if (!dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const dropdownButton = () => {
    return {
      onClick: handleDropdownClick,
      classNames: {
        icon: true,
      },
      Icon: {
        iconSrc: isOpen ? chevron_up : chevron_down,
        alt: isOpen ? 'close dropdown' : 'open dropdown',
      },
      Label: undefined,
      e2e: 'dropdownButton',
    };
  };

  return (
    <div
      className={classNames('subject-dropdown', style)}
      data-testing='subject-dropdown'
      ref={dropdownRef}
    >
      <div
        className={classNames('selected-subject-container', style)}
        onClick={() => setIsOpen(!isOpen)}
        role='button'
        aria-expanded={isOpen}
      >
        <label
          className={classNames('selected-subject-label', style)}
          htmlFor='subject'
        >
          {props.label}
        </label>
        <input
          id='subject'
          name='subject'
          className={classNames('selected-subject-input', style)}
          value={selectedOption}
          readOnly={true}
          aria-label={selectedOption}
          data-testing='selected-subject-input'
        />
        <Button {...dropdownButton()} />
      </div>
      {isOpen && (
        <div className={classNames('options', style)}>
          {props.options.map((option, i: number) => (
            <div
              className={classNames('option', style)}
              key={option}
              data-testing={`option-${i}`}
              onClick={() => handleOptionSelect(i, option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(SubjectDropdown);
