import React, { useCallback } from 'react';
import button from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import LabelProps from '../Label/props';
import Label from '../Label';
import IconProps from '../Icon/props';
import Icon from '../Icon';

function Button(props: button, ref: React.ForwardedRef<HTMLButtonElement>) {
  const onClick: (event: React.MouseEvent<HTMLButtonElement>) => void = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();

    if (props.preventDefault){
      event?.preventDefault();
    }

    if (props.onClick) {
      props.onClick(event);
    }
  }, [props]);
  

  return (
    <button
      className={classNames('button', style, props.classNames)}
      onClick={onClick}
      aria-label={props.Label?.text || props.Icon?.alt}
      type={props.type || 'button'}
      data-e2e={props.e2e}
      data-role={props.role}
      disabled={props.disabled}
      aria-expanded={props.isOpen ? 'true' : undefined}
      style={{height: (config.height.classNames as any)[Object.keys(props.classNames || {}).filter(key => (props.classNames as {[key: string]: boolean})[key])[0] || 'primary']}}
      ref={ref}
      {...props.buttonAttributes}
    >
      {props.Icon && <Icon {...iconProps(props)}></Icon>}
      {props.Label && <Label {...labelProps(props)}></Label>}
    </button>
  );
}

const iconProps = (props: button): IconProps => {
  return {
    ...props.Icon as IconProps,
    classNames: props.Icon?.classNames || (config.Icon.classNames as {[key: string]: { [key: string]: boolean }})[Object.keys(props.classNames || {}).filter(key => (props.classNames as {[key: string]: boolean})[key])[0] || 'primary']
  };
}

const labelProps = (props: button): LabelProps => {
  return {
    ...props.Label as LabelProps,
    classNames: (config.Label.classNames as {[key: string]: { [key: string]: boolean }})[Object.keys(props.classNames || {}).filter(key => (props.classNames as {[key: string]: boolean})[key])[0] || 'primary']
  };
}

export default React.memo(React.forwardRef(Button));
