import React from "react";
import DetailsProps from "./props";
import style from "./style.module.scss";
import classNames from "../../../utils/classNames";

function Details({ children, heading, isOpen = false, onClick, ...rest }: DetailsProps) {
  return (
    <details
      className={classNames("details-wrapper", style)}
      open={isOpen}
      {...rest}
    >
      <summary className={classNames("details-header", style)}
        onClick={onClick}
      >
        {heading}
      </summary>
      {children}
    </details>
  );
}

export default React.memo(Details);
