import React, { useCallback, useMemo } from 'react';
import ListingCarouselCardProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import ListingPreviewProps from '../../private/ListingPreview/props';
import ListingPreview from '../../private/ListingPreview';
import LinkContainer from '../../private/LinkContainer';
import {
  makeLinkContainerProps,
  makeListingPreviewImageProps, makeListingPreviewSeller,
  makeListingPreviewTitle,
  makeListingPriceSubtitleTitle
} from "../../../shared/propsBuilders/listingPropsBuilder";


function ListingCarouselCard(props: ListingCarouselCardProps) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    props.onClick(event);
  }, [props]);

  return (
    <LinkContainer {...makeLinkContainerProps(props)}>
      <span
        className={classNames('listingCarouselCard', style)}
        onClick={onClick}
        data-e2e='listingCarouselCard'
      >
        <ListingPreview {...useListingPreviewProps(props)}></ListingPreview>
      </span>
    </LinkContainer>
  );
}

const useListingPreviewProps = (props: ListingCarouselCardProps): ListingPreviewProps => {
  return useMemo(() => {
    const Image = makeListingPreviewImageProps(props, config.ListingPreview.Image.height);
    const ListingTitle = makeListingPreviewTitle(props.name);
    const Subtitle = makeListingPriceSubtitleTitle(props.price);
    const ListingBody = makeListingPreviewSeller(props.sellerMarketingMessage);
    return {
      Image,
      ListingCaption: {
        ListingPrice: {
          ListingTitle,
          Subtitle,
        },
        ListingBody
      }
    };
  }, [props]);
}

export default React.memo(ListingCarouselCard);
