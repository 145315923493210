import React, {useState} from 'react';
import tabs from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import List from '../List/List';
import list from '../List/props';
import TabContent from './components/TabContent';
import tabContent from './components/TabContent/props';



function Tabs(props: tabs) {
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (_: React.MouseEvent<HTMLElement>, index: number) => {
    setActiveTab(index);
  }

  return (
    <div className={classNames('tabs', style)} data-e2e={props.e2e}>    
      <List {...listProps(props, handleActiveTab, activeTab)} />
      {props.items.map((item: any, index: number) => (
        <div key={index} style={{ display: activeTab === index ? 'block' : 'none' }}>
          {item.TabContent && <TabContent {...item.TabContent as tabContent} />}
        </div>
      ))}
    </div>
  );
}
const listProps = (props: tabs, handleActiveTab: (_: React.MouseEvent<HTMLElement>, index: number) => void, activeTab: number): list => {
  return {
    ...props.List as list,
    onClick: handleActiveTab,
    activeItem: activeTab,
    classNames: {
      "grid": true
    }
  };
}


export default React.memo(Tabs);