type ListenerFn = () => any

export const getScrollLeft = (target?: HTMLElement | null) => {
  return target ? target.scrollLeft : 0;
};

export const addScrollListener = (listener: ListenerFn, target: HTMLElement | null | Document = document) => {
  return target?.addEventListener('scroll', listener)
}

export const removeScrollListener = (
  listener: ListenerFn,
  target: HTMLElement | null | Document = document
) => {
  return target?.removeEventListener('scroll', listener)
}