import React from 'react';
import MaintenanceMesageProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';


function MaintenanceMesage(props: MaintenanceMesageProps) {
  const message: string = props.message;
  const submessage: string = props.submessage;


  return (
    <div className={classNames('maintenanceMesage', style)}>
        <div data-type="maintenance-logo-container">
            <svg className="logo" width="120" height="120" viewBox="0 0 101 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M98.95 84.6149L75.35 61.0149C72.95 58.6149 70.4 58.0649 67.45 58.0649L54.75 70.7649C54.75 73.7149 55.3 76.3149 57.7 78.6649L81.3 102.265C83.25 104.215 86.4 104.215 88.35 102.265L98.95 91.6649C100.9 89.7649 100.9 86.5649 98.95 84.6149Z" fill="white"/>
                <path d="M73.75 41.2149C75.7 43.1649 78.85 43.1649 80.8 41.2149L84.35 37.6649L94.95 48.2649C100.8 42.4149 100.8 32.9149 94.95 27.0649L80.8 12.9149C78.85 10.9649 75.7 10.9649 73.75 12.9149L70.2 16.4649V3.76486C70.2 0.664863 66.4 -0.985137 64.15 1.26486L51.45 13.9649C49.2 16.2149 50.85 20.0149 53.95 20.0149H66.65L63.1 23.5649C61.15 25.5149 61.15 28.6649 63.1 30.6149L64.85 32.3649L50.4 46.8149L29.85 26.1649V21.1649C29.85 19.8149 29.3 18.5649 28.4 17.6149L18.3 7.46486C16.35 5.51486 13.2 5.51486 11.25 7.46486L4.14999 14.5649C2.19999 16.5149 2.19999 19.6649 4.14999 21.6149L14.25 31.7649C15.2 32.7149 16.45 33.2149 17.8 33.2149H22.8L43.45 53.8649L39.2 58.1149H32.7C30.05 58.1149 27.5 59.1649 25.65 61.0649L2.04999 84.6649C0.0999939 86.6149 0.0999939 89.7649 2.04999 91.7149L12.65 102.315C14.6 104.265 17.75 104.265 19.7 102.315L43.3 78.7149C45.2 76.8149 46.25 74.3149 46.25 71.6649V65.2149L72 39.4649L73.75 41.2149Z" fill="white"/>
            </svg>
        </div>
        <div data-type="maintenance-message">{ message }</div>
        <div data-type="maintenance-submessage">{ submessage }</div>
    </div>
  );
}

export default React.memo(MaintenanceMesage);