import React, { useMemo } from 'react';
import listingTooltip from './props';
import style from './style.module.scss';
import classNames from '../../../../../../../../../utils/classNames';
import config from './config.json';

import SubtitleProps from '../../../../../../../Subtitle/props';
import Subtitle from '../../../../../../../Subtitle';

import tooltip from '../../../../../../../Tooltip/props';
import Tooltip from '../../../../../../../Tooltip';
import ContentProps from '../../../../../../../Content/props';
import Content from '../../../../../../../Content';

function ListingTooltip(props: listingTooltip) {
  const subTitleProps = useSubtitleProps(props);
  const contentProps = useContentProps(props);
  return (
    <span className={classNames('listingTooltip', style)}>
      {props.Subtitle && <Subtitle {...subTitleProps}></Subtitle>}
      {props.Content && <Content {...contentProps}></Content>}
      <Tooltip {...props.Tooltip as tooltip}></Tooltip>
    </span>
  );
}

const useSubtitleProps = (props: listingTooltip): SubtitleProps => {
  return useMemo(() => {
    return {
      ...props.Subtitle as SubtitleProps,
      classNames: config.Subtitle.classNames
    };
  }, [props]);
}

const useContentProps = (props: listingTooltip): ContentProps => {
  return useMemo(() => {
    return {
      ...props.Content as ContentProps,
      classNames: config.Content.classNames
    };
  }, [props]);
}

export default React.memo(ListingTooltip);