import React from 'react';
import SocialIconsFeedProps, { SocialIcon } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Button from '../Button';
import button from '../Button/props';
import config from './config.json';

import facebook from '../../../icons/facebook-2.svg';
import twitter from '../../../icons/twitter-2.svg';
import pinterest from '../../../icons/pinterest.svg';
import whatsapp from '../../../icons/whatsapp.svg';
import email from '../../../icons/email.svg';
import print from '../../../icons/print.svg';
import more from '../../../icons/more.svg';
import x from '../../../icons/x.svg';

const icons: { [key: string]: string } = {
  facebook,
  twitter,
  pinterest,
  whatsapp,
  email,
  print,
  more,
  x
}

function SocialIconsFeed(props: SocialIconsFeedProps) {
  return (
    <div className={classNames('socialIconsFeed', style)}>
      <div>
        { props.items.map((button: SocialIcon, index: number) => <Button key={index} {...buttonProps(button)} />) }
      </div>
    </div>
  );
}

const buttonProps = (socialIcon: SocialIcon): button => ({
  classNames: config.classNames,
  Icon: {
    svg: icons[socialIcon.icon],
    alt: socialIcon.alt || socialIcon.label,
    classNames: config.Icon.classNames
  },
  Label: {
    text: socialIcon.label
  },
  onClick: (e) => socialIcon.onClick && socialIcon?.onClick(e, socialIcon.icon),
});

export default React.memo(SocialIconsFeed);
