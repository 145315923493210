import React from 'react';
import ToolbarProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import button from '../Button/props';
import Button from '../Button';
import TagProps from '../Tag/props';
import Tag from '../Tag';
import divider from '../Divider/props';
import Divider from'../Divider';
import IconProps from '../Icon/props';
import Icon from '../Icon';
import link from '../Link/props';
import Link from'../Link';
import LabelProps from '../Label/props';
import Label from '../Label';

function Toolbar(props: ToolbarProps) {
  return (
    <ul
      className={classNames('toolbar', style, props.classNames)}
      vocab={props.schema?.vocab}
      typeof={props.schema?.typeOf}
      {...props.dataSelector}
    >
      {props.items.map((item: { [key: string]: button | TagProps | divider | IconProps | link | LabelProps }, index: number) => {
        const isLastItem = index === props.items.length - 1;

        return (
          <li key={index} property={props.schema?.propertyItem} typeof={props.schema?.typeItem}>
            {item.Button && <Button {...item.Button as button}></Button>}
            {item.Tag && <Tag {...item.Tag as TagProps}></Tag>}
            {item.Divider && <Divider {...item.Divider as divider}></Divider>}
            {item.Icon && <Icon {...item.Icon as IconProps}></Icon>}
            {item.Label && <Label {...item.Label as LabelProps}></Label>}
            {props.classNames?.navbar && item.Link && (
              <>
                <Link {...linkProps(props, index)}></Link>
                {props.schema?.vocab && <meta property="position" content={(index + 1).toString()} />}
                {!isLastItem && <Divider {...dividerProps()}></Divider>}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
}

const linkProps = (props: ToolbarProps, index: number): link => {
  return {
    ...props.items[index].Link as link,
    classNames: config.Link.classNames
  };
}

const dividerProps = (): divider => {
  return {
    classNames: config.Divider.classNames
  };
}

export default React.memo(Toolbar);
