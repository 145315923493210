import React, { useMemo } from 'react';
import FooterProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import ImageProps from '../Image/props';
import Image from '../Image';
import ToolbarProps from '../Toolbar/props';
import Toolbar from '../Toolbar';

function Footer(props: FooterProps) {
  return (
    <span
      className={classNames('footer', style, props.classNames)}
      style={{ minHeight: (config.style.classNames as { [key: string]: { [key: string]: number } })[Object.keys(props.classNames || {}).filter(key => (props.classNames as { [key: string]: boolean })[key])[0]]?.height || config.style.height }}
      {...props.dataSelector}
    >
      {props.Image && <Image {...imageProps(props)}></Image>}
      <Toolbar {...useToolbarProps(props)}></Toolbar>
    </span>
  );
}

const imageProps = (props: FooterProps): ImageProps => {
  return {
    ...props.Image as ImageProps,
    width: config.Image.width,
    classNames: config.Image.classNames
  };
}

const useToolbarProps = (props: FooterProps): ToolbarProps => {
  return useMemo(() => {
    return {
      ...props.Toolbar as ToolbarProps,
      items: (props.Toolbar?.items || []).map((item) => {
        return {
          ...item,
          ...(!item.Button ? {} : {
            Button: {
              ...item.Button,
              classNames: {
                ...item.Button.classNames,
                ...props.classNames
              }
            }
          })
        }
      })
    };
  }, [props]);
}

export default React.memo(Footer);