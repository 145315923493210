import React from 'react';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import IntroSectionContent from './components/IntroSectionContent/IntroSectionContent';
import IntroSectionContentProps from './components/IntroSectionContent/props';
import Image from '../../private/Image';
import ImageProps from '../../private/Image/props';
import IntroSectionProps from "./props";

function IntroSection(props: IntroSectionProps) {
  const { children } = props;
  return (
    <div className={classNames('introSection', style)}>
      <IntroSectionContent {...introSectionContentProps(props)}>
        {children}
      </IntroSectionContent>
      {props.mediaType === 'image' && props.imgSrc && (
        <div className='panel1'>
          <div className='panel2'>
            <Image {...imageProps(props)} />
          </div>
        </div>
      )}
    </div>
  );
}

function introSectionContentProps(
  props: IntroSectionProps
): IntroSectionContentProps {
  return {
    brand: props.brand,
    title: props.title,
    linkUrl: props.linkUrl,
    buttonText: props.buttonText,
    buttonOnClick: props.buttonOnClick,
    classNames: {
      ...(props.classNames?.untruncate && {
        untruncate: props.classNames?.untruncate,
      }),
    },
  };
}

function imageProps(props: IntroSectionProps): ImageProps {
  return {
    src: props.imgSrc as string,
    alt: props.imgAlt as string,
    hiddenOnError: true,
  };
}

export default React.memo(IntroSection);
