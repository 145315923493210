import React from 'react';
import fieldset from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Label from '../Label';
import LabelProps from '../Label/props';
import Collapsible from '../Collapsible';
import collapsible from '../Collapsible/props';

function Fieldset(props: fieldset) {
  return (
    <div className={classNames('fieldset', style)}>
      {props.Label && <Label {...labelProps(props)}></Label>}
      {props.Collapsible && <Collapsible {...collapsibleProps(props)}></Collapsible>}
    </div>
  );
}

const labelProps = (props: fieldset): LabelProps => {
  return {
    ...props.Label as LabelProps,
    classNames: config.Label.classNames
  }
}

const collapsibleProps = (props: fieldset): collapsible => {
  return {
    ...props.Collapsible as collapsible,
    classNames: config.Collapsible.classNames
  }
}

export default React.memo(Fieldset);