import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce';
import { getScrollLeft, addScrollListener, removeScrollListener } from '../utils/domUtils';

enum Direction {
  LEFT = 'left',
  RIGHT = 'right'
}

type ScrollDirection = Direction.LEFT | Direction.RIGHT | null

interface ScrollDirectionHookResult {
  scrollDirection: ScrollDirection
}

export const useScrollDirection = (target?: HTMLElement | null): ScrollDirectionHookResult => {

  const [targetToUse, setTargetFromProps] = useState<HTMLElement | null>()
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null)

  let scrollTimeout: number
  let lastScrollLeft = getScrollLeft(targetToUse)

  const handleScroll = debounce(() => {
    // Reset scroll direction 
    window.clearTimeout(scrollTimeout)
    scrollTimeout = window.setTimeout(() => {
      setScrollDirection(null);
    }, 65)

    // Set horizontal scroll direction
    const scrollLeft = getScrollLeft(targetToUse);
    if (scrollLeft > lastScrollLeft) {
      setScrollDirection(Direction.RIGHT);
    }

    if (scrollLeft < lastScrollLeft) {
      setScrollDirection(Direction.LEFT);
    }
    lastScrollLeft = scrollLeft
  }, 500)

  useEffect(() => {
    setTargetFromProps(target)
  }, [target])

  useEffect(() => {
    addScrollListener(handleScroll, targetToUse)
    return () => removeScrollListener(handleScroll, targetToUse)
  }, [targetToUse, handleScroll]);

  return {
    scrollDirection,
  };
};