import React, {useMemo} from 'react';
import isEqual from 'lodash/isEqual';
import ListingPreviewProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

import ListingCaptionProps from './components/ListingCaption/props';
import ListingCaption from './components/ListingCaption/ListingCaption';
import ImageProps from '../Image/props';
import Image from '../Image';
import CarouselProps from '../Carousel/props';
import Carousel from '../Carousel/Carousel';

function ListingPreview(props: ListingPreviewProps) {
  return (
    <span className={classNames('listingPreview', style, props.classNames)}>
      <ListingCaption {...useListingCaptionProps(props)}></ListingCaption>
      {props.Image && <Image {...props.Image as ImageProps}></Image>}
      {props.Carousel && <Carousel {...props.Carousel as CarouselProps}></Carousel>}
    </span>
  );
}

const useListingCaptionProps = (props: ListingPreviewProps): ListingCaptionProps => {
  return useMemo(() => {
    return {
      ...props.ListingCaption as ListingCaptionProps,
      classNames: {
        ...props.ListingCaption?.classNames,
        contrast: props.classNames?.contrast as boolean
      }
    };
  }, [props]);
}

export default React.memo(ListingPreview, (prevProps: ListingPreviewProps, nextProps: ListingPreviewProps) => {
  return isEqual(prevProps, nextProps);
});
