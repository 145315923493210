import React, { useState } from 'react';
import TypeaheadSearchProps from "./props";
import './styles.scss';
import clearIcon from '../../../icons/clear_icon.svg';

function TypeaheadSearch(props: TypeaheadSearchProps) {
  const [search, setSearch] = useState(props.search || '');
  const [showSuggestions, setShowSuggestions] = useState(false);


  const filteredSuggestions = props.options.filter(option =>
    option.toLowerCase().includes(search.toLowerCase())
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearch(suggestion);
    setShowSuggestions(!showSuggestions);
    props.onSearchChange?.(suggestion);
  };
  
  const handleClear = () => {
    setSearch('');
    setShowSuggestions(false);
  };

  const getSuggestion = () => {
    if (filteredSuggestions.length === 0) {
      return <div key='no-results' className='typeahead-search__no-results'><p>{`"${search}" ${props.notFoundText}`}</p></div>;
    } else {
      return filteredSuggestions.map((suggestion, index) => ( 
        <div 
          className='typeahead-search__suggestion'
          key={index} 
          role="option"
          onClick={() => handleSuggestionClick(suggestion)}
          dangerouslySetInnerHTML={{
            __html: suggestion.replace(
              new RegExp(`(${search})`, 'gi'),
              '<strong>$1</strong>'
            ),
          }}
        />
      ));
    }
  };

  return (
    <div className='typeahead-search' aria-expanded={showSuggestions}>
      <div className='typeahead-search__input-container'>
        <input
          className='typeahead-search__input'
          type='text'
          value={search}
          onChange={handleOnChange}
          placeholder={props.placeholder}
          id='searchInput'
          aria-controls='typeahead-search-suggestions'
          aria-autocomplete='list'
        />
        <label className='typeahead-search__input-label' htmlFor='searchInput'>
          {props.placeholder}
        </label>
        {showSuggestions && (
          <button
            className='typeahead-search__clear-button'
            onClick={handleClear}
            aria-label='Clear search'
          >
            <img src={clearIcon} alt='clear search icon' />
          </button>
        )}
      </div>
      {showSuggestions && (
        <div
          className='typeahead-search__suggestions-dropdown'
          id='typeahead-search-suggestions'
          role='listbox'
        >
          {getSuggestion()}
        </div>
      )}
    </div>
  );
}

export default React.memo(TypeaheadSearch);