import React from 'react';

import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Title from '../../private/Title';
import title from '../../private/Title/props';
import Button from '../../private/Button';
import button from '../../private/Button/props';

import buildPrice from '../../../icons/build_price.svg';
import customService from '../../../icons/customer_service.svg';
import helpMeChoose from '../../../icons/help_me_choose.svg';
import learnMore from '../../../icons/learn_more.svg';
import maintenance from '../../../icons/maintenance.svg';
import news from '../../../icons/news.svg';
import ownersManuals from '../../../icons/owners_manuals.svg';
import performanceTests from '../../../icons/performance_tests.svg';
import promotions from '../../../icons/promotions.svg';
import upcomingEvents from '../../../icons/upcoming_events.svg';
import warranty from '../../../icons/warranty.svg';
import resources from '../../../icons/resources.svg';
import {ExternalLinkIcon, ExternalLinksProps} from "./props";

const icons: { [key: string]: string } = {
  buildPrice,
  customService,
  helpMeChoose,
  learnMore,
  maintenance,
  news,
  ownersManuals,
  performanceTests,
  promotions,
  upcomingEvents,
  warranty,
  resources
}

function ExternalLink(link: ExternalLinkIcon) {
  return (
    <li onClick={link.onClick} >
      <Button {...buttonProps(link)} />
    </li>
  );
}

function ExternalLinks(props: ExternalLinksProps) {
  const { title, links, showItemsMax} = props;
  const maxItems = showItemsMax ? showItemsMax : links.length;
  const maxLinks = links.slice(0, maxItems);
  const listItems = maxLinks.map(( link, index) => (<ExternalLink key={index} {...link} />));
  return (
    <div className={classNames('externalLinks', style)}>
      <Title {...titleProps(title)}></Title>
      <div>
      <ul className="responsive-list">
        {listItems}
        </ul>
      </div>
    </div>
  );
}

const titleProps = (title: string): title => {
  return {
    ...config.Title,
    text: title
  };
}

const buttonProps = (linkProps: ExternalLinkIcon): button => {
  const { onClick, icon, title } = linkProps;
  return {
    onClick: onClick,
    Icon: {
      svg: icons[icon],
      alt: `icon ${title.text}`,
      classNames: config.Icon.classNames
    },
    Label: title,
    classNames: {
      'x-large': true,
      iconWithCaption: true,
    },
  };
};

export default React.memo(ExternalLinks);
