import React, {useMemo} from 'react';
import footerSocialStores from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../../../utils/classNames';

import config from './config.json';

import Title from '../../../../../../private/Title';
import title from '../../../../../../private/Title/props';

import Link from '../../../../../../private/Link';
import link from '../../../../../../private/Link/props';


import app_store from '../../../../../../../icons/app_store.svg';
import google_play from '../../../../../../../icons/google_play.svg';


const icons = {
  app_store,
  google_play
}

function FooterSocialStores(props: footerSocialStores) {
  return (
    <div className={classNames('footerSocialStores', style)}>
      <Title {...useTitleProps(props)}></Title>
      <div>
        {props.stores.map((_store, index: number) => (
          <Link key={index} {...linkProps(props, index)} />
        ))}
      </div>

    </div>
  );
}

const useTitleProps = (props: footerSocialStores): title => {
  return useMemo(() => {
    return {
      text: props.titleStores,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const linkProps = (props: footerSocialStores, index: number): link => {
  return {
    url: props.stores[index].url,
    text: '',
    classNames: config.Link.classNames,
    Icon: {
      svg: (props.stores[index].icon && icons[props.stores[index].icon]),
      alt: props.stores[index].icon,
      classNames: config.Icon.classNames
    },
  }
};

export default React.memo(FooterSocialStores);