import React, { memo, useEffect, useState } from "react";
import { SwitcherProps } from "./props";
import style from "./style.module.scss";
import classNames from "../../../utils/classNames";

function Switcher(props: SwitcherProps) {
  const { defaultIndex = 0, fullWidth = false, options, onSwitch } = props;
  const [currentIdx, setCurrentIdx] = useState(defaultIndex);

  useEffect(() => {
    setCurrentIdx(defaultIndex);
  }, [defaultIndex]);

  return (
    <div
      className={classNames("switcher-wrapper", style, {
        "full-width": fullWidth,
      })}
      role="radiogroup"
    >
      {options.map((opt, idx) => (
        <span key={opt.name} className={classNames("switcher-option", style)}>
          <span
            className={classNames("switcher-option-action", style, {
              active: currentIdx === idx,
            })}
          >
            <input
              checked={currentIdx === idx}
              className={classNames("switcher-option-input", style)}
              id={opt.name}
              onChange={(evt) => {
                setCurrentIdx(idx);
                onSwitch(evt, opt.value);
              }}
              name="switcher"
              type="radio"
              value={opt.value}
            />
          </span>
          <label
            className={classNames("switcher-option-label", style)}
            htmlFor={opt.name}
          >
            {opt.label}
          </label>
        </span>
      ))}
    </div>
  );
}

export default memo(Switcher);
