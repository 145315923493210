import React, {useRef} from 'react';
import EmailLeadFormProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Input from '../../private/Input';
import InputProps, {PhoneInput} from '../../private/Input/props';
import Textarea from '../../private/Textarea';
import TextareaProps from '../../private/Textarea/props';
import Content from '../../private/Content/Content';
import ContentProps from '../../private/Content/props';
import SellerName from '../SellerName';
import SellerNameProps from '../SellerName/props';
import ErrorMessage from '../../private/ErrorMessage';
import ErrorMessageProps from '../../private/ErrorMessage/props';
import SuccessMessage from '../SuccessMessage';
import SuccessMessageProps from '../SuccessMessage/props';
import Image from '../../private/Image';
import PhoneCountryDropdown from '../PhoneCountryDropdown';
import Action from '../Action';
import { ActionProps } from '../Action/props';
import SubjectDropdown from '../SubjectDropdown';

const CountryDropdown = (props: EmailLeadFormProps) => {
  const { countries = [], countrySelected, searchText, noResultsText } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const getWidth = () => dropdownRef.current?.clientWidth || '';
  const phoneProps = inputPhoneProps(props);
  const changedPrefix = (prefix: string) => {
    phoneProps.onChangePrefix?.(prefix);
  };
  return (
    <div className={classNames('phoneCountryWrapper', style)} data-e2e='phoneCountryWrapper' ref={dropdownRef}>
      <PhoneCountryDropdown
        countries={countries}
        countrySelected={countrySelected}
        getWidth={getWidth}
        searchText={searchText}
        noResultsText={noResultsText}
        onChangePrefix={changedPrefix}
      />
      <Input {...phoneProps} />
    </div>
  )
};

function EmailLeadForm(props: EmailLeadFormProps) {
  const { children } = props;
  const fromRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const obj: { [key: string]: FormDataEntryValue | null; } = {};

    formData.forEach((value, key) => {
      obj[key] = value;
    });

    props.onSubmit(e, obj);
  }

  const handleClick = (e: any) => {
    e.preventDefault();

    fromRef?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );

    if (props?.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div className={classNames('emailLeadForm', style, {...props.classNames})} data-e2e='emailLeadForm'>
      {props?.oemLeadLogo?.src ?
      <Image {...props.oemLeadLogo} />:
      <SellerName {...sellerNameProps(props)} />}
      {props?.invalidFields && props?.invalidFields?.length > 0 && <Content {...invalidMessageProps(props)} />}
      {props?.showSuccessMessage ? <SuccessMessage {...inputSuccessMessageProps(props)} /> :
        <form
          ref={fromRef}
          onSubmit={handleSubmit}
          className={classNames('mobileFont', style, {...props.classNames})}
        >
          <Input {...inputNameProps(props)} />
          <div className={classNames('emailPhoneWrapper', style)} data-e2e='emailPhoneWrapper'>
            <Input {...inputEmailProps(props)} />
            {
              props.classNames?.phoneCountry ?
              <CountryDropdown {...props} /> :
              <Input {...inputPhoneProps(props)} />
            }

          </div>
          {/* istanbul ignore next */ props?.zip?.id && <Input {...inputZipProps(props)} />}
          {props.classNames?.showSubjectDropdown && <SubjectDropdown onChangeSubject={props.onChangeSubject} label={props.subjectLabel || ""} defaultText={props.subjectDefaultText || ""} options={props.subjects || []}/>}
          <Textarea {...textareaProps(props)} />
          {children}
          { props.classNames?.leadQualityForm && <div className={classNames('borderDivider', style)} data-testing='button-divider'></div>}
          <Action {...actionProps(props, handleClick)} />
          <ErrorMessage {...inputErrorMessageProps(props)} />
        </form>
      }

    </div>
  );
}

const sellerNameProps = (props: EmailLeadFormProps): SellerNameProps => {
  return {
    name: props.sellerName.name,
    address: props.classNames?.leadHygieneForm ? '' : props.sellerName.address,
    addressLink: props.sellerName.addressLink,
    hidePhone: props.sellerName.hidePhone,
    phoneNumber: props.sellerName.phoneNumber,
    phoneText: props.sellerName.phoneText,
    linkContactInfo: props.sellerName.linkContactInfo,
    displayPhoneMessage: props.sellerName.displayPhoneMessage,
    showCloseButton: props.sellerName.showCloseButton,
    onClick: props.sellerName.onClick,
    onClickPhoneMessage: props.sellerName.onClickPhoneMessage,
    onClickCloseButton: props.sellerName.onClickCloseButton,
    closeButtonE2e: `${props.id}-close`
  }
}

const invalidMessageProps = (props: EmailLeadFormProps): ContentProps => {
  return {
    text: props.invalidMessage || '* Please correct highlighted errors.',
    classNames: config.Content.classNames,
    'data-e2e': `${props.id}-invalidMessage`
  };
};

const inputErrorMessageProps = (props: EmailLeadFormProps): ErrorMessageProps => {
  return {
    title: props.errorTitle || 'Request cannot be completed',
    message: props.errorMessage || 'You may have entered incorrect information or server is temporarily down. Please reload this page and try again later.',
    show: props.showErrorMessage || false
  };
};

const inputSuccessMessageProps = (props: EmailLeadFormProps): SuccessMessageProps => {
  return {
    title: props.successTitle || 'Congratulations!',
    message: props.successMessage || 'We have sent your information directly to the seller.',
    show: props.showSuccessMessage,
    'data-e2e': `${props.id}-successMessage`,
    classNames: {
      successCard: props.classNames?.leadHygieneForm,
    }
  };
};

const inputNameProps = (props: EmailLeadFormProps): InputProps => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props.name.label || 'First & Last Name',
    },
    id: props.name.id,
    type: 'text',
    name: 'name',
    value: props.name.value,
    onChange: props.name.onChange,
    onBlur: props.name.onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes('name'),
    'data-e2e': props.name.id
  }
}


const inputEmailProps = (props: EmailLeadFormProps): InputProps => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props.email.label || 'Your Email',
    },
    id: props.email.id,
    type: 'email',
    name: 'email',
    value: props.email.value,
    onChange: props.email.onChange,
    onBlur: props.email.onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes('email'),
    'data-e2e': props.email.id
  }
}

const inputPhoneProps = (props: EmailLeadFormProps): PhoneInput => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props.phone.label || 'Your Phone (Opt)',
    },
    id: props.phone.id,
    type: 'tel',
    name: 'phone',
    value: props.phone.value,
    onChange: props.phone.onChange,
    onBlur: props.phone.onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes('phone'),
    'data-e2e': props.phone.id,
    onChangePrefix: props.phone.onChangePrefix,
  }
}
/* istanbul ignore next */
const inputZipProps = (props: EmailLeadFormProps): InputProps => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props.zip.label || 'Zip Code',
    },
    id: props.zip.id,
    type: 'text',
    name: 'zip',
    value: props.zip.value,
    onChange: props.zip.onChange,
    onBlur: props.zip.onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes('zip'),
    'data-e2e': props.zip.id
  }
}

const textareaProps = (props: EmailLeadFormProps): TextareaProps => {
  const invalidFields = props.invalidFields;
  return {
    labelText: props.textarea.label || 'Your Message',
    name: 'text',
    hidden: !props.classNames?.leadQualityForm,
    classNames: props.classNames?.leadQualityForm ? config.Textarea.classNames : {},
    id: props.textarea.id,
    onChange: props.textarea.onChange,
    onBlur: props.textarea.onBlur,
    defaultValue: props.textarea.defaultValue,
    value: props.textarea.value,
    invalid: invalidFields?.includes('text'),
    'data-e2e': props.textarea.id
  }
}

const actionProps = (props: EmailLeadFormProps, handleClick: any): ActionProps => {
  return {
    onClick: handleClick,
    label: props.buttonLabel || 'Send Email',
    type: 'submit',
    disabled: props.buttonDisabled,
    stretch: true,
    e2e: `${props.id}-submit`,
    attributes: props.buttonAttributes,
  };
}

export default React.memo(EmailLeadForm);
