import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import input from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import LabelProps from '../Label/props';
import Label from '../Label';
import link from '../Link/props';
import Link from '../Link';
import { convertToPrice } from '../../../utils/input';
import Content from '../Content';
import ContentProps from '../Content/props';
import Icon from '../Icon';
import errorIcon from '../../../icons/info_outline_red.svg';
const Input = (props: input, ref: React.ForwardedRef<HTMLInputElement>) => {
  
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState(props.defaultChecked || false);

  const setInputValue = (val: string) => {
    let value = val;
    if (props?.classNames?.price) {
      if (value !== '') {
        value = convertToPrice(value, props.currencyPrefix, props.currency);
      }
    }
    setValue(value);
  };

  useEffect(() => {
    if (props.value || props.value === '') {
      setInputValue(props.value);
    }
    props.defaultChecked && setChecked(props.defaultChecked);
  }, [props.value, props.defaultChecked])

  const toggleChecked = () => {
    setChecked((prev) => !prev)
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
    setInputValue(e.target.value);
    toggleChecked();
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement> ) => {
    setInputValue(e.target.value);
    if (props.onBlur) {
      props.onBlur(e);
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => props.onFocus?.(e);

  const inputType = (
    (config.Input.type as { [key: string]: string })[
      Object.keys(props.classNames || {}).filter(
        key => (props.classNames as { [key: string]: boolean })[key]
      )[0]
    ] ||
    props.type ||
    'text'
  );

 return (
   <span className={classNames("input", style, {...props.classNames, invalid: props.invalid || false, moved: Boolean(value) || false })}>
      <input
        type={inputType}
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        aria-label={props.Label.text}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={props.disabled}
        ref={ref}
        checked={checked}
        data-e2e={props['data-e2e']}
        maxLength={props.maxLength}
        max={props.maxLength}
        data-testing={value ? 'input-with-value' : ''}
      />
      { !props.Label.hidden && <Label htmlFor={props.id} {...labelProps(props)} /> }
      {props.invalid && props.showErrorIcon && <div className={classNames("errorIcon", style)}><Icon {...iconProps()}/> </div>}
      { props.Link && <Link {...linkProps(props)} /> }
      {props.invalid && props.errorLabel && <div className={classNames("errorLabel", style)}><Content {...contentProps(props)}></Content> </div>}
    </span>
  );
}

const labelProps = (props: input): LabelProps => {
  const labelPropValues = {
    ...props.Label as LabelProps,
    classNames: Object
    .keys(props.classNames || {})
    .reduce(
      (acc, value: string) => ({ ...acc, ...((config.Label.classNames as {[key: string]: { [key: string]: boolean }})[value])}),
      {},
    ),
  };
  if (props.invalid){
    labelPropValues.classNames= {
      ...labelPropValues.classNames,
      errorLabel: true
    };
  }
  return labelPropValues;
}

const iconProps = () => {
  return {
    svg:  errorIcon,
    alt: 'error icon',
    classNames: {
      medium: true
    }
  }
};

const contentProps = (props: input): ContentProps => {
  return {
    text: get(props,'errorLabel', ''),
    classNames: {'content-2': true},
    "data-e2e": `error-message-${props.id}`
  }
};

const linkProps = (props: input): link => {
  return {
    ...props.Link as link,
  };

}

export default React.memo(React.forwardRef(Input));
