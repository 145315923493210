import React, { useCallback, useEffect, useState } from 'react';
import EmblaCarouselProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';
import EmblaCarouselDots from './components/EmblaCarouselDots/EmblaCarouselDots';

import useEmblaCarousel from 'embla-carousel-react';
import {
  EmblaEventType,
  EmblaOptionsType,
} from 'embla-carousel';

import Button from '../../private/Button/Button';
import Image from '../../private/Image';

import arrow_left from '../../../icons/arrow_left.svg';
import arrow_right from '../../../icons/arrow_right.svg';
import defaultImage from '../../../icons/placeholder-image.svg';

function EmblaCarousel(props: EmblaCarouselProps) {
  const options: EmblaOptionsType = { loop: true };
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { selectedIndex, setSelectedIndex, onSlideChange } = props;

  const onPrevButtonClick = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      setCurrentIndex(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
      setCurrentIndex(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  const goToSlide = useCallback(() => {
    if (emblaApi && selectedIndex !== undefined) {
      emblaApi.scrollTo(selectedIndex);
    }
  }, [emblaApi, selectedIndex]);

  const handleItemClick: (
    e: React.MouseEvent<HTMLElement>,
    index: number
  ) => void = (e: React.MouseEvent<HTMLElement>, index: number) => {
    if (props.handleCarouselItemClick) {
      props.handleCarouselItemClick(e, index);
    }
  };

  useEffect(() => {
    if (!onSlideChange) {
      if (emblaApi) {
        emblaApi.on('select' as EmblaEventType, () => {
          setSelectedIndex?.(emblaApi.selectedScrollSnap());
        });
      }
      props.goToSlide && goToSlide();
    }
  });

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select' as EmblaEventType, () => {
        onSlideChange && onSlideChange();
        setSelectedIndex?.(emblaApi.selectedScrollSnap());
      });
    }
    props.goToSlide && goToSlide();
  },[emblaApi]);

  const leftButtonProps = (props: EmblaCarouselProps)  => {
    return {
      classNames: {
        ...config.Button.classNames,
      },
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onPrevButtonClick();
        if (props.prevOnClick) {
          props.prevOnClick(event);
        }
      },
      Icon: {
        svg: arrow_left,
        alt: props.prevIconAlt || 'Previous Slide',
      },
    };
  };
  const rightButtonProps = (props: EmblaCarouselProps) => {
    return {
      classNames: {
        ...config.Button.classNames,
      },
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onNextButtonClick();
        if (props.nextOnClick) {
          props.nextOnClick(event);
        }
      },
      Icon: {
        svg: arrow_right,
        alt: props.nextIconAlt || 'Next Slide',
      },
    };
  };

  const showArrowButtons = React.Children.count(props.children) > 1;
  const showDefaultImage = React.Children.count(props.children) < 1;
  const showIndicators = props.classNames?.withIndicators && React.Children.count(props.children) > 1;
  const defaultNoImages = (
    <Image
      src={defaultImage}
      alt='No Image'
      defaultImage={defaultImage}
      key='no image'
    />
  );

  return (
    <div
      className={classNames('emblaCarousel', style, props.classNames)}
      data-e2e='embla-carousel'
    >
      <div className='embla' ref={emblaRef}>
        <div className='embla__container'>
          {showDefaultImage && defaultNoImages}
          {props.children.map((child, index) => (
            <div
              className='embla__slide'
              key={index}
              onClick={(e) => handleItemClick(e, index)}
            >
              {child}
            </div>
          ))}
        </div>
      </div>

      {showIndicators && (
        <EmblaCarouselDots activeSlideIndex={currentIndex} childrenLength={props.children.length} />
      )}

      {showArrowButtons && (
        <Button {...leftButtonProps(props)} role='prev'></Button>
      )}
      {showArrowButtons && (
        <Button {...rightButtonProps(props)} role='next'></Button>
      )}
    </div>
  );
}

export default React.memo(EmblaCarousel);
