import React from 'react';
import errorMessageV2 from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Icon from '../Icon';
import IconProps from '../Icon/props';
import errorIcon from '../../../icons/info_outline_red.svg';
function ErrorMessageV2(props: errorMessageV2) {
  const {title, messages = [], show} = props;
  if (!show) return null;
  return (
    <div className={classNames('errorMessage', style)}>
      <Icon {...iconProps(props)}/>
      <div>
        <p className="message-title">{title}</p>
        <ul>
          {messages.map((message, index) => <li key={`error-message-${index}`}>{message}</li>)}
        </ul>
      </div>
    </div>
  );
}

export default React.memo(ErrorMessageV2);


const iconProps = (props: errorMessageV2): IconProps => {
  return {
    svg: props.icon || errorIcon,
    alt: props.errorIconAlt || 'Error icon'
  };
}