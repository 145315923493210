import React, { useMemo } from 'react';
import appbody from './props';
import style from './style.module.scss';
import classNames from '../../../../../utils/classNames';
import config from './config.json';

import title from '../../../../private/Title/props';
import Title from '../../../../private/Title';
import SubtitleProps from '../../../../private/Subtitle/props';
import Subtitle from '../../../../private/Subtitle';
import ToolbarProps from '../../../../private/Toolbar/props';
import Toolbar from '../../../../private/Toolbar';

import IconProps from '../../../../private/Icon/props';

import star_full from '../../../../../icons/star_full.svg';
import star_half from '../../../../../icons/star_half.svg';

function AppBody(props: appbody) {
  return (
    <div className={classNames('appbody', style)}>
      <Title {...useTitleProps(props)}></Title>
      <Subtitle {...useSubtitleProps(props)}></Subtitle>
      <Toolbar {...useToolbarProps()}></Toolbar>
    </div>
  );
}

const useTitleProps = (props: appbody): title => {
  return useMemo(() => {
    return {
      ...props.Title as title,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const useSubtitleProps = (props: appbody): SubtitleProps => {
  return useMemo(() => {
    return {
      ...props.Subtitle as SubtitleProps,
      classNames: config.Subtitle.classNames
    };
  }, [props]);
}

const useToolbarProps = (): ToolbarProps => {
  return {
    items: config.Toolbar.items.map((item: string) => {
      return {
        Icon: {
          svg: item === 'star_full' ? star_full : star_half,
          alt: 'star',
          classNames: config.Toolbar.Icon.classNames
        } as IconProps
      };
    })
  };
}

export default React.memo(AppBody);