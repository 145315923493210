import React, { useState } from 'react';
import NumberRangeInputProps, { RangeInput } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Input from '../Input';
import input from '../Input/props';
import Content from '../Content';

function NumberRangeInput(props: NumberRangeInputProps) {
  const [collectedValue] = useState({ 
    min: props.min.value || '',
    max: props.max.value || '',
  });

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    collectedValue.min = event.target.value;
    props.onDataChange && props.onDataChange(collectedValue);
    props.min.onChange && props.min.onChange(event);
  };


  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    collectedValue.max = event.target.value;
    props.onDataChange && props.onDataChange(collectedValue);
    props.max.onChange && props.max.onChange(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <div className={classNames('numberRangeInput', style, props.classNames)}>
      <Input
        {...inputProps(props.min)}
        currency={props.currency}
        currencyPrefix={props.currencyPrefix}
        onChange={handleMinChange}
        onFocus={handleFocus}
      />
      <Content text={props.splitter} />
      <Input
        {...inputProps(props.max)}
        currency={props.currency}
        currencyPrefix={props.currencyPrefix}
        onChange={handleMaxChange}
        onFocus={handleFocus}
      />
    </div>
  );
}

const inputProps = (rangeInput: RangeInput): input => ({
  classNames: {
    ...( rangeInput.price ? { price: true } : { number: true }),
    'inner-shadow': rangeInput.innerShadow,
  },
  ...rangeInput,
  Label: {
    text: rangeInput?.label,
  },
});

export default React.memo(NumberRangeInput);