import React from 'react';
import socialShare, { ContactLink } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Modal from '../../private/Modal';
import ModalProps from '../../private/Modal/props';
import Title from '../../private/Title';
import title from '../../private/Title/props';
import Button from '../../private/Button';
import button from '../../private/Button/props';

import InputButtonGlue from '../../private/InputButtonGlue';
import inputButtonGlue from '../../private/InputButtonGlue/props';

import SocialIconsFeed from '../../private/SocialIconsFeed';
import socialIconsFeed from '../../private/SocialIconsFeed/props';
import config from './config.json';


function SocialShare(props: socialShare) {

  const getContactLinks = () => {
    if (!props?.contact?.length) {
      return null;
    }

    return (
      <div>
        { props.contact.map((link: ContactLink, index: number) => !link.hidden && <Button key={index} {...linkProps(link)} />) }
      </div>
    )
  }

  return (
    <div className={classNames('socialShare', style)}>
      <Modal {...modalProps(props)}>
        <Title {...titleProps(props)} />
        <SocialIconsFeed { ...socialIconsFeedProps(props) } />
        <InputButtonGlue { ...inputButtonGlueProps(props) } />
        { getContactLinks() }
      </Modal>
    </div>
  );
}

const modalProps = (props: socialShare): ModalProps => {
  return {
    Button: {
      onClick: props.modal?.onClose
    },
    Blocker: {
      onClick: props.modal?.onClose,
      classNames: config.Blocker.classNames
    },
    width: config.Modal.width,
    classNames: config.Modal.classNames
  }
}

const titleProps = (props: socialShare): title => ({
  text: props.title
})

const linkProps = (link: ContactLink): button => ({
  onClick: link?.onClick,
  classNames: config.Button.classNames,
  Label: {
    text: link?.text || config.Label.text,
    classNames: config.Label.classNames
  }
})

const socialIconsFeedProps = (props: socialShare): socialIconsFeed => ({
  items: props.buttons.map(button => ({
    icon: button.icon,
    label: button.text,
    alt: `icon ${button.text}`,
    onClick: (e) => button.onClick && button.onClick(e, button.icon)
  }))
})

const inputButtonGlueProps = (props: socialShare): inputButtonGlue => ({
  buttonText: props.input.buttonText,
  value: props.input?.value,
  inputDisabled: true,
  onClick: props.input.onClick
})

export default React.memo(SocialShare);