import React from 'react';
import tooltipLite from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Tooltip from 'react-tooltip-lite';
function TooltipLite(props: tooltipLite) {

  return (
    <div className={classNames('tooltipLite', style)}>
      <Tooltip
        arrow={props.arrow}
        arrowSize={props.arrowSize}
        background={props.background}
        useHover={props.useHover}
        isOpen={props.isOpen}
        className={props.className}
        color={props.color}
        content={props.content}
        direction={props.direction}
        distance={props.distance}
        eventOff={props.eventOff}
        eventOn={props.eventOn}
        eventToggle={props.eventToggle}
        forceDirection={props.forceDirection}
        hoverDelay={props.hoverDelay}
        mouseOutDelay={props.mouseOutDelay}
        padding={props.padding}
        styles={props.styles}
        tagName={props.tagName}
        tipContentHover={props.tipContentHover}
        tipContentClassName={props.tipContentClassName}
        useDefaultStyles={props.useDefaultStyles}
        zIndex={props.zIndex}
        onToggle={props.onToggle}
        arrowContent={props.arrowContent}
      >
        {props.children && props.children}
        </Tooltip>
    </div>
  );
}

export default React.memo(TooltipLite);