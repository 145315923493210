import React from 'react';
import link from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Button from '../Button/Button';
import button from '../Button/props';
import Label from '../Label/Label';
import LabelProps from '../Label/props';
import IconProps from '../Icon/props';

function Link(props: link) {
  const rel = props.rel ? { rel: props.rel } : {};
  const target = props.target ? { target: props.target } : {};
  return (
    <a
      href={props.url}
      title={props.title}
      className={classNames('link', style, props.classNames)}
      property={props.property}
      typeof={props.typeOf}
      onClick={props.onClick}
      { ...(props['data-e2e'] && { 'data-e2e': props['data-e2e'] }) }
      {...rel}
      {...target}
      {...props.rest}
      {...props.hyperlinkAttributes}
    >
      {props.property ?
        <span property="name">
          {props.classNames?.button ?
            <Button {...buttonProps(props)}></Button>
          :
            <Label {...labelProps(props)}></Label>
          }
        </span>
      :
        <>
          {props.classNames?.button ?
            <Button {...buttonProps(props)}></Button>
          :
            <Label {...labelProps(props)}></Label>
          }
        </>
      }
    </a>
  );
}

const buttonProps = (props: link): button => {
  return {
    Icon: props.Icon as IconProps,
    ...(props.text && { Label: {
      text: props.text,
      ...(props['data-text-e2e'] && { 'data-e2e': props['data-text-e2e'] })
    } as LabelProps }),
    classNames: {
      pill: props.classNames?.pill,
      shadow: props.classNames?.shadow,
      transparent: props.classNames?.transparent,
      linkWithIcon: props.classNames?.linkWithIcon || props.classNames?.linkWithIconAndBC,
      icon: props.classNames?.icon,
      secondary: props.classNames?.secondary,
      stretch: props.classNames?.stretch,
      large: props.classNames?.large
    },
    onClick: props.onClick
  };
}

const labelProps = (props: link): LabelProps => {
  return {
    text: props.text,
    classNames: (config.Label.classNames as {[key: string]: { [key: string]: boolean }})[Object.keys(props.classNames || {}).filter(key => (props.classNames as {[key: string]: boolean})[key])[0] || 'label-2']
  };
}

export default React.memo(Link);
