import React, {useMemo} from 'react';
import servicesSection from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Title from '../../private/Title';
import title from '../../private/Title/props';
import ServiceInfo from './components/ServiceInfo';
import serviceInfo from './components/ServiceInfo/props';


function ServicesSection(props: servicesSection) {
  return (
    <div className={classNames('servicesSection', style)}>

      <Title {...useTitleProps(props)}></Title>
      <div>
        {props.services.map((_service, index: number) => (
          <ServiceInfo key={index} {...serviceProps(props, index)} />
        ))}
      </div>
      

    </div>
  );
}

const useTitleProps = (props: servicesSection): title => {
  return useMemo(() => {
    return {
      text: props.title,
      classNames: config.Title.classNames
    };
  }, [props]);
}


const serviceProps = (props: servicesSection, index: number): serviceInfo => {
  return {
    onClick: props.services[index].onClick,
    icon: props.services[index].icon,
    title: props.services[index].title,
    content: props.services[index].content,
    link: {
      url: props.services[index].url,
    },
  };
};
  




export default React.memo(ServicesSection);