import React from 'react';
import { PropsTable } from './props';
import style from "./style.module.scss";
import classNames from "../../../utils/classNames";

const DataTable = (props: PropsTable) => {
  const { data, title, children } = props;
  const dataName = props.dataName || '';

  const renderDataItems = () => {
    const tableClass = 'ui-cp-data-table__xlarge';
    return (
      <table
        className={classNames('ui-cp-datatable-section', { ...style, [tableClass]: tableClass }, { [tableClass]: data.length > 10 })}
        id="render-data-items"
      >
        <tbody className={classNames('ui-cp-datatable-section', { ...style, [tableClass]: tableClass }, { [tableClass]: data.length > 10 })}>
          {data.map((item, index) => {
            const value = item.value === true ? '\u2713' : item.value;
            return (
              <tr className={classNames("ui-cp-datatable-item", { ...style, }, {})} key={index}>
                <td className={classNames("ui-cp-datatable-title", { ...style, [dataName]: dataName }, { [dataName]: true })} key={index}>
                  {item.title}
                </td>
                <td className={classNames("ui-cp-datatable-value", { ...style, }, {})}>{value}</td>
              </tr>
            );
          })}
          {children && <tr>{children}</tr>}
        </tbody>
      </table>
    );
  };

  return (
    <div className={classNames('ui-cp-dataTable', { ...style, [dataName]: dataName }, { [dataName]: true })}>
      {
        title ?
          (
            <div className={classNames("ui-cp-datatable-category", { ...style, }, {})} key={"datatable-category-key"} id={title}>
              <h3 className={classNames("ui-cp-datatable-sub-title", { ...style, }, {})} >{title}</h3>
              {renderDataItems()}
            </div>
          ) :
          renderDataItems()
      }
    </div>
  );

};

export default DataTable;