import React from 'react';
import ListingProps, {ListingLayoutProps} from './props';

import ListingEnhanced from '../ListingEnhanced';
import ListingEnhancedProps from '../ListingEnhanced/props';
import ListingPremium from '../ListingPremium';
import ListingPremiumProps from '../ListingPremium/props';
import ListingSocial from '../ListingSocial';
import listingSocial from '../ListingSocial/props';
import ListingSponsored from '../ListingSponsored';
import ListingSponsoredProps from '../ListingSponsored/props';
import ListingStandard from '../ListingStandard';
import ListingStandardProps from '../ListingStandard/props';
import ListingPreviewCard from '../ListingPreviewCard';
import listingPreviewCard from '../ListingPreviewCard/props';
import ListingOem from '../ListingOem';
import ListingOemProps from '../ListingOem/props';
import LinkContainer from "../../private/LinkContainer/LinkContainer";
import {
  makeLinkContainerProps,
  makeToggleProps,
  useToolbarProps
} from "../../../shared/propsBuilders/listingPropsBuilder";

import Toolbar from "../../private/Toolbar/Toolbar";
import Toggle from "../../private/Toggle/Toggle";
import ListingPreview from "../../private/ListingPreview/ListingPreview";
import Divider from "../../private/Divider/Divider";
import Footer from "../../private/Footer/Footer";

export function ListingLayout(props: ListingLayoutProps) {
  const {classNames, height, footerProps, listingPreviewProps} = props;
  const linkProps = makeLinkContainerProps(props);
  const selector = !props.hyperlinkAttributes ? linkProps.dataSelector : {};

  return (
    <LinkContainer {...linkProps}>
      <span
        className={classNames}
        onClick={props.onClick}
        style={{height: height}}
        {...selector}
      >
        <Toolbar {...useToolbarProps(props)} />
        {props.like && <Toggle {...makeToggleProps(props)}></Toggle>}
        <ListingPreview {...listingPreviewProps}></ListingPreview>
        <Divider></Divider>
        <Footer {...footerProps}></Footer>
      </span>
    </LinkContainer>
  );
}

/**
 * @deprecated This function is no longer needed. Use the individual Listing components instead.
 * We might also consider a simplification/unification of the different listings and a re-definition of the types.
 */
function Listing(props: ListingProps) {
  return <>
    {(!props.classNames || props.classNames?.standard) && <ListingStandard {...props as ListingStandardProps}></ListingStandard>}
    {props.classNames?.enhanced && <ListingEnhanced {...props as ListingEnhancedProps}></ListingEnhanced>}
    {props.classNames?.premium && <ListingPremium {...props as ListingPremiumProps}></ListingPremium>}
    {props.classNames?.sponsored && <ListingSponsored {...props as ListingSponsoredProps}></ListingSponsored>}
    {props.classNames?.oem && <ListingOem {...props as ListingOemProps}></ListingOem>}
    {props.classNames?.card && <ListingPreviewCard {...props as listingPreviewCard}></ListingPreviewCard>}
    {props.classNames?.social && <ListingSocial {...props as listingSocial}></ListingSocial>}
  </>;
}

export default React.memo(Listing);
