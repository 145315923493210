import React from 'react';
import {DontShowProps} from "../../props";

function DontShowAgain ({labelText}: DontShowProps) {
  const show = !!labelText;
  if(!show) {
    return null;
  }

  return (
    <p>
      <label htmlFor="do-not-show">
        <input id="do-not-show" type="checkbox" />
        <span>{labelText}</span>
      </label>
    </p>
  )
}

export default DontShowAgain;