import React, { useMemo } from 'react';
import listingHeader from './props';
import style from './style.module.scss';
import classNames from '../../../../../../../utils/classNames';
import config from './config.json';

import title from '../../../../../Title/props';
import Title from '../../../../../Title';
import SubtitleProps from '../../../../../Subtitle/props';
import Subtitle from '../../../../../Subtitle';
import ContentProps from '../../../../../Content/props';
import Content from '../../../../../Content';

function ListingHeader(props: listingHeader) {
  return (
    <span className={classNames('listingHeader', style)}>
      <Title {...useTitleProps(props)}></Title>
      <Subtitle {...useSubtitleProps(props)}></Subtitle>
      <Content {...useContentProps(props)}></Content>
    </span>
  );
}

const useTitleProps = (props: listingHeader): title => {
  return useMemo(() => {
    return {
      ...props.Title as title,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const useSubtitleProps = (props: listingHeader): SubtitleProps => {
  return useMemo(() => {
    return {
      ...props.Subtitle as SubtitleProps,
      classNames: config.Subtitle.classNames
    };
  }, [props]);
}

const useContentProps = (props: listingHeader): ContentProps => {
  return useMemo(() => {
    return {
      ...props.Content as ContentProps,
      classNames: config.Content.classNames
    };
  }, [props]);
}

export default React.memo(ListingHeader);
