import { useEffect, useState } from 'react';

type TUseKeyPress = {
  isEnterPressed: boolean;
}

export function useKeyPress(componentRef: React.RefObject<HTMLInputElement>): TUseKeyPress {
  const [isEnterPressed, setIsEnterPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as Node;
      switch (event.key) {
        case 'Enter':
          if (componentRef && componentRef.current && componentRef.current.contains(target)) {
            setIsEnterPressed(true);
          }
          break;
        default:
          break;
      }
    }
    const handleKeyUp = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          setIsEnterPressed(false);
          break;
        default:
          break;
      }
    }

    window.addEventListener('keyup', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return { isEnterPressed };
}

export default useKeyPress;
