import React from 'react';
import StickyGalleryBannerProps from "./props";
import style from "./style.module.scss";
import config from './config.json';
import classNames from '../../../../../utils/classNames';
import Button from '../../../../private/Button';
import close from '../../../../../icons/close_thin_icon.svg';
import button from '../../../../private/Button/props';
import IconProps from '../../../../private/Icon/props';
import SummarySection from '../../../SummarySection';
import SummarySectionProps from '../../../SummarySection/props';


function StickyGalleryBanner(props: StickyGalleryBannerProps) {
  return (
    <div className={classNames('stickyGalleryBanner', style)} data-e2e='stickyGalleryBanner'>
      <div className={classNames('banner-container', style)}>
        <div className={classNames('boat-info-container', style)}>
            <SummarySection {...boatSummaryProps(props)} />
            <div className={classNames('boat-info-contact-btn', style)}>
              <Button {...contactButtonProps(props)} />
            </div>
        </div>

        <div className={classNames('sticky-close-button', style)}  data-e2e='sticky-close-button'>
            <Button {...closeButtonProps(props)} />
        </div>
      </div>
    </div>
  );
}

const boatSummaryProps = (props: StickyGalleryBannerProps): SummarySectionProps => {
  return {
    classNames: {
      boatInfoModal: true,
    },
    title: props.title,
    price: props.price,
    location: props.location,
    phoneNumber: props.phoneNumber,
    phoneText: props.phoneText,
    onClickPhoneMessage: props.onClickPhoneMessage

  }
}

const contactButtonProps = (props: StickyGalleryBannerProps): button => {
  return {
    onClick: props.onClickContact,
    e2e: 'contact-button-stacked-gallery',
    Label: {
      text: props.contactButtonText || 'Contact'
    },
  }
}
const closeButtonProps = (props: StickyGalleryBannerProps): button => {
  return {
    onClick: props.onClickClose,
    e2e: 'close-button-stacked-gallery',
    Icon: {
      svg: close,
      alt: 'close-button-stacked-gallery',
      classNames: config.Button.Icon.classNames
    } as IconProps
  }
}

export default React.memo(StickyGalleryBanner);