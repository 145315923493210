import React from 'react';
import divider from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function Divider(props: divider) {
  return (
    <div className={classNames('divider', style, props.classNames)}></div>
  );
}

export default React.memo(Divider);