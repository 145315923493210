import React from 'react';
import BrandedSrpHeaderProps from './props';
import './styles.scss';
import PhoneDisplayer from '../../private/PhoneDisplayer';
import address from '../../../icons/address.svg';
import call from '../../../icons/call.svg';
import mail from '../../../icons/mail.svg';
import website from '../../../icons/website.svg';

function BrandedSrpHeader(props: BrandedSrpHeaderProps) {
  return (
    <header className='branded-srp-header'>
      {props.logoSrc && (
        <div className='branded-srp-header__logo'>
          <img src={props.logoSrc} />
        </div>
      )}
      <div className='branded-srp-header__container'>
        <h1 className='branded-srp-header__title'>{props.title}</h1>
        {props.description && <p className='branded-srp-header__description'>{props.description}</p>}
        <a
          className='branded-srp-header__address'
          href={props.addressLink}
          rel='noreferrer'
          target='_blank'
        >
          <img src={address} alt='View Address' />
          {props.address}
        </a>
        <div className='branded-srp-header__ctas'>
          {props.websiteLink && (
            <a
              href={props.websiteLink}
              className='branded-srp-header__website'
              target='_blank'
              rel='noopener noreferrer nofollow'
            >
              <img src={website} alt='Visit Website' />
              {props.websiteText}
            </a>
          )}
          <div
            className='branded-srp-header__contact'
            onClick={props.handleClickContact}
          >
            <img src={mail} alt='Open Contact Form' />
            {props.contactText}
          </div>
          {props.phoneNumber && (
            <div className='branded-srp-header__call'>
              <img src={call} alt='Call' />
              <PhoneDisplayer
                phoneText={props.phoneText || ''} 
                phoneNumber={props.phoneNumber}
                onClickPhoneMessage={props.handleClickPhoneMessage}
              />
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default React.memo(BrandedSrpHeader);
