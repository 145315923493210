import React, { useMemo } from 'react';
import conveyer from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import CarouselProps from '../../private/Carousel/props';
import Carousel from '../../private/Carousel/Carousel';

function Conveyer(props: conveyer) {
  return (
    <div className={classNames('conveyer', style)}>
      <Carousel {...useCarouselProps(props)}></Carousel>
    </div>
  );
}

const useCarouselProps = (props: conveyer): CarouselProps => {
  return useMemo(() => {
    return {
      ...props.Carousel as CarouselProps,
      classNames: config.Carousel.classNames,
      // Ugly hack to make the carousel work with a different scrolling logic for conveyer than for the image carousel
      touchScroll: true,
      innerImageFilter: true,
    };
  }, [props]);
}

export default React.memo(Conveyer);
