import React, { useMemo } from 'react';
import ListingPriceProps from './props';
import style from './style.module.scss';
import classNames from '../../../../../../../utils/classNames';
import config from './config.json';

import ContentProps from '../../../../../Content/props';
import Content from '../../../../../Content';

import listingTooltip from './components/ListingTooltip/props';
import ListingTooltip from './components/ListingTooltip';
import SubtitleProps from '../../../../../Subtitle/props';
import ListingTitleProps from '../ListingTitle/props';
import ListingTitle from '../ListingTitle';

function ListingPrice(props: ListingPriceProps) {
  return (
    <span className={classNames('listingPrice', style)} {...props.dataSelector}>
      <ListingTitle {...useListingTitleProps(props)}></ListingTitle>
      <Content {...useSubtitleProps(props)}></Content>
      {props.Content && <Content {...contentProps(props)}></Content>}
      {props.ListingTooltip && <ListingTooltip {...props.ListingTooltip as listingTooltip}></ListingTooltip>}
    </span>
  );
}

const useListingTitleProps = (props: ListingPriceProps): ListingTitleProps => {
  return useMemo(() => {
    return {
      ...props.ListingTitle as ListingTitleProps
    };
  }, [props]);
}

const useSubtitleProps = (props: ListingPriceProps): SubtitleProps => {
  return useMemo(() => {
    return {
      ...props.Subtitle as ContentProps,
      classNames: config.Subtitle.classNames
    };
  }, [props]);
}

const contentProps = (props: ListingPriceProps): ContentProps => {
  return {
    ...props.Content as ContentProps,
    classNames: config.Content.classNames
  };
}

export default React.memo(ListingPrice);
