import React from 'react';
import tabContent from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../utils/classNames';
import config from './config.json';

import button from '../../../Button/props';
import Button from '../../../Button/Button';
import Fieldset from '../../../Fieldset/Fieldset';
import fieldset from '../../../Fieldset/props';

function TabContent(props: tabContent) {
  return (
    <div className={classNames('tabContent', style)}>
      {props.items?.map((item: any, index: number) => (
        <Fieldset key={index} {...item.Fieldset as fieldset} />
      ))}
      {props.Button && <Button {...buttonProps(props)}></Button>}
    </div>
  );
}

const buttonProps = (props: tabContent): button => {
  return {
    ...props.Button as button,
    classNames: config.Button.classNames
  };
}

export default React.memo(TabContent);