import React, { useCallback, useMemo } from 'react';
import serviceInfo from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../utils/classNames';
import config from './config.json';

import Icon from '../../../../private/Icon/Icon';
import IconProps from '../../../../private/Icon/props';
import Title from '../../../../private/Title/Title';
import title from '../../../../private/Title/props';
import Content from '../../../../private/Content/Content';
import ContentProps from '../../../../private/Content/props';
import Link from '../../../../private/Link/Link';
import link from '../../../../private/Link/props';

import chevron_right from '../../../../../icons/chevron-right.svg';

import buying from '../../../../../icons/buying.svg';
import directory from '../../../../../icons/directory.svg';
import insurance from '../../../../../icons/insurance.svg';
import research from '../../../../../icons/research.svg';
import selling from '../../../../../icons/selling.svg';
import surveyors from '../../../../../icons/surveyors.svg';

const icons = {
  buying,
  directory,
  insurance,
  research,
  selling,
  surveyors
}

function ServiceInfo(props: serviceInfo) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    props.onClick(event);
  }, [props]);

  return (
    <div onClick={onClick} className={classNames('serviceInfo', style)}>
      <Icon {...useIconProps(props)}/>
      <div style={{ maxWidth: config.style.maxWidth }}>
        <Title {...useTitleProps(props)}/>
        <Content {...useContentProps(props)}/>
        <Link {...useLinkProps(props)}/>
      </div>
    </div>
  );
}

const useIconProps = (props: serviceInfo): IconProps => useMemo(() => {
  return {
    svg: (props.icon && icons[props.icon]),
    alt: props.icon,
    classNames: config.Icon.classNames
  };
}, [props]);

const useTitleProps = (props: serviceInfo): title => useMemo(() => {
  return {
    text: props.title,
    classNames: config.Title.classNames
  };
}, [props]);

const useContentProps = (props: serviceInfo): ContentProps => useMemo(() => {
  return {
    text: props.content,
    classNames: config.Content.classNames
  };
}, [props]);

const useLinkProps = (props: serviceInfo): link => useMemo(() => {
  return {
    url: props.link.url,
    text: config.Link.text,
    classNames: config.Link.classNames,
    Icon: {
      svg: chevron_right,
      alt: 'chevron right',
    },
  }
}, [props]);

export default React.memo(ServiceInfo);
