import React from 'react';
import CurrentLocationProps from "./props";
import './styles.scss';
import myLocation from '../../../icons/my_location.svg';


  function CurrentLocation(props: CurrentLocationProps) {

    const handleDetectLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            };
            props.onLocationChange && props.onLocationChange(location);
          },
          (error) => {
            console.log('Error detecting location:', error);
            alert(props.locationDeniedAlertText);
          }
        );
      } else {
        alert(props.errorText || "Geolocation is not supported by this browser.");
      }
    };

  return (
    <button className='current-location' onClick={handleDetectLocation}>
        <img src={myLocation} alt='Detect Location Icon' />
        {props.currentLocationText}
    </button>
  );
}

export default React.memo(CurrentLocation);