import React from 'react';
import ItemPickerProps, { PickerListProps } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Collapsible from '../../private/Collapsible/Collapsible';
import collapsible from '../../private/Collapsible/props';
import config from './config.json';

function ItemPicker(props: any) {
  return (
    <div className={classNames('itemPicker', style)}>
      <Collapsible {...collapsibleProps(props)} />
    </div>
  );
}

const collapsibleProps = (props: ItemPickerProps): collapsible => {
  return {
    ...config.Collapsible,
    InputGroup: undefined,
    header: props.selected || '',
    onChange: props.onChange,
    List: {
      items: props.items.map((option: PickerListProps) => ({
        titlePrefix: option.titlePrefix,
        Content: { text: option.text }
      }))
    }
  }
}

export default React.memo(ItemPicker);