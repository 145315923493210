import React, {useCallback} from 'react';
import summarySection from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import ContentProps from '../../private/Content/props';
import Content from '../../private/Content';
import heading from '../../private/Heading/props';
import Heading from '../../private/Heading';
import config from './config.json';
import PriceSection from './PriceSection';
import priceSection from './PriceSection/props';
import TagProps from '../../private/Tag/props';
import Tag from '../../private/Tag';
import LabelProps from '../../private/Label/props';
import PhoneDisplayer from '../../private/PhoneDisplayer';
import PhoneDisplayerProps from '../../private/PhoneDisplayer/props';
import Link from '../../private/Link/Link';
import LinkProps from '../../private/Link/props';


function SummarySection(props: summarySection) {

  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (props.onClickLocation) {
      props.onClickLocation(event);
    }
  }, [props]);
  const showDimensions = props.dimensions && props.dimensions.length && props.dimensions.width;
  const showTag = props.status && !props.classNames?.boatInfoModal;
  const showViewPhone = props.classNames?.boatInfoModal;
  const locationContent = props.location && <div onClick={onClick}>
    <Content {...locationProps(props)}></Content></div>;

  return (
    <div className={classNames('summarySection', style, props.classNames)} style={{maxWidth: config.indicator.size}}>
      {showTag && <Tag {...statusProps(props)}></Tag>}
      <header>
        <Heading replaceTitleWithDiv={props.replaceTitleWithDiv} {...titleProps(props)}></Heading>
        {props.boatLength && <span>{props.boatLength}</span>}
      </header>

      {showViewPhone || props.onClickPhoneNumber ?
        <div className={props.onClickPhoneNumber ? classNames('show-clickable-phone', style) : classNames('showViewPhone', style)} >
          {locationContent}
          <span>&bull;</span>
          {props.onClickPhoneNumber ? <Link {...linkProps(props)} /> : <PhoneDisplayer {...phoneDisplayerProps(props)} />}
        </div>
        : 
        locationContent
      }

      <PriceSection {...priceProps(props)}></PriceSection>
      {showDimensions && <div className="content-6">
        <p>
        { <span>Length: {props.dimensions?.length}</span>} / 
        { <span> Width: {props.dimensions?.width}</span>}
        </p>
      </div>
      }
    </div>
  );
}

const statusProps = (props: summarySection): TagProps => {
  return {
    classNames: {
      colored: true
    },
    Label: {
      text: props.status
    } as LabelProps
  };
}

const locationProps = (props: summarySection): ContentProps => {
  return {
    classNames: props.locationClassNames ? props.locationClassNames : config.Location.classNames,
    text: props.location
  };
}
const phoneDisplayerProps = (props: summarySection): PhoneDisplayerProps => {
  return {
    phoneNumber: props.phoneNumber || '',
    phoneText: props.phoneText || 'View phone number',
    onClickPhoneMessage: props.onClickPhoneMessage
  }
};

const titleProps = (props: summarySection): heading => {
  return {
    text: props.title,
    classNames: config.Title.classNames
  };
}

const priceProps = (props: summarySection): priceSection => {
  return {
    price: props.price,
    priceDrop: props.priceDrop || '',
    priceDropAlt: props.priceDropAlt || '',
    engineHours: props.engineHours || '',
    classNames: props.classNames
  };
}

const linkProps = (props: summarySection): LinkProps => {
  return {
    onClick: props.onClickPhoneNumber,
    url: `tel:${props.phoneNumber}`,
    text: props.phoneNumber || ''
  }
};

export default React.memo(SummarySection);
