import React from 'react';
import ListingTitleProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../../../utils/classNames';

function ListingTitle(props: ListingTitleProps) {
  return (
      <h2 className={classNames('listingTitle', style, props.classNames)}
          {...props.dataSelector}
          {...(props['data-e2e'] && {'data-e2e': props['data-e2e']})}>
        {props.text}
      </h2>
  );
}

export default React.memo(ListingTitle);
