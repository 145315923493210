import React, {createRef, useState, useMemo, useRef, useEffect} from 'react';
import ImageCarouselProps, {MediaButton, ThumbnailsType, VideoRef} from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import MoreOptions from './components/MoreOptions';

import IconProps from '../../private/Icon/props';
import ImageProps from '../../private/Image/props';
import video from '../../private/Video/props';
import ToggleProps from '../../private/Toggle/props';
import Toggle from '../../private/Toggle';
import CarouselProps, {CarouselValidItem} from '../../private/Carousel/props';
import Carousel from '../../private/Carousel/Carousel';
import Button from '../../private/Button';
import button from '../../private/Button/props';
import Thumbnails from '../../private/Thumbnails/Thumbnails';
import ThumbnailsProps, {ThumbnailItem} from '../../private/Thumbnails/props';
import heart_off from '../../../icons/heart.svg';
import heart_on from '../../../icons/heart_on.svg';
import share from '../../../icons/share.svg';
import view_around from '../../../icons/view_around.svg';
import defaultImage from '../../../icons/placeholder-image.svg';

import { YouTubePlayer } from 'react-youtube';
import {ImageCounterPanel} from "../../index";


function ImageCarousel(props: ImageCarouselProps) {
  const [mainIndex, setMainIndex] = useState(0);
  const [imageCounter, setImageCounter] = useState(0);
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);
  const videoItemRefs = useRef<VideoRef>([]);

  useEffect(() => {
    if (!props.classNames?.thumbnails) return;

    const doPlay = isVideoThumbnail(props.items[mainIndex]) && props.automaticVideoPlay;
    const videRefs = videoItemRefs.current;
    for (let i = 0; i < videRefs.length; i++) {
      if (i === mainIndex) {
        videRefs[i]?.current?.startPlayingVideo(doPlay);
      } else {
        videRefs[i]?.current?.stopPlayingVideo();
      }
    }
  }, [mainIndex, props.classNames?.thumbnails]);

  const carouselCounterUpdate = (index: number) => {
    setImageCounter(++index);
    setSelectedThumbnailIndex(index);
  }
  const goToIndex = (index: number) => {
    setMainIndex(index);
  }

  const handleThumbnailClick = (index: number) => {
    const thumbnailIndex = index - 1;
    videoItemRefs.current = props.items.map((_, i: number) => {
      // istanbul ignore next
      return videoItemRefs.current[i] ?? createRef()
    });
    goToIndex(thumbnailIndex);
    props.onThumbnailClick && props.onThumbnailClick(index);
  }

  const hasImages = props.items?.length > 0;
  const showCounter = props.showCounter && hasImages;
  const showCarouselButtons = props.showButtons && props.buttons?.length && hasImages;

  return (
    <div className={classNames('imageCarousel', style, props.classNames)} data-e2e='bdp-image-carousel'>
      <div data-type="carousel-top-btns">
        { props.showImmersiveTour && !props.options && <Button {...immersiveTourButtonProps(props)} /> }
        <Button {...buttonWithIconProps(props)} />
        { props.options && props.options.length > 0 && <MoreOptions options={props.options}/>}
        { props.externalHeartComponent ? (
          <span>{props.externalHeartComponent}</span>
        ) : (
          !props.hideLikeButton && <Toggle {...toggleProps(props)} />
        )}
      </div>
      { !props.classNames?.thumbnails && showCarouselButtons && (
        <div data-type="carousel-btns">
          { props.buttons?.map((button, index) => button.show && <Button key={index} {...buttonProps(button)} /> ) }
        </div>
      )}
      <ImageCounterPanel show={showCounter} count={imageCounter} total={props.items.length} />
      <Carousel role='image-carousel' {...useCarouselProps(props, mainIndex, videoItemRefs)}
        handleCarouselItemClick={ props.handleCarouselItemClick }
        callBack={carouselCounterUpdate}
        mainIndex={mainIndex}
        changeIndex={ (i: number)=> setMainIndex(i)}
      />
      { props.classNames?.thumbnails && <Thumbnails {...thumbnailsProps(props)} activeIndex={selectedThumbnailIndex} onThumbnailClick={ handleThumbnailClick } /> }
    </div>
  );
}

const buttonProps = (button: MediaButton): button => {
  return {
    Label: { text: button.title },
    classNames: config.Button.classNames,
    onClick: button.onClick
  }
}

const immersiveTourButtonProps = (props: ImageCarouselProps): button => ({
  onClick: props.onClickImmersive,
  Icon: {
    svg: view_around,
    alt: props.immersiveTourIconAlt || 'Immersive Tour',
    classNames: config.Icon.classNames
  } as IconProps
})

const buttonWithIconProps = (props: ImageCarouselProps): button => {
  return {
    onClick: props.onClickShare,
    Icon: {
      svg: share,
      alt: props.shareIconAlt || 'share',
      classNames: config.Icon.classNames
    } as IconProps
  }
}

const toggleProps = (props: ImageCarouselProps): ToggleProps => {
  return {
    onClick: props.toggleOnClick,
    state: 'off',
    states: [{
      state: 'off',
      Icon: {
        svg: heart_off,
        alt: props.heartOffIconAlt || 'off',
        classNames: config.Toggle.classNames
      } as IconProps
    }, {
      state: 'on',
      Icon: {
        svg: heart_on,
        alt: props.heartOnIconAlt || 'on',
        classNames: config.Toggle.classNames
      } as IconProps
    }]
  } as ToggleProps;
}

const lazyDefault = (isLazyLoading?: boolean) => {
  return isLazyLoading || isLazyLoading === undefined;
};

const useCarouselProps = (props: ImageCarouselProps, index: number, childRefs: React.MutableRefObject<VideoRef>): CarouselProps => {
  childRefs.current = props.items.map((_, i: number) => childRefs.current[i] ?? createRef());
  return useMemo(() => {
    return {
      classNames: config.Carousel.classNames,
      prevIconAlt: props.prevIconAlt,
      nextIconAlt: props.nextIconAlt,
      LeftButton: {
        onClick: (e) => {
          childRefs.current.forEach((ref: React.RefObject<YouTubePlayer | HTMLVideoElement>) => {
            ref.current && ref.current.stopPlayingVideo()
          });

          if (props.previousOnClick) {
            props.previousOnClick(e);
          }
        }
      },
      RightButton: {
        onClick: (e) => {
          childRefs.current.forEach((ref: React.RefObject<YouTubePlayer | HTMLVideoElement>) => {
            ref.current && ref.current.stopPlayingVideo()
          });

          if (props.nextOnClick) {
            props.nextOnClick(e);
          }
        }
      },
      items: props.items.map((item: { [key: string]: string | boolean }, i: number) => {
        const lazyLoading = lazyDefault(props.isLazyLoading) && i > 0;
        const fetchPriority = lazyDefault(props.isLazyLoading) && i === 0;

        if (item?.mediaType === '360Image' || item?.mediaType === 'ImagePannellumContainer') {
          // We have more configuration options for the 360 image that we are not using yet
          // update when needed, the library is ready for it
          const pannellum: CarouselValidItem = {
            ImagePannellumContainer: {
              imageSource: item.src as string,
              id: item.id as string,
              config: {
                autoRotate: 0,
                autoLoad: true,
                draggable: false,
                showFullscreenCtrl: false,
              }
            }
          };
          return pannellum;
        }
        if (item?.mediaType === 'video' || item?.mediaType === 'external') {
          const videoProps = {
            Video: {
              src: item.src,
              childRef: childRefs.current[i],
              overlay: props.classNames?.thumbnails ? false : item.overlay,
              playVideo: item.playVideo
            } as video
          };
          return videoProps;
        }

        if (item.sources) {
          const loading = lazyLoading ? 'lazy' : undefined;
          return {
            Picture: {
              alt: item.alt,
              src: item.src,
              sources: item.sources,
              loading,
              fetchPriority: fetchPriority ? 'high' : undefined,
              backgroundImg: true,
              mainImgClassNames: {
                'centered-image': true
              }
            }
          };
        }

        return {
          Image: {
            alt: item.alt,
            src: item.src,
            srcset: item.srcset,
            sizes: item.sizes,
            lazyLoading,
            fetchPriority
          } as ImageProps
        };
      }),
      emptyCarousel: props.emptyCarousel
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, index]);
};

const isButtonThumbnail = (item: ThumbnailsType) => !!item.buttonText;
const isVideoThumbnail = (item: ThumbnailsType) => item?.mediaType === 'video' || item?.mediaType === 'external';

const createButtonThumbnail = (item: ThumbnailsType) => ({
  Button: {
    Label: {
      text: item.buttonText,
    },
    Icon: {
      svg: item.src,
      alt: item.alt,
      srcset: item.srcset,
      sizes: item.sizes,
      defaultImage: defaultImage,
    },
    onClick: item.onClick,
    classNames: config.Thumbnails.Button.classNames,
  }
});

const createVideoThumbnail = (item: ThumbnailsType, hideControls: boolean) => ({
  Video: {
    title: item.title,
    src: item.src,
    overlay: config.Thumbnails.Video.overlay,
    childRef: { current: null },
    hideControls,
    customPlayIcon: hideControls,
    poster: item.poster 
  },
});

const createImageThumbnail = (item: ThumbnailsType, lazyLoading: boolean)=> ({
  Image: {
    src: item.src,
    alt: item.alt,
    srcset: item.srcset,
    sizes: item.sizes,
    defaultImage: defaultImage,
    lazyLoading,
  },
});

const thumbnailsProps = (props: ImageCarouselProps): ThumbnailsProps => {
  const thumbnailList = props.thumbnails;
  const lazyLoading = lazyDefault(props.isLazyLoading);
  const filteredThumbnailList = thumbnailList?.filter(item => item.show !== false);
  const hideThumbnailControls = props.hideThumbnailControls || false;

  return {
    items:  filteredThumbnailList?.map((item) => {
      if (isButtonThumbnail(item)) {
        return createButtonThumbnail(item);
      } else if (isVideoThumbnail(item)) {
        return createVideoThumbnail(item, hideThumbnailControls);
      } else {
        return createImageThumbnail(item, lazyLoading);
      }
    }) as ThumbnailItem[],
  };
};

export default React.memo(ImageCarousel);
