import React from 'react';
import { PictureProps } from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function Picture({ sources, src, alt, title, loading = undefined, fetchPriority, ...props }: PictureProps) {
  return (
    <picture className={classNames('picture-container', style, {})}>
      {
        sources?.map((source, index) => <source key={`${source.media}-${index}`} media={source.media} srcSet={source.srcset}/>)
      }
      { props.backgroundImg && (
        <img
          loading={loading}
          src={src} 
          alt={alt}
          fetchpriority={fetchPriority}
          className={ classNames('blurred-image', style, {}) }
          {...(title ? { title } : {})}
        />
      )}
      <img
        loading={loading}
        src={src} 
        alt={alt}
        fetchpriority={fetchPriority}
        className={ classNames('main-image', style, props.mainImgClassNames) }
        {...(title ? { title } : {})}
      />
    </picture>
  );
}

export default React.memo(Picture);