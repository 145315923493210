import React, {useMemo} from 'react';
import simpleInfoBox from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Title from '../../private/Title/Title';
import title from '../../private/Title/props';
import Link from '../../private/Link/Link';
import link from '../../private/Link/props';
import config from './config.json';

function SimpleInfoBox(props: simpleInfoBox) {
  return (
    <div className={classNames('simple-info-box', style, props.classNames)}>
      <Title {...useTitleProps(props)} />
      <hr />
      {props.children && <div>{props.children}</div>}
      <Link {...useLinkProps(props)}></Link>
    </div>
  );
}

const useTitleProps = (props: simpleInfoBox): title => useMemo(() => {
  return {
    text: props.title,
    classNames: {
      ...config.Title.classNames,
      ...(props.classNames?.untruncate && { untruncate: props.classNames?.untruncate }),
    }
  };
}, [props]);

const useLinkProps = (props: simpleInfoBox): link => useMemo(() => {
  return {
    onClick: props.link.onClick,
    url: props.link.url,
    text: props.link.text,
    classNames: config.Link.classNames,
    hyperlinkAttributes: props.hyperlinkAttributes,
  }
}, [props]);

export default React.memo(SimpleInfoBox);
