import React from 'react';
import LeadConfirmationModalProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';
import Button from '../../private/Button/Button';
import ButtonProps from '../../private/Button/props';
import close from '../../../icons/close.svg';
import Title from '../../private/Title/Title';
import TitleProps from '../../private/Title/props';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import SuccessMessageProps from '../SuccessMessage/props';
import Link from '../../private/Link';
import LinkProps from '../../private/Link/props';
import HorizontalGallery from '../HorizontalGallery/HorizontalGallery';

function LeadConfirmationModal({customClassName='', ...props}: LeadConfirmationModalProps) {

  const buttonProps = (): ButtonProps => {
    return {
      onClick: props.onClose,
      classNames: config.Button.classNames,
      Icon: {
        svg: close,
        alt: 'close',
        classNames: config.Button.Icon.classNames,
      },
      e2e: 'close-lead-confirmation-modal',
    };
  };

  return (
    <div className={`${classNames('leadConfirmationModal', style)} ${customClassName}`} data-e2e='leadConfirmationModal'>
        <section className={classNames('leadModalContent', style)} data-e2e='leadConfirmationModalContent'>
          <div className={classNames('leadModalHeader', style)}>
            <Title {...titleProps(props)} />
            <Button {...buttonProps()} />
          </div>
          <SuccessMessage {...successMessageProps(props)} />
          <HorizontalGallery {...horizontalGalleryProps(props)}>
            {props.children}
          </HorizontalGallery>
          <div className={classNames('leadModalBackToSearch', style)}>
            <Link {...linkProps(props)} />
          </div>
        </section>
    </div>
  );
}

const titleProps = (props: LeadConfirmationModalProps): TitleProps => {
  return {
    text: props.requestSentText || 'Request sent',
  };
};

const successMessageProps = (
  props: LeadConfirmationModalProps
): SuccessMessageProps => {
  return {
    classNames: {
      successCard: true,
    },
    message:
      props.successMessage ||
      'Your request has been sent successfully. The seller will be in touch with you soon.',
    show: true,
    'data-e2e': `confirmation-modal-message`,
  };
};

const horizontalGalleryProps = (props: LeadConfirmationModalProps) => {
  return {
    title: props.title || 'Explore Similar Boats',
    subtitle: props.subtitle || 'Related to this boat',
    hideSeparator: props.hideSeparator,
    hideArrowButtons: false
  };
};

const linkProps = (props: LeadConfirmationModalProps): LinkProps => {
  return {
    onClick: props.handleBackLinkClick,
    url: props.backLinkUrl,
    text: props.backLinkText || 'Back to Search',
  };
};

export default React.memo(LeadConfirmationModal);
