import React, { useCallback} from 'react';
import list from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import LabelProps from '../Label/props';
import Label from '../Label';
import ContentProps from '../Content/props';
import Content from '../Content';
import SubtitleProps from '../Subtitle/props';
import Subtitle from '../Subtitle';
import link from '../Link/props';
import Link from '../Link/Link';

function List(props: list) {


  const onClick: (event: React.MouseEvent<HTMLElement>, index: number) => void = useCallback((event: React.MouseEvent<HTMLElement>, index: number) => { 
    if (props.onClick) {
      props.onClick(event, index);
      }
  }, [props]);


  return (
    <span className={classNames('list', style, props.classNames)} data-e2e={props.e2e}>
      {props.Subtitle && <Subtitle {...subtitleProps(props)}></Subtitle>}
      <ul tabIndex={0}>
        {props.items.map((item: { [key: string]: LabelProps | ContentProps | link | string }, index: number) =>
         <li key={index} onClick={(event) => onClick(event, index)} data-active={ props.activeItem === index ? true: null }>
          { item.prefix && <span>{ item.prefix }</span> }
          {item.Label && <Label {...labelProps(item.Label as LabelProps)} />}
          {item.Content && <Content {...contentProps(item.Content as ContentProps)} />}
          {item.Link && <Link {...linkProps(item.Link as link)} />}
        </li>
        )}
      </ul>
    </span>
  );
}

const subtitleProps = (props: list): SubtitleProps => {
  return {
    ...props.Subtitle as SubtitleProps,
    classNames: config.Subtitle.classNames
  };
}

const labelProps = (item: LabelProps): LabelProps => {
  return {
    ...item as LabelProps,
    classNames: config.Label.classNames
  };
}

const contentProps = (item: ContentProps): ContentProps => {
  return {
    ...item as ContentProps,
    classNames: config.Content.classNames
  };
}

const linkProps = (item: link): link => {
  return {
    ...item as link,
    classNames: config.Link.classNames
  };
}

export default React.memo(List);