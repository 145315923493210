import React, { useEffect } from 'react';
import RadiusDropdownProps from './props';
import './styles.scss';
import chevron_up from '../../../icons/chevron-up2.svg';
import chevron_down from '../../../icons/chevron-down2.svg';

function RadiusDropdown(props: RadiusDropdownProps) {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = React.useState(props.defaultText);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOptionSelect = (index: number, option: string) => {
    setSelectedOption(option);
    setSelectedIndex(index);
    setIsOpen(false);
    props.onChangeRadius?.(index, option);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current) {
        return;
      }
      if (!dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className='radius-dropdown' ref={dropdownRef}>
      <label className='radius-dropdown__label' htmlFor='radius'>
        {props.label}
      </label>
      <div
        className='selected-radius__container'
        onClick={() => setIsOpen(!isOpen)}
        role='button'
        aria-expanded={isOpen}
      >
        <input
          id='radius'
          name='radius'
          className='selected-radius__input'
          value={selectedOption}
          readOnly={true}
          aria-label={selectedOption}
        />
        <img src={isOpen ? chevron_up : chevron_down} alt='toggle icon' />
      </div>
      {isOpen && (
        <div className='radius-dropdown__options'>
          {props.options.map((option, i: number) => (
            <div
              className={`radius-dropdown__option ${i === selectedIndex ? 'selected' : ''}`}
              key={option}
              data-testing={`option-${i}`}
              onClick={() => handleOptionSelect(i, option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(RadiusDropdown);
