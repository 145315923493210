import React from 'react';
import errorMessage from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Icon from '../Icon';
import IconProps from '../Icon/props';
import errorIcon from '../../../icons/error.svg';
function ErrorMessage(props: errorMessage) {
  const {title, message, show} = props;
  if (!show) return null;
  return (
    <div className={classNames('errorMessage', style)}>
      <Icon {...iconProps(props)}/>
      <div>
        <p className="message-title">{title}</p>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default React.memo(ErrorMessage);


const iconProps = (props: errorMessage): IconProps => {
  return {
    svg: errorIcon,
    alt: props.errorIconAlt || 'Error icon'
  };
}