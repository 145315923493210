import React, {useContext, useEffect} from "react";
import {StepProps} from "../props";
import {StepperContext} from "../Stepper";

const Step: React.FC<StepProps> = ({ children, onLoad }) => {
  const stepperContext = useContext(StepperContext);
  useEffect(() => {
    if (onLoad && stepperContext) {
      onLoad(stepperContext.index);
    }
  }, [onLoad, stepperContext]);
  return (<div data-e2e='step'>{children}</div>);
};

export default Step as React.FC<StepProps>;