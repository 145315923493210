import React, { useMemo } from 'react';
import listingCard from "./props";
import style from "./style.module.scss";
import classNames from '../../../../../../../utils/classNames';
import config from './config.json';

import listingNavigation from './components/ListingNavigation/props';
import ListingNavigation from './components/ListingNavigation/ListingNavigation';
import ContentProps from '../../../../../Content/props';
import Content from '../../../../../Content/Content';
import button from '../../../../../Button/props';
import Button from '../../../../../Button/Button';

function ListingCard(props: listingCard) {
  return (
    <div className={classNames('listingCard', style)}>
      <ListingNavigation {...props.ListingNavigation as listingNavigation}></ListingNavigation>
      <Content {...useContentProps(props)}></Content>
      <Button {...useButtonProps(props)}></Button>
    </div>
  );
}

const useContentProps = (props: listingCard): ContentProps => {
  return useMemo(() => {
    return {
      ...props.Content as ContentProps,
      classNames: config.Content.classNames
    };
  }, [props]);
}

const useButtonProps = (props: listingCard): button => {
  return useMemo(() => {
    return {
      ...props.Button as button,
      classNames: config.Button.classNames
    };
  }, [props]);
}

export default React.memo(ListingCard);