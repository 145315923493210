import heartFilled from '../../../icons/heart_filled.svg';
import heartUnfilled from '../../../icons/heart_unfilled.svg';

const config ={
    "status": {
        "liked": {
            "aria": "Unlike Boat",
            "src": heartFilled,
            "alt": "Liked",
            "label": "Saved"
        },
        "unliked": {
            "aria": "Like Boat",
            "src": heartUnfilled,
            "alt": "Not Liked",
            "label": "Save"
        }
    }
}

export default config;