import React, { useMemo, useState, useCallback } from 'react';
import tooltip from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import IconProps from '../Icon/props';
import button from '../Button/props';
import Button from'../../private/Button';

import loadable from '@loadable/component';

import ModalProps from '../Modal/props';
const Modal = loadable(() => import('../../private/Modal'), {ssr: true});
import ContentProps from '../Content/props';
const Content = loadable(() => import('../../private/Content'), {ssr: true});
import title from '../Title/props';
const Title = loadable(() => import('../../private/Title'), {ssr: true});

function Tooltip(props: tooltip) {
  const [show, setShow] = useState({ show: false });

  const onClick: (event: React.MouseEvent<HTMLButtonElement>) => void = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    event?.preventDefault();

    setShow({ show: true });

    if (props.onClick) {
      props.onClick(event);
    }
  }, [props]);

  const onClose: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    event?.preventDefault();
    setShow({ show: false });

    if (props.onClose) {
      props.onClose(event);
    }
  }, [props]);

  const showTitle: boolean = props.Title && props.Title.text ? true : false;

  return (
    <div className={classNames('tooltip', style, show)}>
      <Button {...useButtonProps(props, onClick)}></Button>
      <Modal {...useModalProps(onClose)}>
        { showTitle && <Title {...titleProps(props)}></Title>}
        <Content {...useContentProps(props)}></Content>
      </Modal>
    </div>
  );
}

const useButtonProps = (props: tooltip, onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): button => {
  return useMemo(() => {
    return {
      classNames: config.Button.classNames,
      onClick: onClick,
      Icon: props.Icon as IconProps
    };
  }, [props, onClick]);
}

const useModalProps = (onClose: (event: React.MouseEvent<HTMLElement>) => void): ModalProps => {
  return {
    classNames: config.Modal.classNames,
    Button: {
      onClick: onClose
    },
    Blocker: {
      onClick: onClose,
      classNames: config.Modal.Blocker.classNames
    }
  };
}

const titleProps = (props: tooltip): title => {
    return {
      ...props.Title as title,
      classNames: config.Title.classNames
    };
}

const useContentProps = (props: tooltip): ContentProps => {
  return useMemo(() => {
    return {
      ...props.Content as ContentProps,
      classNames: config.Content.classNames
    };
  }, [props]);
}

export default React.memo(Tooltip);
