import React from 'react';
import InfoDealerPanelProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import InfoDealerPanelContent from "./components/InfoDealerPanelContent/InfoDealerPanelContent";
import InfoDealerPanelContentProps from "./components/InfoDealerPanelContent/props";
import Image from "../../private/Image/Image";
import ImageProps from "../../private/Image/props";

function InfoDealerPanel(props: InfoDealerPanelProps) {
  const {children} = props;
  return (
    <div className={classNames('infoDealerPanel', style)}>
      <InfoDealerPanelContent {...infoDealerPanelContentProps(props)}>
        {children}
      </InfoDealerPanelContent>
      <div>
        <Image {...imgProps(props)} />
      </div>
    </div>
  );
}

function infoDealerPanelContentProps(props: InfoDealerPanelProps): InfoDealerPanelContentProps {
  return {
    title: props.title,
    buttonText: props.buttonText,
    buttonLink: props.buttonLink,
    onClickLinkHandler: props.onClickLinkHandler,
    ...(props.linkText && {linkText: props.linkText}),
    ...(props.linkUrl && {linkUrl: props.linkUrl}),
    ...(props.linkUrlRel && {rel: props.linkUrlRel}),
    ...(props.linkUrlTarget && {linkUrlTarget: props.linkUrlTarget})
  }
}

function imgProps(props: InfoDealerPanelProps): ImageProps {
  return {
    src: props.imgSrc,
    alt: props.imgAlt
  }
}


export default React.memo(InfoDealerPanel);
