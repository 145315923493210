import React from 'react';
import mediaCarouselModal from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Modal from '../../private/Modal';
import ModalProps from '../../private/Modal/props';
import config from './config.json';

function MediaCarouselModal(props: mediaCarouselModal) {
  return (
    <div className={classNames('mediaCarouselModal', style, { open: props.open })}>
      <Modal {...modalProps(props)}>
        { props.children }
      </Modal>
    </div>
  );
}

const modalProps = (props: mediaCarouselModal): ModalProps => {
  return {
    ...config.Modal,
    Button: {
      ...config.Button,
      role: 'close_modal',
      Label: {
        text: props.showCloseText ? props.closeText || 'close' : ''
      },
      onClick: props.onClose
    },
    Blocker: config.Blocker,
    onAnimationEnd: props.onAnimationEnd,
  }
}

export default React.memo(MediaCarouselModal);