import React from 'react';
import SingleChoiceTypeQuestionProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import HeadingTag from '../HeadingTag';

function SingleChoiceTypeQuestion(props: SingleChoiceTypeQuestionProps) {
  const [selectedOption, setSelectedOption] = React.useState('');

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    props.onChange && props.onChange();
  };

  return (
    <div className={classNames('singleChoiceTypeQuestion', style)} data-testing='single-choice'>
      <HeadingTag headingLevel={3} heading={props.questionTitle} />
        {props.options && props.options.map((option) => (
          <div key={option} className={classNames('option', style)}>
            <label>
              <input
                type='radio'
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
                name={props.name}
              />
              {option}
            </label>
          </div>
        ))}
    </div>
  );
}

export default React.memo(SingleChoiceTypeQuestion);
