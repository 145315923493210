import React, {useEffect, useRef, useCallback} from 'react';
import ThumbnailsProps, {XScrollable} from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Image from "../Image/Image";
import Button from "../Button/Button";
import {ThumbnailItem} from './props';
import { throttle } from 'lodash';
import defaultImage from '../../../icons/placeholder-image.svg';
import Video from "../Video/Video";

export const scrollCarouselThumbs = (scrollElement: XScrollable | null, itemElement: XScrollable | null) => {
  if (scrollElement && itemElement) {
      const itemWidth = itemElement.offsetWidth;
      const itemLeft = itemElement.offsetLeft;
      const scrollRect = scrollElement.getBoundingClientRect();
      const itemRect = itemElement.getBoundingClientRect();
      const xLeftLimit = scrollElement.offsetWidth + scrollRect.x;
      const isLast = itemRect.x > xLeftLimit;
      const isFirst = itemRect.x <= scrollRect.x;
      const isLastOverlap = (itemRect.x + itemRect.width > xLeftLimit) && !isLast;
      const isFirstOverlap = itemRect.x + itemRect.width > scrollRect.x;

      if (isLast) {
        scrollElement.scrollLeft = itemLeft;
      }
      if (isFirst) {
        // If selected element overlaps we adjust to it, else we move the scroll to put it at the beginning
        scrollElement.scrollLeft = isFirstOverlap ? itemLeft : itemLeft - scrollElement.offsetWidth + itemWidth;
      }
      if (isLastOverlap) {
        scrollElement.scrollLeft = itemLeft + itemWidth - scrollElement.offsetWidth;
      }
  }
};

export const scrollVerticalCarouselThumbs = (scrollElement: XScrollable | null, itemElement: XScrollable | null, thumbnailScrollOffset = 0) => {
  if (scrollElement && itemElement) {
      const itemHeight = itemElement.offsetHeight;
      const itemTop = itemElement.offsetTop - thumbnailScrollOffset;
      const scrollRect = scrollElement.getBoundingClientRect();
      const itemRect = itemElement.getBoundingClientRect();
      const xTopLimit = scrollElement.offsetHeight + scrollRect.y;
      const isLast = itemRect.y > xTopLimit;
      const isFirst = itemRect.y <= scrollRect.y;
      const isLastOverlap = (itemRect.y + itemRect.height > xTopLimit) && !isLast;
      const isFirstOverlap = itemRect.y + itemRect.height > scrollRect.y;

      if (isLast) {
        scrollElement.scrollTop = itemTop;
      }
      if (isFirst) {
        // If selected element overlaps we adjust to it, else we move the scroll to put it at the beginning
        scrollElement.scrollTop = isFirstOverlap ? itemTop : itemTop - scrollElement.offsetHeight + itemHeight;
      }
      if (isLastOverlap) {
        scrollElement.scrollTop = itemTop + itemHeight - scrollElement.offsetHeight;
      }
  }
};

const getThumbnailClass = (active?: boolean) => {
  const activeThumbnail = active ? classNames('active', style) : '';
  return `${classNames('thumbnail', style)} ${activeThumbnail}`
}


function Thumbnail(props: ThumbnailItem) {
  const thumbnailClass = getThumbnailClass(props.active);
  let thumbnailItem;
  if (props.Video) {
    // Render a placeholder image for video thumbnails
    if (props.Video.poster) {
      thumbnailItem = <Image src={props.Video.poster} alt="Video Placeholder" optsAttributes={props.optsAttributes} />;
    } else if (props.Video.thumbnailVideo) {
      thumbnailItem =  <Video {...props.Video} optsAttributes={props.optsAttributes}></Video>
    } else {
      thumbnailItem = <Image src={defaultImage} alt="Video Placeholder" optsAttributes={props.optsAttributes} />;
    }

  } else if (props.Image) {
    thumbnailItem = <Image {...props.Image} optsAttributes={props.optsAttributes}></Image>;
  } else if (props.Button) {
    thumbnailItem = <Button {...props.Button}></Button>;
  }
  if (!thumbnailItem) {
    return null;
  }
  return (
    <div className={thumbnailClass} onClick={() => props.onClick?.()} data-e2e='carousel-thumbnail' ref={props.active ? props.itemRef : null}>
      {thumbnailItem}
    </div>
  );
}

function Thumbnails(props: ThumbnailsProps) {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const itemRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const scrollElement = scrollRef.current;
    const itemElement = itemRef.current;
    if (props.isVertical) {
      scrollVerticalCarouselThumbs(scrollElement, itemElement, props.thumbnailScrollOffset);
    } else {
      scrollCarouselThumbs(scrollElement, itemElement);
    }
  }, [props.activeIndex, props.thumbnailScrollOffset, props.isVertical]);

  const handleScroll = useCallback(
    throttle(() => {
      props.onThumbnailsScroll && props.onThumbnailsScroll();
    }, 1000, { 'trailing': false }), [props]
  );

  useEffect(() => {
    const scrollElement = scrollRef.current;
    scrollElement?.addEventListener('scroll', handleScroll);

    return () => {
      scrollElement?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleThumbnailClick = (index: number) => {
    if (props.onThumbnailClick) {
      props.onThumbnailClick(index);
    }
  }

  return (
    <div className={classNames('thumbnails', style, props.classNames)} data-e2e='carousel-thumbnails' id='carousel-thumbnails' ref={scrollRef}>
      {props.items.map((item, index) => (
          <Thumbnail {...item as ThumbnailItem}
                     active={props.activeIndex === index}
                     key={index}
                     itemRef={itemRef}
                     onClick={() => handleThumbnailClick(index)}
                     optsAttributes={props.optsAttributes}/>
        )
      )}
    </div>
  );
}

export default React.memo(Thumbnails);
