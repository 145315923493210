
import React from 'react';
import filterTagsBox from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Button from '../../private/Button/Button';
import button from '../../private/Button/props';

import close_grey from '../../../icons/close_grey.svg';

function FilterTagsBox(props: filterTagsBox) {
  return (
    <div className={classNames("filter-tags-box", style)} data-e2e={props.e2e}>
      {props.tags.length !== 0 && <div>
        <Button {...clearButtonProps(props)}></Button>
        {props.tags.map((_tag, index) => (
          <Button key={index} {...tagButtonProps(props, index)}></Button>
        ))}
      </div>}
    </div>
  );
}

const clearButtonProps = (props: filterTagsBox): button => {
  return {
    onClick: props.clear.onClick,
    Label:{
      text: props.clear.text,
    },
    classNames: config.Button.classNames
  }
};

const tagButtonProps = (props: filterTagsBox, index: number): button => {
  return {
    onClick: props.tags[index].onClick,
    Label:{
      text: props.tags[index].text,
    },
    Icon: {
      svg: close_grey,
      alt: 'close'
    },
    classNames: config.Tag.classNames
  }
};

export default React.memo(FilterTagsBox);