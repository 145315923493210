import React, { useEffect, useRef, useState } from 'react';
import HorizontalNavProps, { XScrollable, NavLink } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import { ArrowsHandler } from './components/ArrowsHandler';
import { useScrollDirection } from './hooks/useScrollDirection';
import Link from '../../private/Link';
import LinkProps from '../../private/Link/props';
import config from './config.json';

export const scrollNavigation = (scrollableRefCurrent: XScrollable | null, hideArrowButtons: boolean | undefined, setHideLeft: React.Dispatch<React.SetStateAction<boolean>>, setHideRight: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (scrollableRefCurrent && !hideArrowButtons) {
    if (scrollableRefCurrent.scrollWidth <= scrollableRefCurrent.clientWidth) {
      setHideRight(true);
    }
    const handleScroll = () => {
      const hideLeft = scrollableRefCurrent.scrollLeft === 0;
      const hideRight = scrollableRefCurrent.offsetWidth + scrollableRefCurrent.scrollLeft === scrollableRefCurrent.scrollWidth;
      setHideLeft(hideLeft);
      setHideRight(hideRight);
    };

    const removeEventListener = () => {
      return () => {
        scrollableRefCurrent.removeEventListener('scroll', handleScroll);
      }
    }

    scrollableRefCurrent.addEventListener('scroll', handleScroll);

    return { removeEventListener, showArrowButtons: !hideArrowButtons && !!scrollableRefCurrent};
  }

  return { removeEventListener: () => { return; }, showArrowButtons: false};
};

function HorizontalNav(props: HorizontalNavProps) {
  const [hideLeft, setHideLeft] = useState(true);
  const [hideRight, setHideRight] = useState(false);
  const [showArrowButtons, setShowArrowButtons] = useState(false);
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const { scrollDirection } = useScrollDirection(scrollableRef.current);

  useEffect(() => {
    if (scrollDirection) {
      props.onScrollDirection && props.onScrollDirection(scrollDirection);
    }
  });

  useEffect(() => {
    const scrollableRefCurrent = scrollableRef.current;
    
    const { removeEventListener, showArrowButtons } = scrollNavigation(scrollableRefCurrent, props.hideArrowButtons, setHideLeft, setHideRight);
    setShowArrowButtons(showArrowButtons);
    return removeEventListener();
  }, [scrollableRef, props.hideArrowButtons]);

  return (
    <div className={classNames('horizontalNav', style)} data-e2e='horizontal-navigation-component'>
      <div className={classNames('scrollNav', style )} ref={scrollableRef}>
        {props.links && props.links.map((link, index) => (
          <div className={classNames('navigation-item', style)} key={index}>
            <Link {...getLinkProps(link)} />
          </div>
        ))}
        {showArrowButtons && (
          <ArrowsHandler
            hideLeft={hideLeft}
            hideRight={hideRight}
            leftButtonAltText={props.leftButtonAltText}
            rightButtonAltText={props.rightButtonAltText}
            scrollableRefCurrent={scrollableRef.current}
            leftButtonAttributes={props.leftButtonAttributes}
            rightButtonAttributes={props.rightButtonAttributes}
          />
        )}
      </div>
    </div>
  );
}

const getLinkProps = (props: NavLink): LinkProps => ({
  ...props,
  classNames: config.Link.classNames,
  hyperlinkAttributes: {
    ...props.hyperlinkAttributes,
    'data-active': props.active
  }
});

export default React.memo(HorizontalNav);