import React from 'react';
import heading from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';

function Heading(props: heading) {
  return (
    props.replaceTitleWithDiv ? 
    <div className={classNames('heading', style, props.classNames)} data-e2e={props.e2e}>{props.text}</div> :
    <h1 className={classNames('heading', style, props.classNames)} data-e2e={props.e2e}>{props.text}</h1>
  );
}

export default React.memo(Heading);