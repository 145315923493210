import React, { useState, useEffect, useMemo } from 'react';
import ModalProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';
import blocker from '../Blocker/props';
import Blocker from '../Blocker';
import button from '../Button/props';
import Button from '../Button';

import close from '../../../icons/close.svg';

function Modal(props: ModalProps) {
  const [mobile, setMobile] = useState(!props.classNames?.desktop as boolean);

  useEffect(() => {
    let isMounted = true;

    const resize: () => void = () => {
      requestAnimationFrame(() => {
        if (isMounted) {
          setMobile(window.innerWidth <= config.style.width);
        }
      });
    }

    if (!props.classNames?.mobile && !props.classNames?.desktop) {
      window.addEventListener('resize', resize);

      resize();
    }

    return () => {
      isMounted = false;
      window.removeEventListener('resize', resize);
    }
  }, [props.classNames]);

  const buttonProps = useMemo(() => {
    return {
      ...(props.Button as button),
      classNames: config.Button.classNames,
      Icon: {
        svg: close,
        alt: 'close',
        classNames: props.Button?.Icon?.classNames || config.Button.Icon.classNames
      }
    };
  }, [props]);

  return (
    <Blocker {...props.Blocker as blocker}>
      <div
        className={classNames('modal', style, {...props.classNames, mobile: props.classNames?.mobile || mobile})}
        onAnimationEnd={props.onAnimationEnd}
        style={{
          maxWidth: props.width || config.style.width,
          minHeight: props.height || config.style.height
        }}
        data-e2e='modal'>
        {!props.hideCloseButton && <Button {...buttonProps} />}
        {props.children}
      </div>
    </Blocker>
  );
}

export default React.memo(Modal);
