import React from 'react';
import priceSection from "./props";
import style from "./style.module.scss";
import classNames from '../../../../utils/classNames';
import ContentProps from '../../../private/Content/props';
import Content from '../../../private/Content';
import IconProps from '../../../private/Icon/props';
import Icon from '../../../private/Icon';
import price_drop from '../../../../icons/price_drop.svg';
import config from './config.json';

function PriceSection(props: priceSection) {
const showPriceDrop = props.priceDrop && !props.classNames?.boatInfoModal;
const showEngineHours = props.engineHours && !props.classNames?.boatInfoModal;

  return (
    <div className={classNames('priceSection', style, props.classNames)} style={{maxWidth: config.indicator.size}}>
      {props.price &&
        <span onClick={props.onClick}>
          <Content {...priceProps(props)}></Content>
        </span>
      }
      {showPriceDrop && <span>
          <Icon {...iconProps(props)}></Icon>
          <Content {...priceDropProps(props)}></Content>
        </span>
      }
        {showEngineHours && <Content {...engineHoursProps(props)}></Content>}
    </div>
  );
}

const priceProps = (props: priceSection): ContentProps => {
  return {
    classNames: config.Price.classNames,
    text: props.price
  };
}

const priceDropProps = (props: priceSection): ContentProps => {
  return {
    classNames: config.PriceDrop.classNames,
    text: props.priceDrop
  };
}

const engineHoursProps = (props: priceSection): ContentProps => {
  return {
    classNames: config.EngineHours.classNames,
    text: props.engineHours
  };
}

const iconProps = (props: priceSection): IconProps => {
  return {
    svg: price_drop,
    alt: props.priceDropAlt
  };
}

export default React.memo(PriceSection);