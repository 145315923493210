import React from 'react';
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import {EmailLeadFormModalProps} from "./props";
import config from './config.json';
import {EmailLeadForm} from "../../index";
import EmailLeadFormProps from "../EmailLeadForm/props";
import DontShowAgain from "./components/DontShowAgain/DontShowAgain";
import Button from '../../private/Button/Button';
import ButtonProps from '../../private/Button/props';
import close from '../../../icons/close.svg';
import Title from '../../private/Title/Title';
import TitleProps from '../../private/Title/props';


function EmailLeadFormModal({customClassName='', ...props}: EmailLeadFormModalProps) {
  const { dontShowText, ...rest} = props;

  const emailFormProps = rest as EmailLeadFormProps;
  emailFormProps.classNames = {
    noShadow: true,
  };
  emailFormProps.sellerName.onClickCloseButton = props.onClose;

  if (props.classNames?.leadHygieneModal) {
    emailFormProps.sellerName.showCloseButton = false;
    emailFormProps.classNames.leadHygieneForm = true;
  }
  if (props.classNames?.countryPhoneModal) {
    emailFormProps.classNames.phoneCountry = true;
  }
  if (props.classNames?.leadQualityModal) {
    emailFormProps.classNames.leadQualityForm = true;
  }
  if (props.classNames?.showSubjectDropdown) {
    emailFormProps.classNames.showSubjectDropdown = true;
  }

  
  
  const buttonProps = (): ButtonProps => {
    return {
      onClick: props.onClose,
      classNames: config.Button.classNames,
      Icon: {
        svg: close,
        alt: 'close',
        classNames: config.Button.Icon.classNames,
      },
      e2e: 'close-lead-confirmation-modal',
    };
  };
  
  return (
    <div className={`${classNames('emailLeadFormModal', style, props.classNames)} ${customClassName}`} data-e2e='emailLeadFormModal'>
        <section>
          {props.classNames?.leadHygieneModal && 
            <div className={classNames('leadHygieneModalHeader', style)}>
              <Title {...titleProps(props)} />
              <Button {...buttonProps()} />
            </div>
          }
          <EmailLeadForm
            {...emailFormProps} />
            <DontShowAgain labelText={dontShowText} />
        </section>
    </div>
  );
}

const titleProps = (props: EmailLeadFormModalProps): TitleProps => {
  return {
    text: props.leadHygieneModalHeaderText || 'Contact Broker',
  };
};

export default React.memo(EmailLeadFormModal);