import React from 'react';
import ImageCounterPanelProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';

function ImageCounterPanel(props: ImageCounterPanelProps) {
  const { show, count, total } = props;
  if (!show) {
    return null;
  }

  return (
    <div data-role="carousel-counter" className={classNames('imageCounterPanel', style)}>
      <span>{`${count} / ${total}`}</span>
    </div>
  );
}

export default React.memo(ImageCounterPanel);