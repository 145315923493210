import React from 'react';
import { ActionProps } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';

function Action ({
  size = "medium",
  stretch = false,
  isReverseIcon = false,
  variant = "primary",
  attributes = {},
  label,
  icon,
  e2e,
  as,
  className = "",
  ...rest
}: ActionProps) {

  const getSizeClass = () => {
    const classes = {
      small: false,
      medium: false,
      large: false,
    };

    switch (size) {
      case 'small':
        return {
          ...classes,
          'action-small': true,
        };
      case 'large':
        return {
          ...classes,
          'action-large': true,
        };
      case 'medium':
      default:
        return {
          ...classes,
          'action-medium': true,
        };
    }
  };
  const getVariantClass = () => {
    const classes = {
      'action-primary': false,
      'action-secondary': false,
      'action-text': false,
      'action-alert': false,
    };
    switch (variant) {
      case 'secondary':
        return {
          ...classes,
          'action-secondary': true,
        };
      case 'text':
        return {
          ...classes,
          'action-text': true,
        };
      case 'alert':
        return {
          ...classes,
          'action-alert': true,
        };
      case 'primary':
      default:
        return {
          ...classes,
          'action-primary': true,
        };
    }
  };
  
  const buttonClasses: { [key: string]: boolean } = {
    ...getSizeClass(),
    ...getVariantClass(),
    'action-stretch': stretch,
    'action-reverse': isReverseIcon,
    'action-rounded': Boolean(icon && !label)
  };

  const Component = as || 'button';

  return (
    <Component
      className={`${classNames('action', style, buttonClasses)} ${className}`}
      aria-label={label && label.length ? label : undefined}
      {...attributes}
      data-e2e={e2e}
      {...rest}
    >
      {icon && icon}
      {label && label}
    </Component>
  )
}

export default React.memo(Action);
