import React, { useCallback, useMemo } from 'react';
import ListingPremiumProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import ToolbarProps from '../../private/Toolbar/props';
import Toolbar from'../../private/Toolbar';
import ToggleProps from '../../private/Toggle/props';
import Toggle from '../../private/Toggle';
import FooterProps from '../../private/Footer/props';
import Footer from '../../private/Footer';
import Divider from '../../private/Divider';

import LabelProps from '../../private/Label/props';
import TagProps from '../../private/Tag/props';
import IconProps from '../../private/Icon/props';
import ImageProps from '../../private/Image/props';
import title from '../../private/Title/props';
import SubtitleProps from '../../private/Subtitle/props';
import ContentProps from '../../private/Content/props';
import tooltip from '../../private/Tooltip/props';
import button from '../../private/Button/props';

import ListingPreviewProps from '../../private/ListingPreview/props';
import ListingPreview from '../../private/ListingPreview';

import listingBody from '../../private/ListingPreview/components/ListingCaption/components/ListingBody/props';
import ListingCaptionProps from '../../private/ListingPreview/components/ListingCaption/props';
import ListingPriceProps from '../../private/ListingPreview/components/ListingCaption/components/ListingPrice/props';
import listingTooltip from '../../private/ListingPreview/components/ListingCaption/components/ListingPrice/components/ListingTooltip/props';
import ListingTitleProps from '../../private/ListingPreview/components/ListingCaption/components/ListingTitle/props';

import heart_off from '../../../icons/heart_off.svg';
import heart_on from '../../../icons/heart_on.svg';
import svg_info from '../../../icons/info_outline.svg';

function ListingPremium(props: ListingPremiumProps) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    props?.onClick?.(event);
  }, [props]);

  return (
    <span
      className={classNames('listingPremium', style)}
      onClick={onClick}
      style={{ height: config.style.height }}
    >
      <Toolbar {...useToolbarProps(props)}></Toolbar>
      {props.like && <Toggle {...toggleProps(props)}></Toggle>}
      <ListingPreview {...useListingPreviewProps(props)}></ListingPreview>
      <Divider></Divider>
      <Footer {...useFooterProps(props)}></Footer>
    </span>
  );
}

const useToolbarProps = (props: ListingPremiumProps): ToolbarProps => {
  return useMemo(() => {
    const tags = props.tags || [];
    return {
      items: tags.map((tag: {[key: string]: string}) => {
        return {
          Tag: {
            Label: {
              text: tag.text
            } as LabelProps,
            classNames: config.Toolbar.Tag.classNames
          } as TagProps
        };
      })
    };
  }, [props]);
}

const toggleProps = (props: ListingPremiumProps): ToggleProps => {
  return {
    onClick: props.like?.onClick,
    state: props.like?.state,
    states: [{
      state: props.like?.off,
      Icon: {
        svg: heart_off,
        alt: props.like?.off
      } as IconProps
    }, {
      state: props.like?.on,
      Icon: {
        svg: heart_on,
        alt: props.like?.on
      } as IconProps
    }]
  } as ToggleProps;
}

const useListingPreviewProps = (props: ListingPremiumProps): ListingPreviewProps => {
  return useMemo(() => {
    return {
      Image: {
        src: props.image,
        alt: props.imageAlt,
        lazyLoading: props.imageLazyLoading,
        fetchPriority: props.imageFetchPriority,
        height: config.ListingPreview.Image.height
      } as ImageProps,
      ListingCaption: {
        ListingPrice: {
          ListingTitle: {
            text: props.name,
            'data-e2e': 'listingName'
          } as ListingTitleProps,
          Subtitle: {
            text: props.price,
            'data-e2e': 'listingPrice'
          } as SubtitleProps,
          Content: !props.prevPrice ? undefined :{
            text: props.prevPrice
          } as ContentProps,
          ListingTooltip: !props.monthlyPrice?.text?.length ? undefined : {
            Subtitle: {
              text: props.monthlyPrice.text,
              'data-e2e': 'listingMonthlyPrice'
            } as SubtitleProps,
            Tooltip: {
              Icon: {
                svg: svg_info,
                alt: 'info'
              } as IconProps,
              Title: {
                text: props.monthlyPrice.tooltip.title
              } as title,
              Content: {
                text: props.monthlyPrice.tooltip.content
              } as ContentProps,
              onClick: props.monthlyPrice?.onClick,
              onClose: props.monthlyPrice?.onClose
            } as tooltip
          } as listingTooltip
        } as ListingPriceProps,
        ListingBody: {
          SellerContent: {
            text: props.seller,
            'data-e2e': 'listingSellerContent'
          } as ContentProps
        } as listingBody
      } as ListingCaptionProps,
      classNames: config.ListingPreview.classNames
    };
  }, [props]);
}

const useFooterProps = (props: ListingPremiumProps): FooterProps => {
  return useMemo(() => {
    return {
      Image: !props.logo ? undefined : {
        src: props.logo,
        alt: props.logoAlt
      } as ImageProps,
      Toolbar: {
        items: [{
          Button: {
            onClick: props.contact.onClick,
            Label: {
              text: props.contact.text
            } as LabelProps,
            classNames: config.Footer.Toolbar.Button.classNames,
            e2e: 'listingContactButton'
          } as button
        }]
      } as ToolbarProps
    };
  }, [props]);
}

export default React.memo(ListingPremium);
