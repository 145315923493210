import React, {useEffect} from 'react';
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Modal from "../../private/Modal/Modal";
import {EmailLeadFormModalPropsV2} from "./props";
import config from './config.json';
import {EmailLeadFormV2} from "../../index";
import EmailLeadFormPropsV2 from "../EmailLeadFormV2/props";
import DontShowAgain from "./components/DontShowAgain/DontShowAgain";
import Button from '../../private/Button/Button';
import ButtonProps from '../../private/Button/props';
import close from '../../../icons/close.svg';
import Title from '../../private/Title/Title';
import TitleProps from '../../private/Title/props';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const nil = () => {};

function EmailLeadFormModalV2(props: EmailLeadFormModalPropsV2) {
  const [visible, setVisible] = React.useState(props.show);
  const {show, showCloseText, onAnimationEnd, dontShowText, ...rest} = props;
  useEffect(() => {
    setVisible(show);
    if (show && props.classNames?.leadHygieneModal) {
      document.body.style.overflow = 'hidden';
    } 
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show, props.classNames]);

  const parentClose = props.onClose || nil;
  const onClose = (ev: React.MouseEvent<HTMLElement>) => {
    setVisible(false);
    parentClose(ev);
  }
  if(!visible) {
    return null;
  }
  const modalProps = createModalProps({...rest, onClose, onAnimationEnd, showCloseText});
  const emailFormProps = rest as EmailLeadFormPropsV2;
  emailFormProps.classNames = {
    noShadow: true,
  };
  emailFormProps.sellerName.onClickCloseButton = onClose;

  if (props.classNames?.leadHygieneModal) {
    emailFormProps.sellerName.showCloseButton = false;
    emailFormProps.classNames.leadHygieneForm = true;
  }
  
  const buttonProps = (): ButtonProps => {
    return {
      onClick: onClose,
      classNames: config.Button.classNames,
      Icon: {
        svg: close,
        alt: 'close',
        classNames: config.Button.Icon.classNames,
      },
      e2e: 'close-lead-confirmation-modal',
    };
  };
  
  return (
    <div className={classNames('emailLeadFormModal', style, {...props.classNames})} data-e2e='emailLeadFormModal'>
      <Modal {...modalProps}>
        <section>
          {props.classNames?.leadHygieneModal && 
          <div className={classNames('leadHygieneModalHeader', style)}>
          <Title {...titleProps(props)} />
          <Button {...buttonProps()} />
        </div>
        }
          <EmailLeadFormV2
            {...emailFormProps} />
            <DontShowAgain labelText={dontShowText} />
        </section>
      </Modal>
    </div>
  );
}

const createModalProps = (props: EmailLeadFormModalPropsV2) => {
  const {onClose, onAnimationEnd, showCloseText} = props;
  const {Modal, Button} = config;
  const blockerProps = {
    onClick: onClose,
    classNames: {
      ...config.Blocker.classNames,
      scroll: !props.classNames?.leadHygieneModal,
    }
  };
  return {
    ...Modal,
    Button: {
      ...Button,
      Label: {
        text: showCloseText ? Button.Label.text : ''
      },
      onClick: onClose
    },
    Blocker: blockerProps,
    onAnimationEnd: onAnimationEnd,
    hideCloseButton: true
  }
}
const titleProps = (props: EmailLeadFormModalPropsV2): TitleProps => {
  return {
    text: props.leadHygieneModalHeaderText || 'Contact Broker',
  };
};

export default React.memo(EmailLeadFormModalV2);