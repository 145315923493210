import React, { useCallback } from 'react';
import listingPreviewCard from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import ImageProps from '../../private/Image/props';
import title from '../../private/Title/props';
import IconProps from '../../private/Icon/props';
import ContentProps from '../../private/Content/props';
import button from '../../private/Button/props';

import ListingPreviewProps from '../../private/ListingPreview/props';
import ListingPreview from '../../private/ListingPreview';

import ListingCaptionProps from '../../private/ListingPreview/components/ListingCaption/props';
import listingCard from '../../private/ListingPreview/components/ListingCaption/components/ListingCard/props';

import arrow_circle_right from '../../../icons/arrow_circle_right.svg';

function ListingPreviewCard(props: listingPreviewCard) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    
    props.onClick(event);
  }, [props]);

  if (props.isLink) {
    return (
      <a href={props.linkUrl}>
        <span
          className={classNames('listingPreviewCard', style)}
          style={{ height: config.style.height }}
        >
          <ListingPreview {...listingPreviewProps(props)}></ListingPreview>
        </span>
      </a>
    );
  }
  return (
    <span
      className={classNames('listingPreviewCard', style)}
      onClick={onClick}
      style={{ height: config.style.height }}
    >
      <ListingPreview {...listingPreviewProps(props)}></ListingPreview>
    </span>
  );
}

const listingPreviewProps = (props: listingPreviewCard): ListingPreviewProps => {
  return {
    Image: {
      src: props.image,
      alt: props.imageAlt,
      lazyLoading: props.imageLazyLoading,
      fetchpriority: props.imageFetchPriority,
      height: config.ListingPreview.Image.height
    } as ImageProps,
    ListingCaption: {
      ListingCard: {
        ListingNavigation: {
          Title: {
            text: props.name,
            'data-e2e': 'listingName'
          } as title,
          Icon: {
            svg: arrow_circle_right,
            alt: props.card?.iconAlt || config.ListingPreview.ListingCaption.ListingCard.Icon.alt
          } as IconProps
        },
        Content: {
          text: props.description
        } as ContentProps,
        Button: {
          onClick: props.card?.onClick,
          Label: {
            text: props.card?.link
          }
        } as button
      } as listingCard
    } as ListingCaptionProps
  };
}

export default React.memo(ListingPreviewCard);