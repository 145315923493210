import React, { useState, useMemo, useEffect, useRef } from 'react';
import collapsible from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import down_arrow from '../../../icons/down_arrow.svg';
import down_arrow_black from '../../../icons/down_arrow_black.svg';
import config from './config.json';

import Button from '../Button/Button';
import button from '../Button/props';
import InputGroup from '../InputGroup/InputGroup';
import inputGroup from '../InputGroup/props';
import List from '../List/List';
import list from '../List/props';


const icon = {
  default: {
    svg: down_arrow,
    alt: 'down_arrow',
  },
  black: {
    svg: down_arrow_black,
    alt: 'down_arrow_black',
  },
}

function Collapsible(props: collapsible) {
  const { isAnimated = false } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);
  const [selectedItem, setSelectedItem] = useState({ itemText: '', itemIndex: -1 });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeOnOutsideClick = props.closeOnOutsideClick ?? true;

  const closeCollapsible = () => { 
    setIsOpen(false);
  }

  const handleClick = () => {
    setIsOpen((prevState) => {
      props.onOpen && props.onOpen(!prevState);
      return !prevState
    });
  };

  const handleListClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    const listItem = event.currentTarget;
    const { List } = props;
    const selectedItem = {
      ...(List?.items[index]?.Link && {Link: List?.items[index].Link }),
      ...(List?.items[index]?.Content && {Content: List?.items[index].Content }),
      ...(List?.items[index]?.Label && {Label: List?.items[index].Label }),
    };

    const itemText = Object.values(selectedItem)[0]?.text || listItem.textContent as string;

    const prefix = props.List?.items?.[index]?.titlePrefix || '';

    setSelectedItem((prevState) => {
      return {
        ...prevState,
        itemText: prefix + itemText,
        itemIndex: index
      };
    });

    if (props.onChange) {
      props.onChange(event, itemText, index);
    }

    setTimeout(closeCollapsible, 400);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (closeOnOutsideClick && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      closeCollapsible();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const renderContent = () => (
    <div>
      {props.children && <div>{props.children}</div>}
      {props.InputGroup && <InputGroup {...inputGroupProps(props)}></InputGroup>}
      {props.List && <List {...listProps(props, handleListClick, selectedItem.itemIndex)}></List>}
    </div>
  )

  return (
    <div className={classNames('collapsible', style, {
      ...props.classNames,
      rotated: isOpen,
      open: isOpen,
      animated: isAnimated,
    })} data-e2e={props.e2e} ref={dropdownRef}>
      <Button {...useBtnProps(props, selectedItem.itemText, handleClick, isOpen)}></Button>

      { isAnimated ? renderContent() : isOpen && renderContent()}
    </div>
  );
}

const useBtnProps = (props: collapsible, selectedItem: string, handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void, isOpen: boolean): button => {
  return useMemo(() => {
    return {
      onClick: handleClick,
      isOpen: isOpen,
      Label: {
        text: selectedItem !== '' ? selectedItem : props.header,
      },
      Icon: {
        ...(props?.classNames?.section ? icon.black : icon.default),
      },
      classNames: (config.Button.classNames as any)[Object.keys(props.classNames || {}).filter(key => (props.classNames as {[key: string]: boolean})[key])[0] || 'internal']
    };
  }, [props, selectedItem, handleClick, isOpen]);
};

const inputGroupProps = (props: collapsible): inputGroup => {
  return {
    ...props.InputGroup as inputGroup,
  };
};

const listProps = (props: collapsible, handleListClick: (event: React.MouseEvent<HTMLElement>, index: number) => void, activeItem: number): list => {
  return {
    ...props.List as list,
    onClick: handleListClick,
    activeItem: activeItem,
    classNames: {
      ...config.List.classNames,
      ...( props.classNames?.activeTick && { activeTick: true })
    }
  };
};

export default React.memo(Collapsible);