import React from 'react';
import QuestionnaireProps, { QuestionsProps } from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';
import Button from '../../private/Button/Button';
import ButtonProps from '../../private/Button/props';
import close from '../../../icons/close.svg';
import Title from '../../private/Title/Title';
import qualifying_questions from '../../../icons/qualifying-questions.svg';
import Content from '../../private/Content';
import Icon from '../../private/Icon';
import SingleChoiceTypeQuestion from '../SingleChoiceTypeQuestion/SingleChoiceTypeQuestion';
import BooleanTypeQuestion from '../BooleanTypeQuestion/BooleanTypeQuestion';
import TextTypeQuestion from '../TextTypeQuestion/TextTypeQuestion';

const Questions = ({ questions, setIsAnswered }: QuestionsProps) => {
  const onChange = () => {
    setIsAnswered(true);
  }

  return (
    <div className={classNames('questions', style)}>
      {questions.map((question, index) => {
        switch (question.type) {
          case 'single-choice':
            return (
              <SingleChoiceTypeQuestion
                key={index}
                questionTitle={question.title}
                options={question.options ?? []}
                onChange={onChange}
                name={question.name}
              />
            );
          case 'boolean':
            return <BooleanTypeQuestion 
              key={index} 
              questionTitle={question.title} 
              options={question.options ?? []}
              onChange={onChange}
              name={question.name}
            />;
          case 'text':
            return <TextTypeQuestion 
              key={index} 
              questionTitle={question.title} 
              placeholder={question.placeholder} 
              textLabel={question.textLabel}
              textId={question.textId}
              textValue={question.textValue}
              onChange={onChange}
              name={question.name}
            />;
          default:
            return null;
        }
      })}
    </div>
  );
};

function Questionnaire(props: QuestionnaireProps) {
  const fromRef = React.useRef<HTMLFormElement>(null);
  const [isAnswered, setIsAnswered] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    props.onSubmit && props.onSubmit(e, formData);
  };

  const handleDoneClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    fromRef?.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );

      props.handleDoneClick && props.handleDoneClick(e);
  };

  const buttonCloseProps = (): ButtonProps => {
    return {
      onClick: props.onClose,
      classNames: config.Button.classNames,
      Icon: {
        svg: close,
        alt: 'close',
        classNames: config.Button.Icon.classNames,
      },
      e2e: 'questionnaireCloseBtn',
    };
  };

  return (
    <div
      className={classNames('questionnaire', style)}
      data-testing='questionnaire'
    >
      <div className={classNames('questionnaire-header', style)}>
        <Title text={props.headerText || "We're working on your request"} />
        <Button {...buttonCloseProps()} />
      </div>
      <div className={classNames('questions-intro', style)}>
        <Content text={props.introText || 'Answer a few questions to help the seller assist you better.' }/>
        <Icon
          svg={qualifying_questions}
          alt='questions'
          classNames={{ s40: true }}
        />
      </div>
      <form
        className={classNames('questionnaire-form', style)}
        ref={fromRef}
        onSubmit={handleSubmit}
      >
        <Questions questions={props.questions} setIsAnswered={setIsAnswered}/>
        <div className={classNames('questionnaire-buttons', style)}>
          <Button {...buttonDoneProps(props, handleDoneClick)} disabled={!isAnswered} 
          />
          <Button {...buttonSkipProps(props)} />
        </div>
      </form>
    </div>
  );
}

const buttonDoneProps = (
  props: QuestionnaireProps,
  handleDoneClick: React.MouseEventHandler<HTMLButtonElement>
): ButtonProps => {
  return {
    onClick: handleDoneClick,
    type: 'submit',
    Label: {
      text: props.doneButtonText || 'Done',
    },
    e2e: 'questionnaireDoneBtn',
    buttonAttributes: props.doneButtonAttributes,
  };
};
const buttonSkipProps = (props: QuestionnaireProps): ButtonProps => {
  return {
    onClick: props.handleSkipClick,
    classNames: {
      link: true,
    },
    Label: {
      text: props.skipButtonText || 'Skip',
    },
    e2e: 'questionnaireSkipBtn',
    buttonAttributes: props.skipButtonAttributes,
  };
};

export default React.memo(Questionnaire);
