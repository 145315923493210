import React, { useMemo } from "react";
import oemInfoPanel from "./props";
import style from "./style.module.scss";
import config from "./config.json";
import Label from "../../private/Label";
import LabelProps from "../../private/Label/props";
import Title from "../../private/Title/Title";
import title from "../../private/Title/props";
import Button from "../../private/Button/Button";
import button, { ButtonType } from "../../private/Button/props";
import classNames from "../../../utils/classNames";

function OEMInfoPanel(props: oemInfoPanel) {
  return (
    <div 
      className={classNames("oem-info-panel", style, props.classNames)}
      style={{ backgroundImage: `url('${props.bgUrl}')` }}>
      <Title {...useTitleProps(props)} />
      <Label {...useLabelProps(props)} />
      <Button {...useButtonProps(props)} />
    </div>
  );
}

const useTitleProps = (props: oemInfoPanel): title =>
  useMemo(() => {
    return {
      text: props.title,
      classNames: config.Title.classNames
    };
  }, [props]);

const useLabelProps = (props: oemInfoPanel): LabelProps =>
  useMemo(() => {
    return {
      text: props.infoText,
      classNames: config.Label.classNames
    };
  }, [props]);

const useButtonProps = (props: oemInfoPanel): button =>
  useMemo(() => {
    return {
      onClick: props.button.onClick,
      type: config.Button.type as ButtonType,
      classNames: config.Button.classNames,
      Label: {
        text: props.button.label
      }
    };
  }, [props]);

export default React.memo(OEMInfoPanel);
