import React, { useEffect, useState } from "react";
import Button from '../../../private/Button/Button';
import button from '../../../private/Button/props';
import arrow_left from '../../../../icons/arrow_left_2.svg';
import arrow_right from '../../../../icons/arrow_right_2.svg';
import config from '../config.json';
import ArrowsHandlerProps, { ButtonAttributes } from "./props";
const LEFT_GAIN = -1;
const RIGHT_GAIN = 1;

const leftButtonProps = (
  leftButtonAltText: string | undefined,
  onClick: (gain: number) => void,
  leftButtonAttributes: ButtonAttributes | undefined
): button => {
  return {
    classNames: {
      ...config.Button.classNames
    },
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClick(LEFT_GAIN);
    },
    Icon: {
      svg: arrow_left,
      alt: leftButtonAltText || config.Button.altLeft
    },
    buttonAttributes: leftButtonAttributes
  };
}

const rightButtonProps = (
  rightButtonAltText: string | undefined,
  onClick: (gain: number) => void,
  rightButtonAttributes: ButtonAttributes | undefined
): button => {
  return {
    classNames: {
      ...config.Button.classNames
    },
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClick(RIGHT_GAIN);
    },
    Icon: {
      svg: arrow_right,
      alt: rightButtonAltText || config.Button.altRight
    },
    buttonAttributes: rightButtonAttributes
  };
}

export const ArrowsHandler = ({ 
  hideLeft,
  hideRight,
  leftButtonAltText,
  rightButtonAltText,
  scrollableRefCurrent,
  leftButtonAttributes,
  rightButtonAttributes
}: ArrowsHandlerProps) => {
  const [displayButtons, setDisplayButtons] = useState(!!scrollableRefCurrent);

  useEffect(() => {
    setDisplayButtons(!!scrollableRefCurrent);
  }, [scrollableRefCurrent])
  
  if (!displayButtons || scrollableRefCurrent === null) {
    return null;
  }

  const onClickRight = (gain: number) => {
    scrollableRefCurrent.scrollLeft += scrollableRefCurrent.clientWidth * gain;
  }

  const onClickLeft = (gain: number) => {
    scrollableRefCurrent.scrollLeft += scrollableRefCurrent.clientWidth * gain;
  }

  return (
    <>
      {!hideLeft && <Button {...leftButtonProps(leftButtonAltText, onClickLeft, leftButtonAttributes)} role="prev" />}
      {!hideRight && <Button {...rightButtonProps(rightButtonAltText, onClickRight, rightButtonAttributes)} role="next" />}
    </>
  );
};