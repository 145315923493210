import React, { useMemo } from 'react';
import TagProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import config from './config.json';

import LabelProps from '../Label/props';
import Label from '../Label';

function Tag(props: TagProps) {
  return (
    <span className={classNames('tag', style, props.classNames)}>
      <Label {...useLabelProps(props)}></Label>
    </span>
  );
}

const useLabelProps = (props: TagProps): LabelProps => {
  return useMemo(() => {
    return {
      ...props.Label as LabelProps,
      classNames: (config.Label.classNames as {[key: string]: { [key: string]: boolean }})[Object.keys(props.classNames || {}).filter(key => (props.classNames as {[key: string]: boolean})[key])[0] || 'normal']
    };
  }, [props]);
}

export default React.memo(Tag);