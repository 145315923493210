import React from 'react';
import TextTypeQuestionProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import HeadingTag from '../HeadingTag';
import Textarea from '../../private/Textarea';

function TextTypeQuestion(props: TextTypeQuestionProps) {
  return (
    <div className={classNames('textTypeQuestion', style)} data-testing='text-type-question'>
      <HeadingTag headingLevel={3} heading={props.questionTitle} />
      <Textarea {...textareaProps(props)} />
    </div>
  );
}
const textareaProps = (props: TextTypeQuestionProps) => {
  return {
    labelText: props.textLabel,
    name: props.name,
    hidden: true,
    id: props.textId || 'questionnaire-textarea',
    onChange: props.onChange,
    placeholder: props.placeholder,
    value: props.textValue,
    'data-e2e': 'questionnaire-textarea'
  }
}

export default React.memo(TextTypeQuestion);