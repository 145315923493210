import React, { useEffect, useMemo, useRef, useState } from 'react';
import HorizontalGalleryProps, { XScrollable } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import Title from '../../private/Title';
import title from '../../private/Title/props';
import Subtitle from '../../private/Subtitle';
import SubtitleProps from '../../private/Subtitle/props';
import { ArrowsHandler } from './components/ArrowsHandler';
import { useScrollDirection } from './hooks/useScrollDirection';

export const scrollCarouselListings = (scrollableRefCurrent: XScrollable | null, hideArrowButtons: boolean | undefined, setHideLeft: React.Dispatch<React.SetStateAction<boolean>>, setHideRight: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (scrollableRefCurrent && !hideArrowButtons) {
    if (scrollableRefCurrent.scrollWidth <= scrollableRefCurrent.clientWidth) {
      setHideRight(true);
    }
    const handleScroll = () => {
      const hideLeft = scrollableRefCurrent.scrollLeft === 0;
      const hideRight = scrollableRefCurrent.offsetWidth + scrollableRefCurrent.scrollLeft === scrollableRefCurrent.scrollWidth;
      setHideLeft(hideLeft);
      setHideRight(hideRight);
    };

    const removeEventListener = () => {
      return () => {
        scrollableRefCurrent.removeEventListener('scroll', handleScroll);
      }
    }

    scrollableRefCurrent.addEventListener('scroll', handleScroll);

    return { removeEventListener, showArrowButtons: !hideArrowButtons && !!scrollableRefCurrent};
  }

  return { removeEventListener: () => { return; }, showArrowButtons: false};
};

function HorizontalGallery(props: HorizontalGalleryProps) {
  const [hideLeft, setHideLeft] = useState(true);
  const [hideRight, setHideRight] = useState(false);
  const [showArrowButtons, setShowArrowButtons] = useState(false);
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const { scrollDirection } = useScrollDirection(scrollableRef.current);

  useEffect(() => {
    if (scrollDirection) {
      props.onScrollDirection && props.onScrollDirection(scrollDirection);
    }
  });


  useEffect(() => {
    const scrollableRefCurrent = scrollableRef.current;
    
    const { removeEventListener, showArrowButtons } = scrollCarouselListings(scrollableRefCurrent, props.hideArrowButtons, setHideLeft, setHideRight);
    setShowArrowButtons(showArrowButtons);
    return removeEventListener();
  }, [scrollableRef, props.hideArrowButtons]);

  const titleProps = useTitleProps(props);
  const subtitleProps = useSubtitleProps(props);

  return (
    <div className={classNames('horizontalGallery', style)} data-e2e='gallery-component'>
      {
        props.title && 
        <div className={classNames('sticky-content', style)}  data-e2e='sticky-content'> 
          <Title {...titleProps}></Title>
          { props.subtitle && <Subtitle {...subtitleProps}></Subtitle> }
        </div>
      }
      <div className={classNames('scrollGallery', style )} ref={scrollableRef}>
        {props.children && props.children.map((child, index) => (
          <div className={classNames('gallery-item', style)} key={index}>
              {child}
          </div>
        ))}
        {showArrowButtons && (
          <ArrowsHandler
            hideLeft={hideLeft}
            hideRight={hideRight}
            leftButtonAltText={props.leftButtonAltText}
            rightButtonAltText={props.rightButtonAltText}
            scrollableRefCurrent={scrollableRef.current}
            leftButtonAttributes={props.leftButtonAttributes}
            rightButtonAttributes={props.rightButtonAttributes}
          />
        )}
      </div>
    </div>
  );
}

const useTitleProps = (props: HorizontalGalleryProps): title => {
  return useMemo(() => {
    return {
      text: props.title || '',
      classNames: config.Title.classNames
    };
  }, [props]);
}

const useSubtitleProps = (props: HorizontalGalleryProps): SubtitleProps => {
  return useMemo(() => {
    return {
      text: props.subtitle ? (props.hideSeparator ? props.subtitle : `| ${props.subtitle}`) : '',
      classNames: config.Subtitle.classNames
    };
  }, [props]);
}

export default React.memo(HorizontalGallery);