import {useMemo} from "react";
import LinkContainerProps from "../../components/private/LinkContainer/props";
import {
  FOOTER_SELECTOR,
  IMG_SELECTOR,
  LISTING_SELECTOR, PRICE_DROP_SELECTOR,
  TOGGLE_SELECTOR, TOOLBAR_SELECTOR
} from "../SSRHelpers/SSRParsableConstants";
import ToolbarProps from "../../components/private/Toolbar/props";
import LabelProps from "../../components/private/Label/props";
import TagProps from "../../components/private/Tag/props";
import ToggleProps from "../../components/private/Toggle/props";
import IconProps from "../../components/private/Icon/props";
import heart_off from "../../icons/heart_off.svg";
import heart_on from "../../icons/heart_on.svg";
import ListingCaptionProps from "../../components/private/ListingPreview/components/ListingCaption/props";
import ListingTitleProps
  from "../../components/private/ListingPreview/components/ListingCaption/components/ListingTitle/props";
import SubtitleProps from "../../components/private/Subtitle/props";
import ContentProps from "../../components/private/Content/props";
import svg_info from "../../icons/info_outline.svg";
import title from "../../components/private/Title/props";
import tooltip from "../../components/private/Tooltip/props";
import listingTooltip
  from "../../components/private/ListingPreview/components/ListingCaption/components/ListingPrice/components/ListingTooltip/props";
import ListingPriceProps
  from "../../components/private/ListingPreview/components/ListingCaption/components/ListingPrice/props";
import listingBody
  from "../../components/private/ListingPreview/components/ListingCaption/components/ListingBody/props";
import ListingPreviewProps from "../../components/private/ListingPreview/props";
import ImageProps from "../../components/private/Image/props";
import button from "../../components/private/Button/props";
import CarouselProps from "../../components/private/Carousel/props";
import {
  ListingConfig,
  ListingConfigElements,
  ListingContactProps,
  PreviewImage
} from "../SSRHelpers/SSRParsableElements";
import FooterProps from "../../components/private/Footer/props";
import classNames from "../../utils/classNames";
import ListingOemProps from "../../components/public/ListingOem/props";
import {ListingMonthlyPrice} from "../types";

/**
 * Types should be refactored. We should not pass huge props objects, we should simplify.
 */

 export const parseListingConfig = (config: ListingConfig): ListingConfigElements => {
  const listingLayoutHeight = config.style.height;
  const listingPreviewHeight = config.ListingPreview.Image.height;
  const lazyLoading = config.ListingPreview.Image.lazyLoading;
  const carouselClassNames = config.ListingPreview.Carousel?.classNames as unknown as { [key: string]: string };
  return {listingLayoutHeight, lazyLoading, listingPreviewHeight, carouselClassNames};
};

export const makeToggleProps = (props: ListingContactProps): ToggleProps => {
  return {
    onClick: props.like?.onClick,
    state: props.like?.state,
    states: [{
      state: props.like?.off,
      Icon: {
        svg: heart_off,
        alt: props.like?.off
      } as IconProps
    }, {
      state: props.like?.on,
      Icon: {
        svg: heart_on,
        alt: props.like?.on
      } as IconProps
    }],
    dataSelector: {'data-name': TOGGLE_SELECTOR},
    preventDefault: !!props.hyperlinkAttributes
  } as ToggleProps;
}

export const makeLinkContainerProps = (props: LinkContainerProps): LinkContainerProps => {
  return {
    hyperlinkAttributes: props.hyperlinkAttributes,
    dataSelector: {'data-name': LISTING_SELECTOR}
  } as LinkContainerProps;
}

const makeFooterImageProps = (props: ListingContactProps, hiddenOnError: boolean) => {
  if (!props.logo) {
    return undefined;
  }
  return {
      src: props.logo,
      alt: props.logoAlt,
      lazyLoading: props.imageLazyLoading,
      hiddenOnError: hiddenOnError,
      onError: (e) => {
        // this is a hack to avoid a refactor of Images
        const img = e.target as HTMLImageElement;
        img.style.display = 'none';
      }
    } as ImageProps
};

const makeFooterButton = (props: ListingContactProps, toolbarBtnClassnames: {[key:string]: string}) => {
  return {
    Button: {
      onClick: props.contact?.onClick,
      Label: {
        text: props.contact?.text
      } as LabelProps,
      classNames: toolbarBtnClassnames,
      e2e: 'listingContactButton',
      preventDefault: !!props.hyperlinkAttributes
    }
  }
}

const makeFooterToolbarProps = (props: ListingContactProps, toolbarBtnClassnames: {[key:string]: string}): ToolbarProps => {
  const footerItem = makeFooterButton(props, toolbarBtnClassnames);
  return {
    items: [footerItem],
  } as ToolbarProps
}

export const makeListingPreviewTitle = (name: string): ListingTitleProps => {
  return {
    text: name,
    'data-e2e': 'listingName'
  } as ListingTitleProps
}

export const makeListingPriceSubtitleTitle = (price: string): ListingTitleProps => {
  return {
    text: price,
    'data-e2e': 'listingPrice'
  } as SubtitleProps
}

export const makeListingPreviewDropPrice = (prevPrice?: string): ContentProps | undefined => {
  return !prevPrice ? undefined :
    {
      text: prevPrice,
      dataSelector: {'data-name': PRICE_DROP_SELECTOR}
    } as ContentProps
}

export const makeListingPreviewTooltip = (monthlyPrice?: ListingMonthlyPrice): listingTooltip | undefined => {
  if (!monthlyPrice?.text?.length) {
    return undefined;
  }
  return {
    Content: {
      text: monthlyPrice.text,
      'data-e2e': 'listingMonthlyPrice'
    } as ContentProps,
    Tooltip: {
      Icon: {
        svg: svg_info,
        alt: 'info'
      } as IconProps,
      Title: {
        text: monthlyPrice.tooltip.title
      } as title,
      Content: {
        text: monthlyPrice.tooltip.content
      } as ContentProps,
      onClick: monthlyPrice?.onClick,
      onClose: monthlyPrice?.onClose
    } as tooltip
  } as listingTooltip
}

export const makeListingPriceCaptionProps = (props: ListingContactProps): ListingPriceProps | undefined => {
  const ListingTitle = makeListingPreviewTitle(props.name || '');
  const Subtitle = makeListingPriceSubtitleTitle(props.price || '');
  const Content = makeListingPreviewDropPrice(props.prevPrice);
  const ListingTooltip = makeListingPreviewTooltip(props.monthlyPrice);
  return {
    ListingTitle,
    Subtitle,
    Content,
    ListingTooltip
  } as ListingPriceProps
}

export const makeListingPreviewSeller = (seller?: string): listingBody => {
  return {
    SellerContent: {
      text: seller,
      'data-e2e': 'listingSellerContent'
    } as ContentProps
  } as listingBody
}

export const makeListingPreviewCaption = (props: ListingContactProps): ListingCaptionProps => {
  const ListingPrice = makeListingPriceCaptionProps(props);
  const ListingBody = makeListingPreviewSeller(props.seller);
  return {
    ListingPrice,
    ListingBody
  } as ListingCaptionProps
};

export const makeListingPreviewCaptionHiddenPrice = (props: ListingOemProps): ListingCaptionProps => {
  const ListingCaption = makeListingPreviewCaption(props);
  delete ListingCaption.ListingPrice?.ListingTooltip;
  const ListingPrice = ListingCaption.ListingPrice;
  // istanbul ignore next
  if (!ListingPrice?.Subtitle) return ListingCaption;
  ListingPrice.Subtitle.text = props.hiddenPriceMessage || '';
  ListingCaption.ListingPrice = ListingPrice as ListingPriceProps;
  return ListingCaption;
}

export const makeListingPreviewImageProps = (props: PreviewImage, imageHeight?: string): ImageProps => {
  return {
    src: props.image,
    fetchPriority: props.imageFetchPriority,
    alt: props.imageAlt,
    lazyLoading: props.imageLazyLoading,
    height: imageHeight,
    defaultImage: props.defaultImage,
    Logo: {
      src: props.imageLogo,
      alt: props.imageLogoAlt
    } as ImageProps,
    dataSelector: {'data-name': IMG_SELECTOR}
  } as ImageProps
}

export const makeListingPreviewCarouselProps = (
  props: ListingContactProps,
  listingPreviewHeight?: string,
  lazyLoading?: boolean,
  carouselClassNames?: {[key:string]: string}): CarouselProps => {
  return {
    LeftButton: {
      onClick: props.Carousel?.prevClick
    } as button,
    RightButton: {
      onClick: props.Carousel?.nextClick
    } as button,
    items: props.Carousel?.items.map((item: {[key: string]: string}) => {
      return {
        Image: {
          src: item.image,
          alt: item.imageAlt,
          height: listingPreviewHeight,
          lazyLoading: lazyLoading,
          defaultImage: props.defaultImage,
        } as ImageProps
      };
    }),
    classNames: carouselClassNames,
    hideLeftArrowButtonOnFirstSlide: props.Carousel?.hideLeftArrowButtonOnFirstSlide,
    showArrowButtonsOnHover: props.Carousel?.showArrowButtonsOnHover,
    cta: {
      text: props.Carousel?.cta?.text
    },
    disableResizeHandler: props.Carousel?.disableResizeHandler
  } as CarouselProps
}

export const listingPreviewProps = (
  props: ListingContactProps,
  previewHeight?: string
): ListingPreviewProps => {
  const ListingCaption = makeListingPreviewCaption(props);
  const Image = makeListingPreviewImageProps(props, previewHeight);
  return {
    Image,
    ListingCaption
  };
}

const listingPreviewCarouselProps = (
  props: ListingContactProps,
  previewHeight?: string,
  lazyLoading?: boolean,
  carouselClassNames?: {[key:string]: string}
): ListingPreviewProps => {
  const ListingCaption = makeListingPreviewCaption(props);
  const Carousel = makeListingPreviewCarouselProps(props, previewHeight, lazyLoading, carouselClassNames);
  return { ListingCaption, Carousel };
}

export const createFooterProps = (props: ListingContactProps, config: ListingConfig): FooterProps => {
  const Image = makeFooterImageProps(props, config.Footer.Image.hiddenOnError);
  if  (config.Footer.Toolbar) {
    const btnClassNames = config.Footer.Toolbar.Button.classNames as unknown as { [key: string]: string };
    const Toolbar = makeFooterToolbarProps(props, btnClassNames);

    return { Image, Toolbar, dataSelector: {'data-name': FOOTER_SELECTOR} };
  }
  return { Image, dataSelector: {'data-name': FOOTER_SELECTOR} };
}

export const useListingPreviewProps = (
  props: ListingContactProps,
  previewHeight?: string,
  lazyLoading?: boolean,
  carouselClassNames?: {[key:string]: string}
): ListingPreviewProps => {
  return useMemo(() => {
  // if we pass carousel, we return images with a carousel
  // Not yet, as far as I am concerned
  if (props.Carousel?.items) {
    return listingPreviewCarouselProps(props, previewHeight, lazyLoading, carouselClassNames);
  }
  return listingPreviewProps(props, previewHeight);
  }, [props, previewHeight, lazyLoading, carouselClassNames]);
}

export const useToolbarProps = (props: ListingContactProps): ToolbarProps => {
  return useMemo(() => {
    const tags = props.tags || [];
    return {
      items: tags.map((tag: {[key: string]: string}) => {
        return {
          Tag: {
            Label: {
              text: tag.text
            } as LabelProps
          } as TagProps
        };
      }),
      dataSelector: {'data-name': TOOLBAR_SELECTOR}
    };
  }, [props]);
}

export const useListingCommonProps = (props: ListingContactProps, config: ListingConfig, classKey: string, moduleStyle: {[key: string]: string}) => {
  return useMemo(() => {
    const classes = classNames(classKey, moduleStyle);
    const cfg: ListingConfigElements = parseListingConfig(config as unknown as ListingConfig);
    const footerProps = createFooterProps(props, config);
    return {footerProps, listingConfig: cfg, classNames: classes};
  }, [props, config, classKey, moduleStyle]);
}
