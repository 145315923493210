import { useState, useEffect } from "react";
import get from 'lodash/get';
type TestingImageRef = {
  current: {
    naturalHeight: number,
    naturalWidth: number
  }
};

export const useSafeImageLoading = (originalSrc?: string, originalSrcset?: string, defaultImage?: string, hiddenOnError?: boolean, classNames?: { contain?: boolean, invisible?: boolean}, imageRef?: null | React.RefObject<HTMLImageElement> | TestingImageRef) => {
    const [imageSrc, setImageSrc] = useState(originalSrc);
    const [imageClassNames, setImageClassnames] = useState(hiddenOnError ? { invisible: true } : classNames);
    const [imageSrcset, setImageSrcset] = useState(originalSrcset);

    const setErrorLoadingImage = defaultImage ? () => {
      setImageSrc(defaultImage);
      setImageSrcset(defaultImage);
    } : undefined;
    const setSuccessLoadingImage = hiddenOnError ? () => setImageClassnames(classNames) : undefined;

    useEffect(() => {
      //removing the invisible class if the image was correctly rendered from cache.
      if(get(imageRef,'current.naturalHeight',0) > 0 && get(imageRef,'current.naturalWidth',0) > 0){
        setImageClassnames(classNames);
      }
    }, [imageRef, classNames]);

    return {
        imageSrc,
        imageClassNames,
        imageRef,
        imageSrcset,
        setErrorLoadingImage,
        setSuccessLoadingImage
    };
};
