import React, { useMemo } from 'react';
import topiclinksbox from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Title from '../../private/Title/Title';
import title from '../../private/Title/props';
import Link from '../../private/Link/Link';
import link from '../../private/Link/props';
import config from './config.json';

function TopicLinksBox(props: topiclinksbox) {
  return (
    <div
      className={classNames('topiclinksbox', style)}
      data-e2e={config['data-e2e']}
    >
      <Title {...useTitleProps(props)} />
      <div>
        { props.links.map((link, index) => (
          <Link key={index} {...getLinkProps(link.url, link.text)} />
        )) }
      </div>
    </div>
  );
}

const useTitleProps = (props: topiclinksbox): title => useMemo(() => ({
  text: props.title,
  classNames: config.Title.classNames,
  'data-e2e': config.Title['data-e2e']
}), [props]);


const getLinkProps = (url: string, text: string): link => ({
  text,
  url,
  classNames: config.Link.classNames,
  'data-e2e': config.Link['data-e2e'],
  'data-text-e2e': config.Link['data-text-e2e']
});

export default React.memo(TopicLinksBox);
