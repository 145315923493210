import React, { useRef } from 'react';
import EmailLeadFormPropsV2 from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';
import Input from '../../private/Input';
import InputProps from '../../private/Input/props';
import Textarea from '../../private/Textarea';
import TextareaProps from '../../private/Textarea/props';
import get from 'lodash/get';
import SellerName from '../SellerName';
import SellerNameProps from '../SellerName/props';
import ErrorMessageV2 from '../../private/ErrorMessageV2';
import ErrorMessagePropsV2 from '../../private/ErrorMessageV2/props';
import SuccessMessage from '../SuccessMessage';
import SuccessMessageProps from '../SuccessMessage/props';
import Image from '../../private/Image';
import Action from '../Action';
import { ActionProps } from '../Action/props';
import errorIcon from '../../../icons/info_outline_red.svg';

function EmailLeadFormV2(props: EmailLeadFormPropsV2) {
  const { children } = props;

  const fromRef = useRef<HTMLFormElement>(null)

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const obj: { [key: string]: FormDataEntryValue | null; } = {};

    formData.forEach((value, key) => {
      obj[key] = value;
    });

    props.onSubmit(e, obj);
  }

  const handleClick = (e: any) => {
    e.preventDefault();

    fromRef?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );

    if (props?.onClick) {
      props.onClick(e);
    }
  };
  const inputFirstNameProps = inputNameProps(props, 'firstName', 'First Name', 'Enter your first name');
  const inputLastNameProps = inputNameProps(props, 'lastName', 'Last Name', 'Enter your last name');
  const emailProps = inputEmailProps(props);
  const phoneProps = inputPhoneProps(props);

  return (
    <div className={classNames('emailLeadForm', style, {...props.classNames})} data-e2e='emailLeadForm'>
      {props?.oemLeadLogo?.src ?
      <Image {...props.oemLeadLogo} />: 
      <SellerName {...sellerNameProps(props)} />}
      {props?.showSuccessMessage ? <SuccessMessage {...inputSuccessMessageProps(props)} /> :
        <form
          ref={fromRef}
          onSubmit={handleSubmit}
          className={props.portal === 'bt' ? classNames('mobileFont', style, {...props.classNames}) : ''}
        >
          <ErrorMessageV2 {...inputErrorMessageProps(props)} />
          <div className={classNames('nameWrapper', style)} data-e2e='nameWrapper'>
            {inputFirstNameProps && 
              <Input {...inputFirstNameProps} />
            }
            {inputLastNameProps && 
              <Input {...inputLastNameProps} />
            }
          </div>
          <Input {...emailProps} />
          <Input {...phoneProps} />

          {/* istanbul ignore next */ props?.zip?.id && <Input {...inputZipProps(props)} />}
          <Textarea {...textareaProps(props)} />
          {children}
          <Action {...actionProps(props, handleClick)} /> 
        </form>
      }

    </div>
  );
}

const sellerNameProps = (props: EmailLeadFormPropsV2): SellerNameProps => {
  return {
    name: props.sellerName.name,
    address: props.classNames?.leadHygieneForm ? '' : props.sellerName.address,
    addressLink: props.sellerName.addressLink,
    phoneNumber: props.sellerName.phoneNumber,
    phoneText: props.sellerName.phoneText,
    linkContactInfo: props.sellerName.linkContactInfo,
    displayPhoneMessage: props.sellerName.displayPhoneMessage,
    showCloseButton: props.sellerName.showCloseButton,
    onClick: props.sellerName.onClick,
    onClickPhoneMessage: props.sellerName.onClickPhoneMessage,
    onClickCloseButton: props.sellerName.onClickCloseButton,
    closeButtonE2e: `${props.id}-close`
  }
}


const inputErrorMessageProps = (props: EmailLeadFormPropsV2): ErrorMessagePropsV2 => {
  return {
    title: props.errorTitle || 'Request cannot be completed',
    messages: [...get(props,'errorMessages',['You may have entered incorrect information or server is temporarily down. Please reload this page and try again later.'])],
    show: props.showErrorMessage || false,
    icon: errorIcon
  };
};

const inputSuccessMessageProps = (props: EmailLeadFormPropsV2): SuccessMessageProps => {
  return {
    title: props.successTitle || 'Congratulations!',
    message: props.successMessage || 'We have sent your information directly to the seller.',
    show: props.showSuccessMessage,
    'data-e2e': `${props.id}-successMessage`,
    classNames: {
      successCard: props.classNames?.leadHygieneForm,
    }
  };
};

const inputNameProps = (props: EmailLeadFormPropsV2, field: 'firstName' | 'lastName', defaultLabel: string, defaultErrorLabel: string): InputProps => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props[field].label || defaultLabel,
    },
    errorLabel: props[field].errorLabel || defaultErrorLabel,
    showErrorIcon: true,
    id: props[field].id,
    type: 'text',
    name: field,
    value: props[field].value,
    onChange: props[field].onChange,
    onBlur: props[field].onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes(field),
    'data-e2e': props[field].id
  }
}

const inputEmailProps = (props: EmailLeadFormPropsV2): InputProps => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props.email.label || 'Your Email',
    },
    id: props.email.id,
    type: 'email',
    name: 'email',
    value: props.email.value,
    onChange: props.email.onChange,
    onBlur: props.email.onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes('email'),
    'data-e2e': props.email.id,
    errorLabel: props.email.errorLabel,
    showErrorIcon: true
  }
}

const inputPhoneProps = (props: EmailLeadFormPropsV2): InputProps => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props.phone.label || 'Your Phone (Opt)',
    },
    id: props.phone.id,
    type: 'tel',
    name: 'phone',
    value: props.phone.value,
    onChange: props.phone.onChange,
    onBlur: props.phone.onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes('phone'),
    'data-e2e': props.phone.id,
    errorLabel: props.phone.errorLabel,
    showErrorIcon: true
  }
}
/* istanbul ignore next */
const inputZipProps = (props: EmailLeadFormPropsV2): InputProps => {
  const invalidFields = props.invalidFields;
  return {
    Label: {
      text: props.zip.label || 'Zip Code',
    },
    id: props.zip.id,
    type: 'text',
    name: 'zip',
    value: props.zip.value,
    onChange: props.zip.onChange,
    onBlur: props.zip.onBlur,
    classNames: config.Input.classNames,
    invalid: invalidFields?.includes('zip'),
    'data-e2e': props.zip.id,
    errorLabel: props.zip.errorLabel,
    showErrorIcon: true
  }
}

const textareaProps = (props: EmailLeadFormPropsV2): TextareaProps => {
  const invalidFields = props.invalidFields;
  return {
    labelText: props.textarea.label || 'Your Message',
    name: 'text',
    hidden: true,
    id: props.textarea.id,
    onChange: props.textarea.onChange,
    onBlur: props.textarea.onBlur,
    defaultValue: props.textarea.defaultValue,
    value: props.textarea.value,
    invalid: invalidFields?.includes('text'),
    'data-e2e': props.textarea.id,
    errorLabel: props.textarea.errorLabel
  }
}

const actionProps = (props: EmailLeadFormPropsV2, handleClick: any): ActionProps => {
  return {
    onClick: handleClick,
    label: props.buttonLabel || 'Send Email',
    type: 'submit',
    disabled: props.buttonDisabled,
    stretch: true,
    e2e: `${props.id}-submit`,
    attributes: props.buttonAttributes,
  };
}

export default React.memo(EmailLeadFormV2);
