import React, { useState, useEffect } from 'react';
import TextareaProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import get from 'lodash/get';

import LabelProps from '../Label/props';
import Label from '../Label';
import Content from '../Content';
import ContentProps from '../Content/props';

function Textarea(props: TextareaProps) {
  const [value, setValue] = useState('');
  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> ) => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement> ) => {
    setValue(e.target.value);
    if (props.onBlur) {
      props.onBlur(e);
    }
  }
  
  return (
    <div className={classNames('textarea', style, { ...props.classNames, invalid: props.invalid || false, moved: Boolean(value) || false })} data-testing='textarea-wrapper'>
      <textarea
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        aria-label={props.labelText}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={props.defaultValue}
        data-e2e={props['data-e2e']}
      />
      { !props.hidden && <Label htmlFor={props.id} {...labelProps(props)} /> }
      {props.invalid && props.errorLabel && <div className={classNames("errorLabel", style)}><Content {...contentProps(props)}></Content> </div>}
    </div>
  );
}

const labelProps = (props: TextareaProps): LabelProps => {
  return {
    text: props.labelText || '',
    classNames: {
      "label-5": true
    }
  };
}

const contentProps = (props: TextareaProps): ContentProps => {
  return {
    text: get(props,'errorLabel', ''),
    classNames: {'content-2': true},
    "data-e2e": `error-message-${props.id}`
  }
};

export default React.memo(Textarea);
