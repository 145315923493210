import React, { useCallback } from 'react';
import ListingStandardProps from "./props";
import style from "./style.module.scss";
import config from './config.json';

import {
  useListingPreviewProps, useListingCommonProps
} from "../../../shared/propsBuilders/listingPropsBuilder";
import {ListingLayout} from "../Listing/Listing";
import {ListingConfig} from "../../../shared/SSRHelpers/SSRParsableElements";
import ListingOemProps from "../ListingOem/props";

const Listing = (props: ListingStandardProps) => {
  const cfg = props.listingConfig || config as unknown as ListingConfig;
  const classKey = props.classKey || 'listingStandard';
  const moduleStyle = props.moduleStyle || style;
  const { classNames, footerProps, listingConfig } = useListingCommonProps(props, cfg, classKey, moduleStyle);
  const { listingLayoutHeight, listingPreviewHeight, lazyLoading, carouselClassNames } = listingConfig;
  const previewProps = useListingPreviewProps(props, listingPreviewHeight, lazyLoading, carouselClassNames);

  return (<ListingLayout {...props}
    footerProps={footerProps}
    classNames={classNames}
    height={listingLayoutHeight}
    listingPreviewProps={previewProps}
  />);
};

function ListingStandard(props: ListingStandardProps | ListingOemProps) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    props?.onClick?.(event);
  }, [props]);

  const newProps = { ...props, onClick: onClick };
  return (<Listing {...newProps}/>);
}

export default React.memo(ListingStandard);
