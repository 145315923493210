import React, { useEffect, useRef } from "react";
import { NativeAdProps } from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Button from '../../private/Button/Button';
import closeIcon from '../../../icons/close.svg';

function NativeAd({
  redirectUrl,
  viewableUrl,
  eligibleUrl,
  accupixelUrl,
  altText,
  htmlBody,
  onLoad,
  onClick,
  e2e,
  as,
  target = '_self',
  className = "",
  closeSelector,
  ...rest
}: NativeAdProps) {
  const adRef = useRef(null);
  const viewableImgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && viewableImgRef.current && viewableUrl) {
          viewableImgRef.current.src = viewableUrl;
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    adRef.current && observer.observe(adRef.current);

    return () => {
      observer.disconnect();
    };
  }, [viewableUrl]);

  const handleClose = () => {
    const wrapperElement = document.querySelector(closeSelector as any);
    if (wrapperElement) {
      wrapperElement.remove();
    }
  };

  const adClasses: { [key: string]: boolean } = {
    'image-wrapper': true
  };

  const Component = as || 'div';

  return (
    <Component
      ref={adRef}
      className={`${classNames('Ad', style, adClasses)} ${className}`}
      data-e2e={e2e}
      data-testid='ad-component'
      {...rest}
    >
      {closeSelector && <Button
        classNames={{ icon: true }}
        Icon={{ svg: closeIcon, alt: 'close' }}
        onClick={handleClose}
      />}
      <a href={redirectUrl} data-testid="redirect-url" target={target} className={classNames('Ad', style, {'redirectLink': true})} rel="sponsored">
        <div
          className='native-ad'
          data-testid="native-ad"
          dangerouslySetInnerHTML={{ __html: htmlBody }}
          onClick={(e: React.MouseEvent<HTMLImageElement>) => {
             onClick && onClick(e);
          }}
        />
        {viewableUrl && 
          <img 
            ref={viewableImgRef}
            alt={altText ? altText : 'viewable ad alt text'}
            data-testid="viewable-url" 
            style={{ display: 'none' }}
            onLoad={(e: React.MouseEvent<HTMLImageElement>) => {
              onLoad && onLoad(e);
            }}
          />
        }
        {eligibleUrl && 
          <img 
            alt={altText ? altText : 'eligible ad alt text'}
            src={eligibleUrl}
            data-testid="eligible-url" 
            style={{ display: 'none' }}
          />
        }
        {accupixelUrl && 
          <img 
            alt={altText ? altText : 'accupixel ad alt text'}
            src={accupixelUrl}
            data-testid="accupixel-url" 
            style={{ display: 'none' }}
          />
        }
      </a>
    </Component>
  );
}


export default NativeAd;
