import React from 'react';
import {CarouselItemProps} from "./props";
import Image from '../../Image/Image';
import Video from '../../Video/Video';
import Listing from '../../../public/Listing';
import ListingEnhanced from "../../../public/ListingEnhanced";
import ListingOem from "../../../public/ListingOem";
import ListingPremium from "../../../public/ListingPremium";
import ListingPreviewCard from "../../../public/ListingPreviewCard";
import ListingPreviewSnapshot from "../../../public/ListingPreviewSnapshot";
import ListingSocial from "../../../public/ListingSocial";
import ListingSponsored from "../../../public/ListingSponsored";
import ListingStandard from "../../../public/ListingStandard";
import LoadMore from '../../../public/LoadMore/LoadMore';
import ImageProps from "../../Image/props";
import VideoProps from "../../Video/props";
import ListingEnhancedProps from "../../../public/ListingEnhanced/props";
import ListingOemProps from "../../../public/ListingOem/props";
import ListingPremiumProps from "../../../public/ListingPremium/props";
import listingPreviewCard from "../../../public/ListingPreviewCard/props";
import listingPreviewSnapshot from "../../../public/ListingPreviewSnapshot/props";
import ListingSponsoredProps from "../../../public/ListingSponsored/props";
import ListingStandardProps from "../../../public/ListingStandard/props";
import listingSocial from "../../../public/ListingSocial/props";
import ListingProps from "../../../public/Listing/props";
import loadMore from '../../../public/LoadMore/props';
import ImagePannellumContainerProps from "../../../public/ImagePannellumContainer/props";
import {ImagePannellumContainer} from "../../../index";
import Picture from '../../Picture';
import { PictureProps } from '../../Picture/props';

function CarouselItem<T>({itemKey, elementProps, isCurrent}: CarouselItemProps<T>): JSX.Element {
  switch (itemKey) {
    case 'Image':
      return <Image {...elementProps as unknown as ImageProps}></Image>;
    case 'Image360':
    case 'ImagePannellumContainer':
      return <ImagePannellumContainer {...elementProps as unknown as ImagePannellumContainerProps} start={isCurrent}></ImagePannellumContainer>;
    case 'Video':
      return <Video {...elementProps as unknown as VideoProps}></Video>;
    case 'ListingEnhanced':
      return <ListingEnhanced {...elementProps as unknown as ListingEnhancedProps}></ListingEnhanced>;
    case 'ListingOem':
      return <ListingOem {...elementProps as unknown as ListingOemProps}></ListingOem>;
    case 'ListingPremium':
      return <ListingPremium {...elementProps as unknown as ListingPremiumProps}></ListingPremium>;
    case 'ListingPreviewCard':
      return <ListingPreviewCard {...elementProps as unknown as listingPreviewCard}></ListingPreviewCard>;
    case 'ListingPreviewSnapshot':
      return <ListingPreviewSnapshot {...elementProps as unknown as listingPreviewSnapshot}></ListingPreviewSnapshot>;
    case 'ListingSponsored':
      return <ListingSponsored {...elementProps as unknown as ListingSponsoredProps}></ListingSponsored>;
    case 'ListingStandard':
      return <ListingStandard {...elementProps as unknown as ListingStandardProps}></ListingStandard>;
    case 'ListingSocial':
      return <ListingSocial {...elementProps as unknown as listingSocial}></ListingSocial>;
    case 'LoadMore':
      return <LoadMore {...elementProps as unknown as loadMore}></LoadMore>;
    case 'Picture':
      return <Picture {...elementProps as unknown as PictureProps} />
    case 'Listing':
      //{/!* -- deprecated -- *!/}
      return <Listing {...elementProps as unknown as ListingProps}></Listing>;
    default:
      return <div />;
  }
}

export default CarouselItem;
