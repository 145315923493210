import React, {useEffect, useRef} from 'react';
import ArticleVideoSectionProps, {VideoRef, VideoControlsRef} from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import Title from '../../private/Title';
import TitleProps from '../../private/Title/props';
import Content from '../../private/Content';
import ContentProps from '../../private/Content/props';
import Video from '../../private/Video';
import VideoProps from '../../private/Video/props';
import config from './config.json';

function ArticleVideoSection(props: ArticleVideoSectionProps) {
  const videoItemRef = useRef<VideoRef>({ current: null });
  
  useEffect(() => {
    videoItemRef.current = props?.video?.childRef;
  }, [props.video.childRef]);

  return (
    <div className={classNames('articleVideoSection', style, {})}>
      <Title { ...useTitleProps(props) } />
      <Content { ...useContentProps(props) } />
      <Video {...useVideoProps(props.video, videoItemRef)} />
    </div>
  );
}

const useVideoProps = (item: VideoProps, videoItemRef: React.MutableRefObject<VideoRef>) => ({
  title: item.title,
  src: item.src,
  childRef: videoItemRef.current as VideoControlsRef,
  overlay: false,
  customPlayIcon: item.customPlayIcon,
}) as VideoProps

const useTitleProps = (props: ArticleVideoSectionProps): TitleProps => ({
  text: props.title || config.Title.text,
  classNames: config.Title.classNames,
});

const useContentProps = (props: ArticleVideoSectionProps): ContentProps => ({
  text: props.description || config.Content.text,
  classNames: config.Content.classNames,
});

export default React.memo(ArticleVideoSection);