import React, { useState } from 'react';
import PhoneDisplayerProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';


function PhoneDisplayer(props: PhoneDisplayerProps) {
  const [phoneClicked, setPhoneClicked] = useState(false);

  const handleClickPhoneText = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.onClickPhoneMessage && props.onClickPhoneMessage(e);
    setPhoneClicked(true);
  };

  return (
    <div className={classNames('phoneDisplayer', style)} data-e2e='phoneDisplayer'>
      {phoneClicked ?
        <a href={`tel:${props.phoneNumber}`}>{props.phoneNumber}</a> : <a onClick={handleClickPhoneText}>{props.phoneText}</a>}
    </div>
  );
}

export default React.memo(PhoneDisplayer);
