import React, {Children, useState, useImperativeHandle, forwardRef, useEffect, createContext} from 'react';
import {StepElement, StepperDefaults, StepperProps} from "./props";
import Step from "./components/Step";

export const StepperContext = createContext<{ index: number } | undefined>(undefined);

const isValidStep = (child:  StepElement, index: number) => {
  if (React.isValidElement(child) && child.type === Step) {
    return child;
  } else {
    console.error(`Child at index ${index} is not a valid Step component.`);
    return null;
  }
}

const StepperDefaultButtons: React.FC<StepperDefaults> = ({prevStep, nextStep, currentStep, length}) => {
  return (
    <div className={'default-stepper-buttons'}>
      <button onClick={prevStep} disabled={currentStep === 0}>
        Back
      </button>
      <button onClick={nextStep} disabled={currentStep === length - 1}>
        Next
      </button>
    </div>
  );
}

const Stepper = forwardRef(({children, onStepLoad, className = '', defaultButtons = false, initialStep = 0}: StepperProps, ref) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const childrenSteps = Children.toArray(children);
  const steps = childrenSteps.map(isValidStep).filter(Boolean);
  const length = steps.length;
  useEffect(() => {
    if (onStepLoad) {
      onStepLoad(currentStep);
    }
  }, [currentStep, onStepLoad]);

  const nextStep = () => {
    if (currentStep < length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const goToStep = (stepIndex: number) => {
    if (stepIndex >= 0 && stepIndex < length) {
      setCurrentStep(stepIndex);
    }
  }

  useImperativeHandle(ref, () => ({
    nextStep,
    prevStep,
    goToStep
  }));
  if (!childrenSteps?.length) return null;

  return (
    <>
      <StepperContext.Provider value={{ index: currentStep }}>
        <div className={className ? className : undefined} data-e2e='stepper'>
          {steps[currentStep]}
          {defaultButtons && <StepperDefaultButtons prevStep={prevStep} nextStep={nextStep} currentStep={currentStep} length={length}/>}
        </div>
      </StepperContext.Provider>
    </>
  );
});

Stepper.displayName = 'Stepper';

export default React.memo(Stepper);