import React, {useMemo} from 'react';
import searchTabs from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import list from '../../private/List/props';
import Tabs from '../../private/Tabs';
import tabs from '../../private/Tabs/props';
import tabContent from '../../private/Tabs/components/TabContent/props';
import Title from '../../private/Title';
import title from '../../private/Title/props';



function SearchTabs(props: searchTabs) {
  return (
    <div className={classNames('searchTabs', style)}>
      <Title {...useTitleProps(props)}></Title>
      <Tabs {...useTabsProps(props)}></Tabs>
    </div>
  );
}


const useTitleProps = (props: searchTabs): title => {
  return useMemo(() => {
    return {
      text: props.title,
      classNames: config.Title.classNames
    };
  }, [props]);
}

const useTabsProps = (props: searchTabs): tabs => {
  return useMemo(() => {
    return {
      List: {
        items: props.tabs.map((tab: {[key: string]: string}) => {
          return {
            Link: {
              text: tab.text
            }
          };
        })
      } as list,
      items: props.contents.map((content: { [key: string]: any }) => {
        return {
          TabContent: {
            Button: {
              Label: {
                text: content.searchButton,
              } 
            },
            items: content.dropdowns.map((dropdown: { [key: string]: any }) => {
              return {
                Fieldset: {
                  Label: {
                    text: dropdown.label
                  },
                  Collapsible: {
                    header: dropdown.dropdownHeader,
                    List: {
                      items: dropdown.dropdownList.map((dropdownItem: { [key: string]: any }) => {
                        return {
                          Label: {
                            text: dropdownItem.dropdownItem
                          }
                        }
                      })
                    }
                  }
                }
              };
            })
          } as tabContent
        };
      })
    };
  }, [props]);
}



export default React.memo(SearchTabs);