import React, { useEffect, useRef, useCallback } from 'react';
import blocker from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';

function Blocker(props: blocker) {
  const ref: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (props.onClick) {
      props.onClick(event);
    }
  }, [props]);

  useEffect(() => {
    const current: HTMLDivElement | null = ref.current;

    if (!props.classNames?.scroll) {
      current?.addEventListener('touchmove', touchMove, {passive: false});
      current?.addEventListener('pointermove', pointerMove, {passive: false});
      current?.addEventListener('wheel', wheel, {passive: false});
    }
    
    return () => { 
      current?.removeEventListener('touchmove', touchMove);
      current?.removeEventListener('pointermove', pointerMove);
      current?.removeEventListener('wheel', wheel);
    };
  }, [props.classNames?.scroll, ref]);

  return (
    <div className={classNames('blocker', style, {...props.classNames})} data-e2e='blocker'>
      <div
        ref={ref}
        onClick={onClick}
      ></div>
      {props.children}
    </div>
  );
}

const touchMove: (event: TouchEvent) => void = (event: TouchEvent) => {
  event.preventDefault();
};

const pointerMove: (event: PointerEvent) => void = (event: PointerEvent) => {
  event.preventDefault();
};

const wheel: (event: WheelEvent) => void = (event: WheelEvent) => {
  event.preventDefault();
};

export default React.memo(Blocker);