import React from 'react';
import CurrencyPickerProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import ItemPicker from '../../private/ItemPicker';

function CurrencyPicker(props: CurrencyPickerProps) {
  return (
    <div className={classNames('currencyPicker', style)}>
      <ItemPicker {...props} />
    </div>
  );
}

export default React.memo(CurrencyPicker);