import React from "react";
import BoatDetailsProps from "./props";
import style from "./style.module.scss";
import classNames from "../../../utils/classNames";

import BoatDetail from "./components/BoatDetail";
import BoatDetailProps from "./components/BoatDetail/props";

function BoatDetails(props: BoatDetailsProps) {
  return (
    <div className={classNames("boatDetails", style)}>
      {props.details.map((_detail, index: number) => (
        <BoatDetail key={index} {...detailProps(props, index)} />
      ))}
    </div>
  );
}

const detailProps = (
  props: BoatDetailsProps,
  index: number
): BoatDetailProps => {
  return {
    icon: props.details[index].icon,
    iconAlt: props.details[index].iconAlt,
    title: props.details[index].title,
    content: props.details[index].content || "-",
    tooltip: props.details[index].tooltip,
  };
};

export default React.memo(BoatDetails);
