
import React, { useMemo } from 'react';
import breadcrumb from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Toolbar from '../../private/Toolbar';
import ToolbarProps from '../../private/Toolbar/props';

import link from '../../private/Link/props';

function Breadcrumb(props: breadcrumb) {
  return (
    <div className={classNames('breadcrumb', style)}>
     <Toolbar {...useToolbarProps(props)}></Toolbar>
    </div>
  );
}

const useToolbarProps = (props: breadcrumb): ToolbarProps => {
  return useMemo(() => {
    return {
      schema: config.Toolbar.schema,
      items: props.items.map((item: { [key: string]: string }) => {
        return {
          ...item,
          Link: {
            text: item.text,
            url: item.url,
            property: config.Toolbar.items.Link.property,
            typeOf: config.Toolbar.items.Link.typeOf,
            classNames: config.Toolbar.items.Link.classNames
          } as link
        }
      }),
      classNames: config.Toolbar.classNames
    };
  }, [props]);
}

export default React.memo(Breadcrumb);