import React from 'react';
import ImagePannellumContainerProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import ReactPannellum from 'react-pannellum';

const defaultStyle = {width: '100%', height: '100%'};

function ImagePannellumContainer({imageSource, id, config, start = true}: ImagePannellumContainerProps) {
  const panellumConfig = {
    autoRotate: config?.autoRotate ?? 1,
    autoLoad: config?.autoLoad ?? true,
    draggable: config?.draggable ?? true,
    showFullscreenCtrl: config?.showFullscreenCtrl ?? true,
    mouseZoom: config?.mouseZoom ?? 'fullscreenonly',
  };
  const pannellumStyle = config?.style || defaultStyle;
  return (
    <div className={classNames('imagePannellumContainer', style)}>
      {start && <ReactPannellum
        id={id}
        sceneId={`scene-${id}`}
        imageSource={imageSource}
        style={pannellumStyle}
        config={panellumConfig}
      />}
    </div>
  );
}

export default React.memo(ImagePannellumContainer);
