import React, { useCallback, useMemo } from 'react';
import ListingOemProps, {OemListingProps} from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import FooterProps from '../../private/Footer/props';
import Footer from '../../private/Footer';

import ToolbarProps from '../../private/Toolbar/props';
import LabelProps from '../../private/Label/props';
import ImageProps from '../../private/Image/props';

import Divider from '../../private/Divider';

import ListingPreviewProps from '../../private/ListingPreview/props';
import ListingPreview from '../../private/ListingPreview';
import LinkContainer from '../../private/LinkContainer';
import {
  makeLinkContainerProps,
  makeListingPreviewCaptionHiddenPrice, makeListingPreviewCarouselProps,
  makeListingPreviewImageProps
} from "../../../shared/propsBuilders/listingPropsBuilder";
import {FOOTER_SELECTOR} from "../../../shared/SSRHelpers/SSRParsableConstants";

const OemListing = ({linkProps, classes, onClick, height, listingPreviewProps, footerProps}: OemListingProps) => {
  const selector = !linkProps.hyperlinkAttributes ? linkProps.dataSelector : {};
  return (<LinkContainer {...linkProps}>
    <span
      className={classes}
      onClick={onClick as React.MouseEventHandler<HTMLSpanElement>}
      style={{height}}
      {...selector}
    >
      <ListingPreview {...listingPreviewProps}></ListingPreview>
      <Divider></Divider>
      <Footer {...footerProps}></Footer>
    </span>
  </LinkContainer>);
}

function ListingOem(props: ListingOemProps) {
  const onClick: (event: React.MouseEvent<HTMLElement>) => void = useCallback((event: React.MouseEvent<HTMLElement>) => {
    props?.onClick?.(event);
  }, [props]);

  const newProps = {
    ...props,
    onClick: onClick
  };

  return (
    <>
      <OemListing
        linkProps={makeLinkContainerProps(newProps)}
        classes={classNames('listingOem', style)}
        onClick={newProps.onClick}
        height={config.style.height}
        listingPreviewProps={useListingPreviewProps(newProps)}
        footerProps={useFooterProps(newProps)}/>
    </>
  );
}

const oemPreviewImageProps = (props: ListingOemProps): ListingPreviewProps => {
  const imgHeight = config.ListingPreview.Image.height;
  const Image =  makeListingPreviewImageProps(props, imgHeight);
  const ListingCaption = makeListingPreviewCaptionHiddenPrice(props);
  return { Image, ListingCaption };
};

const oemPreviewCarouselProps = (props: ListingOemProps): ListingPreviewProps => {
  const listingPreviewHeight = config.ListingPreview.Image.height;
  const lazyLoading = config.ListingPreview.Image.lazyLoading;
  const carouselClassNames = config.ListingPreview.Carousel?.classNames as unknown as {[key:string]: string};
  const ListingCaption = makeListingPreviewCaptionHiddenPrice(props);
  const Carousel = makeListingPreviewCarouselProps(props, listingPreviewHeight, lazyLoading, carouselClassNames);
  return { ListingCaption, Carousel };
}

const useListingPreviewProps = (props: ListingOemProps): ListingPreviewProps => {
  return useMemo(() => {
    return props.Carousel?.items ? oemPreviewCarouselProps(props) : oemPreviewImageProps(props);
  }, [props]);
}

const useFooterProps = (props: ListingOemProps): FooterProps => {
  return useMemo(() => {
    return {
      Image: !props.logo ? undefined : {
        src: props.logo,
        alt: props.logoAlt,
        lazyLoading: props.imageLazyLoading,
        hiddenOnError: config.Footer.Image.hiddenOnError
      } as ImageProps,
      Toolbar: {
        items: [{
          Label: {
            text: props.manufacturerListingText || props.contact?.text,
            classNames: config.Footer.Label.classNames
          } as LabelProps,
        }],        
      } as ToolbarProps,
      dataSelector: {'data-name': FOOTER_SELECTOR}
    };
  }, [props]);
}

export default React.memo(ListingOem);
