import React, { useRef} from 'react';
import IconProps from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import { useSafeImageLoading } from '../../../hooks/useSafeLoadingImage';

function Icon(props: IconProps) {
  const imageRef: null | React.RefObject<HTMLImageElement> = useRef(null);

  const {
    imageSrc,
    imageSrcset,
    imageClassNames,
    setErrorLoadingImage,
    setSuccessLoadingImage
  } = useSafeImageLoading(props.svg, props.srcset, props.defaultImage, props.hiddenOnError, props.classNames, imageRef);

  return (
    <img
      className={classNames('icon', style, imageClassNames)}
      ref={imageRef}
      src={props.iconSrc || imageSrc}
      alt={props.alt}
      srcSet={imageSrcset}
      sizes={props.sizes}
      onLoad={props.onLoad || setSuccessLoadingImage}
      onError={props.onError || setErrorLoadingImage}
    />
  );
}

export default React.memo(Icon);