import React, {useMemo} from 'react';
import hero from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Image from '../../private/Image/Image';
import ImageProps from '../../private/Image/props';

function Hero(props: hero) {
  return (
    <div className={classNames('hero', style, props.classNames)}>
      <Image {...useImageProps(props)}></Image>
    </div>
  );
}

const useImageProps = (props: hero): ImageProps => {
  return useMemo(() => {
    return {
      src: props.img,
      alt: 'hero image',
      width: config.Image.width,
      height: props.height || 'auto',
      hiddenOnError: props.hiddenOnError ?? false
    };
  }, [props]);
}

export default React.memo(Hero);